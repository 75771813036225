import ProgressTracker from "components/ProgressTracker/ProgressTracker";
import { Button } from "components/design-system/Button";
import { SubmitButton } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import useOnboardingStore from "store/useOnboardingStore";
import tw from "utils/tw";
import BackButton from "../SignIn/BackButton";

type Props = {
  hideBackButton?: boolean;
  hideSkip?: boolean;
  hideProgress?: boolean;
  fullHeight?: boolean;
  formSubmitting?: boolean;
  onClickBack: () => void;
  onClickSkip?: () => void;
  submitDisabled?: boolean;
  requireChanges?: boolean;
};

const Footer = ({
  children,
  fullHeight = true,
  hideBackButton = false,
  hideSkip = false,
  hideProgress = false,
  formSubmitting = false,
  submitDisabled = false,
  requireChanges,
  onClickBack,
  onClickSkip,
}: WithChildren<Props>) => {
  const { totalSteps, currentStep } = useOnboardingStore(
    ({ totalSteps, currentStep }) => ({
      totalSteps,
      currentStep,
    })
  );
  return (
    <div className={tw("flex flex-col", { "h-full": fullHeight })}>
      <div className="grow" />
      <div className="flex items-center">
        {!hideBackButton && <BackButton onClick={onClickBack} />}
        <div className={tw({ "ml-12": !hideBackButton })}>
          {!hideProgress && (
            <ProgressTracker total={totalSteps} step={currentStep} />
          )}
        </div>
        <div className="grow" />
        {!hideSkip && (
          <Button
            className="mr-8 text-text-subtle hover:text-text-subtle-hover"
            buttonStyle="subtle"
            onClick={onClickSkip}
            type="button"
          >
            Skip
          </Button>
        )}
        {children ?? (
          <SubmitButton
            disabled={submitDisabled}
            requireChanges={requireChanges}
          >
            Next
            <Icon
              className={tw("ml-6", { "animate-spin": formSubmitting })}
              icon={!formSubmitting ? "ArrowRight" : "LoaderCircle"}
              size={20}
            />
          </SubmitButton>
        )}
      </div>
    </div>
  );
};

export default Footer;
