import { ElementRef, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { nodeAs } from "@utility-types";
import { useValidateMessage } from "components/thread/hooks";
import {
  FetchUserEdgeDocument,
  FetchUsersDocument,
  PersistentChatsDocument,
  useCreatePersistentChatMutation,
  useFetchUserEdgeQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { removeLonelyLinks } from "utils/message/removeLonelyLinks";
import tw from "utils/tw";

import { MessageEditor } from "components/MessageEditor";

import MessageEditorContainer from "components/MessageEditor/MessageEditorContainer";
import {
  routeToThread,
  useRouteParams,
  userPath,
} from "components/routing/utils";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import NoPersistentChatHeader from "./NoPersistentChatHeader";
import NoPersistentChatProfile from "./NoPersistentChatProfile";

type Props = {
  fullHeight?: boolean;
  isUserRequired?: boolean;
  showHeader?: boolean;
  userID?: string;
};

const NoPersistentChat = ({
  fullHeight = true,
  isUserRequired = false,
  showHeader = true,
  userID,
}: Props) => {
  const { authData, authReady } = useAuthData();
  const history = useHistory();

  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const [createPersistentChat] = useCreatePersistentChatMutation();
  const { validateMessage } = useValidateMessage();

  const { data: userData } = useFetchUserEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-and-network",
    skip: !authData?.me.id || !userID,
    variables: { id: `${userID}-${authData?.me.id}` },
  });

  const userEdge = nodeAs(userData?.node, ["UserEdge"]);
  const user = userEdge?.node;

  const { superTab } = useRouteParams();

  const onFormSubmit = () => {
    if (!user || !authData || !editor.current) {
      return;
    }

    const message = editor.current.getMessage();
    message.text = removeLonelyLinks(
      message.text.trim(),
      editor.current.getStreamMessage().attachments
    );

    if (!validateMessage(message)) {
      return;
    }

    createPersistentChat({
      refetchQueries: [
        PersistentChatsDocument,
        FetchUsersDocument,
        FetchUserEdgeDocument,
      ],
      variables: {
        input: {
          message: { attachments: [], text: message.text },
          recipient: user.id,
        },
      },
    }).then(({ data }) => {
      if (!data) return;

      if (superTab === "inbox") {
        history.replace(
          routeToThread({ threadID: data.createPersistentChat.id })
        );
        return;
      }

      history.replace(userPath(undefined, userID));
    });

    editor.current.reset();
  };

  const hideUI = isUserRequired && !userEdge;

  const [safeAreaPadding, setSafeAreaPadding] = useState(false);
  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          setSafeAreaPadding(keyboardHeight > 100);
        }
      ),
    []
  );

  return (
    <div className={tw("flex flex-col grow w-full", { "h-full": fullHeight })}>
      {!hideUI && showHeader && <NoPersistentChatHeader userEdge={userEdge} />}
      {!hideUI && (
        <div className="flex items-end h-full">
          <NoPersistentChatProfile userEdge={userEdge} />
        </div>
      )}
      <div className="no-persistent-chat flex flex-col grow justify-between">
        {fullHeight && <div className="flex-1" />}
        <div className="shrink">
          <div className="px-16 pb-16 text-text-secondary select-none">
            {!hideUI && (
              <>Send a message to start a private chat with {user?.name}.</>
            )}
          </div>

          <MessageEditorContainer
            safeAreaPadding={safeAreaPadding}
            variant="no-persistent-chat"
          >
            <MessageEditor
              ref={editor}
              placeholder="Reply..."
              submitForm={onFormSubmit}
            />
          </MessageEditorContainer>
        </div>
      </div>
    </div>
  );
};

export default NoPersistentChat;
