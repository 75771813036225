import { AttachmentProps } from "stream-chat-react";

import {
  FileType,
  GlueDefaultStreamChatGenerics,
  StreamFileAttachment,
  StreamImageAttachment,
  StreamLinkPreviewAttachment,
  StreamThreadAttachment,
  StreamUnknownAttachment,
} from "@utility-types";
import fixDevApiUrl from "utils/fixDevApiUrl";
import env from "utils/processEnv";

import { ErrorBoundary } from "components/helper";
import ThreadPreview from "../Message/components/ThreadPreview";
import {
  AudioAttachment,
  ExternalObjectOther,
  FileAttachment,
  ImageAttachment,
  OtherAttachment,
  VideoAttachment,
} from "./components";
import AttachmentError from "./components/AttachmentError";

type SortedAttachments = {
  audio: StreamFileAttachment[];
  externalObject: StreamLinkPreviewAttachment[];
  file: StreamFileAttachment[];
  image: StreamImageAttachment[];
  other: StreamUnknownAttachment[];
  video: StreamFileAttachment[];
  thread: StreamThreadAttachment[];
};

const MessageAttachments = <At extends GlueDefaultStreamChatGenerics>({
  attachments,
  compact = false,
  messageId,
}: Omit<AttachmentProps<At>, "attachments"> & {
  attachments: (
    | StreamFileAttachment
    | StreamImageAttachment
    | StreamLinkPreviewAttachment
    | StreamUnknownAttachment
    | StreamThreadAttachment
  )[];
  compact?: boolean;
  messageId: string;
}): JSX.Element | null => {
  if (attachments.length > 0) {
    const sortedAttachments = attachments.reduce<SortedAttachments>(
      (obj, item) => {
        fixDevApiUrls(item);

        if (item?.__typename === "ExternalObject") {
          switch (item.objectType) {
            default:
              obj.externalObject = [...obj.externalObject, item];
              break;
          }
        } else if (item?.__typename === "ThreadPreview") {
          obj.thread = [...obj.thread, item];
        } else {
          switch (item.type) {
            case FileType.Audio:
              obj[item.type] = [...obj[item.type], item];
              break;
            case FileType.File:
              obj[item.type] = [...obj[item.type], item];
              break;
            case FileType.Image:
              obj[item.type] = [...obj[item.type], item];
              break;
            case FileType.Video:
              obj[item.type] = [...obj[item.type], item];
              break;
            default:
              obj.other = [...obj.other, item];
              break;
          }
        }
        return obj;
      },
      {
        audio: [],
        externalObject: [],
        file: [],
        image: [],
        other: [],
        video: [],
        thread: [],
      }
    );

    const imageCount = sortedAttachments.image.length;

    return (
      <ErrorBoundary
        message={
          <AttachmentError message="Error while loading content. Check to make sure you have the latest version." />
        }
      >
        <>
          {sortedAttachments.externalObject.length > 0 && (
            <ExternalObjectOther
              compact={!!compact}
              externalObjects={sortedAttachments.externalObject}
            />
          )}
          {imageCount > 0 && (
            <ImageAttachment id={messageId} images={sortedAttachments.image} />
          )}
          {sortedAttachments.video.length > 0 && (
            <VideoAttachment files={sortedAttachments.video} />
          )}
          {sortedAttachments.audio.length > 0 && (
            <AudioAttachment files={sortedAttachments.audio} />
          )}
          {sortedAttachments.file.length > 0 && (
            <FileAttachment files={sortedAttachments.file} />
          )}
          {sortedAttachments.other.length > 0 && (
            <OtherAttachment attachments={sortedAttachments.other} />
          )}
          {sortedAttachments.thread.length > 0 &&
            sortedAttachments.thread[0]?.id && (
              <ThreadPreview threadID={sortedAttachments.thread[0].id} />
            )}
        </>
      </ErrorBoundary>
    );
  }

  return null;
};

export default MessageAttachments;

// fix local dev URLs to handle changing origins
const fixItemDevApiUrl = <T extends { url: string }>(item: T) => {
  if (env.glueEnv !== "development") return item;

  return {
    ...item,
    url: fixDevApiUrl(item.url),
  };
};

const fixDevApiUrls = (
  item:
    | StreamFileAttachment
    | StreamImageAttachment
    | StreamLinkPreviewAttachment
    | StreamThreadAttachment
    | StreamUnknownAttachment
) => {
  if ("asset_url" in item && item.asset_url) {
    item.asset_url = fixDevApiUrl(item.asset_url);
  }
  if ("image_url" in item && item.image_url) {
    item.image_url = fixDevApiUrl(item.image_url);
  }

  if (item.__typename === "ExternalObject") {
    if (item.icon) {
      item.icon = fixItemDevApiUrl(item.icon);
    }

    item.image = item.image?.map(fixItemDevApiUrl) || null;
    item.video = item.video?.map(fixItemDevApiUrl) || null;
  }
};
