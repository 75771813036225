import { useApolloClient } from "@apollo/client";
import { Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import {
  SubscriptionPlan,
  WorkspaceSubscriptionCheckoutQuery,
  WorkspaceSubscriptionCheckoutQueryDocument,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useContext, useEffect, useRef } from "react";
import { StripeContext } from "./StripeContextProvider";

export type Props = ModalProps & {
  onComplete?: () => void;
  planID?: SubscriptionPlan["id"];
  refetchSubscription?: () => void;
  workspaceID?: string;
};

const PaymentModal = ({
  planID,
  refetchSubscription,
  workspaceID,
  ...props
}: Props) => {
  const apolloClient = useApolloClient();
  const { authData, authReady } = useAuthData();

  const checkoutRef = useRef<
    | Awaited<ReturnType<Exclude<typeof stripe, null>["initEmbeddedCheckout"]>>
    | undefined
  >();
  const checkoutLoading = useRef(false);
  const checkoutPlaceholderRef = useRef<HTMLDivElement | null>(null);

  const { stripe } = useContext(StripeContext);

  useEffect(() => {
    if (!workspaceID || props.state === "closing") return;

    (async () => {
      if (
        !authReady ||
        checkoutLoading.current ||
        !checkoutPlaceholderRef.current ||
        !checkoutRef ||
        !planID
      )
        return;
      checkoutLoading.current = true;

      if (!checkoutRef.current) {
        checkoutRef.current = await stripe?.initEmbeddedCheckout({
          fetchClientSecret: async () => {
            const response =
              await apolloClient.query<WorkspaceSubscriptionCheckoutQuery>({
                query: WorkspaceSubscriptionCheckoutQueryDocument,
                fetchPolicy: "network-only",
                variables: {
                  input: {
                    subscriptionPlanID: planID,
                  },
                  workspaceID,
                },
              });
            const { stripeClientSecret } =
              response.data.workspaceSubscriptionCheckout;
            return stripeClientSecret;
          },
          onComplete: () => {
            refetchSubscription?.();
          },
        });

        if (checkoutRef.current) {
          checkoutRef.current.mount(checkoutPlaceholderRef.current);
        }
      }

      checkoutLoading.current = false;
    })();
  }, [
    apolloClient,
    authData,
    authReady,
    planID,
    props.state,
    refetchSubscription,
    stripe,
    workspaceID,
  ]);

  useEffect(() => {
    if (props.state !== "closing") return;
    checkoutRef.current?.destroy();
  }, [props.state]);

  // afterClose, fire `refetchSubscription` again for redundancy; onComplete has been flaky above
  return (
    <StandardModal {...props} afterClose={refetchSubscription}>
      <Main className="py-12">
        <div ref={checkoutPlaceholderRef} />
      </Main>
    </StandardModal>
  );
};

export default PaymentModal;
