import { MutableRefObject, useRef, useState } from "react";

import { Clipboard } from "@capacitor/clipboard";
import { animated, useSpring } from "@react-spring/web";
import { Copy } from "react-feather";
import { useFormContext } from "react-hook-form";

import tw from "utils/tw";

import { Label } from "./Label";

export const CopyButton = ({
  contentRef,
  disabled,
  onCopy,
}: {
  contentRef: MutableRefObject<HTMLInputElement | null>;
  disabled: boolean;
  onCopy?: () => void;
}) => {
  const handleCopy = () => {
    if (!contentRef?.current) return;

    Clipboard.write({
      string: contentRef.current.value,
    });

    onCopy?.();
  };

  return (
    <Copy
      className={tw(
        "flex absolute top-0 right-15 items-center h-full",
        {
          "hover:text-interactive-subtle-hover text-interactive-subtle cursor-pointer":
            !disabled,
        },
        { "text-interactive-subtle-disabled": disabled }
      )}
      onClick={!disabled ? handleCopy : undefined}
    />
  );
};

type Props = {
  className?: string;
  content: string;
  label?: React.ReactNode | string;
  labelClassName?: string;
  wrapperClassName?: string;
};

/**
 * Looks like a form input, but is static;
 * rocks a copy button.
 */
export const CopyBox = ({
  className,
  content,
  label,
  labelClassName,
  wrapperClassName,
}: Props) => {
  const contentRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const {
    formState: { isSubmitting },
  } = useFormContext();

  const styles = useSpring({ opacity: copied ? 1 : 0 });

  const handleOnCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div className={tw("my-20", wrapperClassName)}>
      {label && (
        <Label className={tw("justify-between", labelClassName)}>
          <span>{label}</span>
          <animated.span className="text-sm" style={styles}>
            Copied!
          </animated.span>
        </Label>
      )}
      <div
        className={tw(
          "box-border relative p-10 w-full max-w-full min-h-[42px] bg-background-ghost disabled:bg-background-subtle rounded border border-background-subtle focus:outline-none",
          className
        )}
      >
        <input
          ref={contentRef}
          className={tw(
            "box-border w-full max-w-[calc(100%_-_44px)] truncate bg-background-ghost rounded border-none focus:outline-none",
            className
          )}
          type="text"
          value={content}
          readOnly
        />

        <CopyButton
          contentRef={contentRef}
          disabled={isSubmitting}
          onCopy={handleOnCopy}
        />
      </div>
    </div>
  );
};
