import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";

import { uniqBy } from "lodash-es";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";

import { Recipient, Workspace } from "@utility-types";
import EditableAvatar from "components/Avatar/EditableAvatar";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import {
  Form,
  Label,
  SubmitButton,
  TextInput,
  Toggle,
  useDisableSubmit,
} from "components/design-system/Forms";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import Hr from "components/design-system/Hr";
import { apiURL } from "components/routing/utils";
import { MemberRole as MemberRoleValue } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import { ProviderApiKeyInput } from "./ProviderApiKeyInput";
import { useLlmProviders } from "./hooks/useLlmProviders";

type MemberRole = { member: string; role: MemberRoleValue };

const memberRoles = (recipients: Recipient[]): MemberRole[] =>
  uniqBy(
    recipients.map(r => ({
      member: r.id,
      role: MemberRoleValue.Default,
    })),
    "member"
  );

type WorkspaceInput = {
  avatarURL?: string | null;
  domains: string[];
  members: MemberRole[];
  name: string;
};

type FormData = {
  avatarURL?: string | null;
  joinable: boolean;
  members: Recipient[];
  name: string;
};

type Props = {
  editMode?: boolean;
  loading?: boolean;
  onSave: (id: string, input: WorkspaceInput) => Promise<unknown>;
  title?: string;
  workspace?: Workspace;
};

export type FormRef = {
  submitDisabled: boolean;
  submitForm: () => void;
};

type FieldsProps = Partial<Props> & {
  domainAvatarURL: string | null;
  onSubmit: SubmitHandler<FormData>;
};

const WorkspaceModalFields = forwardRef<FormRef, FieldsProps>(
  (
    {
      domainAvatarURL,
      editMode,
      onSubmit,
      title = "",
      workspace,
      ...props
    }: FieldsProps,
    ref
  ) => {
    const availableAiProviders = useLlmProviders(workspace?.id);
    const { authData } = useAuthData();
    const authDomain = authData?.me.addressDomains[0];

    const { handleSubmit, setValue } = useFormContext<FormData>();

    const avatarURL = useWatch({ name: "avatarURL" });
    const joinable = useWatch({ name: "joinable" });
    const name = useWatch({ name: "name" });

    const submitForm = useCallback(() => {
      handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit]);

    const disabled = useDisableSubmit();

    useImperativeHandle(
      ref,
      () => ({
        submitDisabled: disabled,
        submitForm,
      }),
      [submitForm, disabled]
    );

    useEffect(() => {
      let nextAvatarURL = avatarURL === domainAvatarURL ? null : avatarURL;
      nextAvatarURL ??= joinable ? domainAvatarURL : null;
      setValue("avatarURL", nextAvatarURL);
    }, [avatarURL, domainAvatarURL, joinable, setValue]);

    const SaveButton = useMemo(
      () => (
        <SubmitButton>
          {workspace?.id !== undefined ? "Save Workspace" : "Create Workspace"}
        </SubmitButton>
      ),
      [workspace?.id]
    );

    const CommonFormFields = useMemo(
      () => (
        <>
          <h2 className="m-0 text-base font-bold">Workspace settings</h2>
          <p className="text-body text-text-subtle m-0 mb-20">
            Workspaces are shared environments for teams, consisting of users
            and groups.
          </p>

          <div className="flex justify-start items-center gap-16 mb-32">
            <EditableAvatar
              avatarURL={avatarURL}
              name={name}
              onAvatarUpdate={avatarUrl =>
                setValue("avatarURL", avatarUrl, { shouldDirty: true })
              }
              rounded="rounded-lg"
              size="x-large"
            />
            <div className="grow">
              <Label htmlFor="name">Name</Label>
              <TextInput<FormData>
                autoComplete="off"
                config={{ required: true }}
                data-testid="workspace-name"
                name="name"
                placeholder="Company name"
                wrapperClassName="!my-0"
              />
            </div>
          </div>

          {!editMode && (
            <RecipientsSelect
              label="Members"
              name="members"
              placeholder="Users or emails..."
              selectGroups={false}
              wrapperClassName="mt-0 mb-32"
            />
          )}

          {authDomain && (
            <div className="mb-32">
              <Label className="!mb-0">Domain Access</Label>
              <Toggle
                label={
                  <span>
                    Allow anyone with <strong>{authDomain}</strong> email
                    address to join?
                  </span>
                }
                labelClassName="justify-between w-full"
                name="joinable"
                wrapperClassName="!my-0"
              />
            </div>
          )}

          {workspace?.id && (
            <>
              <Hr className="mb-20" />

              <div className="flex flex-col gap-12 mb-32">
                <div>
                  <h2 className="m-0 text-base font-bold">Glue AI</h2>
                  <div className="text-body text-text-subtle">
                    Add API keys to lift usage limits:
                  </div>
                </div>

                {availableAiProviders?.map(
                  ({ id, provider, name, iconSrc, keyPreview }) => (
                    <ProviderApiKeyInput
                      key={provider}
                      id={id}
                      keyPreview={keyPreview}
                      provider={provider}
                      iconSrc={iconSrc}
                      name={name}
                      workspaceID={workspace?.id}
                    />
                  )
                )}
              </div>
            </>
          )}

          <div className="flex justify-end items-center mb-16">
            <div className="hidden md:flex">{editMode && SaveButton}</div>
          </div>
        </>
      ),
      [
        SaveButton,
        authDomain,
        avatarURL,
        editMode,
        name,
        setValue,
        workspace?.id,
        availableAiProviders,
      ]
    );

    return editMode ? (
      <div className="flex flex-col h-full py-16">{CommonFormFields}</div>
    ) : (
      <StandardModal
        contentHandlesSafeArea={false}
        header={
          <Header
            mobileCtaLabel={workspace?.id !== undefined ? "Save" : "Create"}
            mobileCtaProps={{ disabled, type: "submit" }}
            variant="bordered"
          >
            <h3 className="m-0">{title}</h3>
          </Header>
        }
        {...props}
      >
        <Main className="px-16 py-20 md:px-32">{CommonFormFields}</Main>

        <Footer className="hidden md:flex">{SaveButton}</Footer>
      </StandardModal>
    );
  }
);

export const WorkspaceModalForm = forwardRef<FormRef, Props & ModalProps>(
  (
    { loading, onSave, workspace, ...props }: Props & ModalProps,
    ref
  ): JSX.Element => {
    const { authData } = useAuthData();
    const authDomain = authData?.me.addressDomains[0];

    const defaultDomains = useMemo(
      () => workspace?.domains || (authDomain ? [authDomain] : []),
      [authDomain, workspace?.domains]
    );

    const domainAvatarURL = useMemo(() => {
      const domain = defaultDomains[0];
      if (domain) {
        return apiURL(
          `/proxy/${encodeURIComponent(
            `https://logo.clearbit.com/${domain}`
          )}/image`
        );
      }

      return null;
    }, [defaultDomains]);

    const onSubmit = useCallback(
      (data: FormData) =>
        onSave(workspace?.id || "", {
          ...data,
          domains: data.joinable ? (authDomain ? [authDomain] : []) : [],
          members: memberRoles(data.members),
        }),
      [authDomain, onSave, workspace?.id]
    );

    const isJoinable = useMemo(
      () => !!(authDomain && defaultDomains.includes(authDomain)),
      [authDomain, defaultDomains]
    );

    const defaultValues = useMemo(
      () => ({
        avatarURL: workspace?.avatarURL,
        joinable: isJoinable,
        members:
          workspace?.members.edges.map(e => e.node) ||
          (authData ? [authData.me] : []),
        name: workspace?.name || "",
      }),
      [
        authData,
        isJoinable,
        workspace?.avatarURL,
        workspace?.members.edges,
        workspace?.name,
      ]
    );

    return (
      <Form
        className="h-full"
        loading={loading}
        onSubmit={onSubmit}
        resetDefault={false}
        useFormProps={{
          defaultValues,
        }}
        onSubmitReset
      >
        <WorkspaceModalFields
          ref={ref}
          domainAvatarURL={domainAvatarURL}
          onSubmit={onSubmit}
          workspace={workspace}
          {...props}
        />
      </Form>
    );
  }
);

export default WorkspaceModalForm;
