import { Button } from "components/design-system/Button";
import { LoadingSpinner } from "components/design-system/LoadingSpinner";
import Icon from "components/design-system/icons/Icon";
import tw from "utils/tw";

type Props = {
  onSummarize: () => void;
  summarizing: boolean;
};

const SummarizeButton = ({ onSummarize, summarizing }: Props) => {
  return (
    <Button
      buttonStyle="icon-secondary"
      buttonType="icon"
      className="size-28 justify-center rounded-md border-1 border-border-container hover:!bg-background-secondary hover:!text-icon-magic-hover"
      disabled={summarizing}
      onClick={e => {
        e.stopPropagation();
        onSummarize();
      }}
      tooltip="Summarize thread"
      tooltipPlacement="bottom"
      tooltipStyle="inverted"
    >
      {summarizing ? (
        <LoadingSpinner
          className={tw(
            "w-20 h-20 text-icon-secondary animate-spin transition-opacity",
            "opacity-100"
          )}
        />
      ) : (
        <Icon icon="SparkleFilled" size={20} />
      )}
    </Button>
  );
};

export default SummarizeButton;
