import { useRemirrorContext } from "@remirror/react";
import { AtSign } from "react-feather";

import { Button } from "components/design-system/Button";
import useNativeHaptics from "hooks/native/useNativeHaptics";
import tw from "utils/tw";

import buttonStyles from "../../buttonStyles";

type Props = {
  readOnly: boolean;
};

const MentionButton = ({ readOnly }: Props) => {
  const { chain, commands, getState, helpers } = useRemirrorContext();
  const { lightImpactHaptic } = useNativeHaptics();

  const handleAddMention = () => {
    const selection = getState().selection;
    const charBeforeRange = { from: selection.from - 1, to: selection.to };
    const characterBefore = helpers.getTextBetween(
      charBeforeRange.from,
      charBeforeRange.to
    );
    const currentPosition = selection.from;
    const txt = `${
      ![" ", "@"].includes(characterBefore) && currentPosition !== 1 ? " " : ""
    }@`;

    lightImpactHaptic();

    if (characterBefore === "@") {
      // deleting the char to trigger a change which will trigger the mention results popup
      commands.delete(charBeforeRange);
    }

    chain
      .replaceText({
        content: txt,
        range:
          characterBefore === "@"
            ? { from: charBeforeRange.from, to: charBeforeRange.from }
            : { from: selection.from, to: selection.to },
      })
      .focus(
        characterBefore === "@"
          ? charBeforeRange.from + txt.length
          : selection.from + txt.length
      )
      .run();
  };

  return (
    <Button
      buttonStyle="subtle"
      className={tw(buttonStyles())}
      disabled={readOnly}
      icon={AtSign}
      iconSize={20}
      name="mention"
      onClick={handleAddMention}
      type="button"
    />
  );
};

export default MentionButton;
