import { useCallback } from "react";

import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

import tw from "utils/tw";

import Error from "./Error";
import { RadioGroupProps, RadioOption } from "./types";

export const RadioGroup = <TFieldValues extends FieldValues>({
  config,
  groupClassName,
  label,
  labelClassName,
  name,
  optionLabelClassName,
  options,
  wrapperClassName,
  wrapperSelectedClassname,
  ...props
}: RadioGroupProps<TFieldValues>) => {
  const {
    formState: { errors, isSubmitting },
    register,
    setValue,
    watch,
  } = useFormContext<TFieldValues>();

  const selectedOption = watch(name);

  const disabled = props.disabled || isSubmitting;

  const stateBasedStyling = useCallback(
    (option: RadioOption) => ({
      "border-accent-error": !(disabled || option.disabled) && !!errors[name],
      "border-text-disabled": disabled || option.disabled,
    }),
    [disabled, errors, name]
  );

  return (
    <ul className={tw("my-20", groupClassName)}>
      {label && (
        <li
          className={tw(
            "flex items-center mb-5 text-base font-normal text-text-subtle select-none",
            labelClassName
          )}
        >
          {label}
        </li>
      )}

      {options.map(option => {
        const inputID = `${name}-${option.value}`;
        return (
          <li
            key={inputID}
            className={tw(
              "group/label",
              "block mb-10 last:mb-0 text-base font-normal select-none",
              wrapperClassName,
              {
                [wrapperSelectedClassname || ""]:
                  option.value === selectedOption,
              }
            )}
            onClick={() =>
              setValue(
                name,
                option.value as PathValue<TFieldValues, Path<TFieldValues>>,
                { shouldDirty: true }
              )
            }
          >
            <input
              className="hidden"
              id={inputID}
              type="radio"
              value={option.value}
              {...props}
              checked={option.value === selectedOption}
              disabled={disabled || option.disabled}
              {...register(name, config)}
            />
            <label
              className={tw(
                "flex",
                {
                  "cursor-pointer": !disabled && !option.disabled,
                  "text-text-disabled": disabled || option.disabled,
                },
                optionLabelClassName
              )}
              htmlFor={inputID}
            >
              {option.value === selectedOption ? (
                <div
                  className={tw(
                    "flex justify-center items-center mr-8 min-w-[18px] w-18 h-18 rounded-full border-[1.5px] border-accent-primary",
                    stateBasedStyling(option)
                  )}
                >
                  <div
                    className={tw(
                      "shrink-0 w-10 h-10 bg-accent-primary rounded-full",
                      stateBasedStyling(option)
                    )}
                  />
                </div>
              ) : (
                <div
                  className={tw(
                    "flex justify-center items-center mr-8 min-w-[18px] w-18 h-18 rounded-full border-[1.5px] border-icon-secondary group-hover/label:border-accent-primary",
                    stateBasedStyling(option)
                  )}
                />
              )}
              <label className="flex flex-col grow" htmlFor={inputID}>
                <div>{option.label}</div>
                {option.description ? (
                  <div
                    className={tw(
                      "text-sm font-normal leading-4 text-text-subtle mt-4 cursor-pointer"
                    )}
                  >
                    {option.description}
                  </div>
                ) : null}
              </label>
            </label>
          </li>
        );
      })}
      <Error name={name} />
    </ul>
  );
};
