import { ComponentProps } from "react";
import { useHistory } from "react-router";

import useSidebarCounts from "components/SideBar/hooks/useSidebarCounts";
import { Button } from "components/design-system/Button";
import Icon, { IconName } from "components/design-system/icons/Icon";
import { FeedType } from "generated/graphql";
import useShortcut from "hooks/useShortcut";
import { isNativeMac } from "utils/platform";
import tw from "utils/tw";

import {
  RoutingPartition,
  usePartitionState,
} from "components/routing/RoutingPartition";
import {
  useRoutePartition,
  useValidatedTabRoute,
} from "components/routing/utils";
import useRouteMemory from "hooks/__desktop__/useRouteMemory";
import useRoutesStore from "store/useRoutesStore";
import { TabCountBadge, TabDotBadge } from "../TabBadge";

export enum PartitionShortcut {
  Inbox = "1",
  Feed = "2",
  Groups = "3",
  DirectMessages = "4",
  Activity = "5",
}

const className =
  "cursor-pointer font-semibold leading-[26px] mb-24 text-xl select-none focus-visible-border";

const RailItem = ({
  badgeCount,
  badge = badgeCount ? "alert" : undefined,
  children,
  icon,
  iconIsActive,
  isActive,
  label,
  ...props
}: ComponentProps<typeof Button> & {
  badge?: "alert" | "alert-strong";
  badgeCount?: number;
  icon: IconName;
  iconIsActive: IconName;
  isActive: boolean;
  label?: string;
}) => {
  return (
    <Button
      buttonStyle="none"
      buttonType="none"
      className={tw(
        "group/rail-item !my-0 py-8 w-full justify-center border-none",
        className
      )}
      {...props}
      type="button"
    >
      <div className="relative">
        {badgeCount ? (
          <TabCountBadge
            count={badgeCount}
            className="absolute -right-6 -top-6"
          />
        ) : badge ? (
          <TabDotBadge className="absolute right-4 top-4" />
        ) : null}
        {children}
        <div
          className={tw(
            "flex flex-col items-center justify-center text-text-action-inverse"
          )}
        >
          <span
            className={tw(
              "flex justify-center items-center w-36 h-36 mb-4 rounded-lg transition-colors ease-spring duration-75",
              "group-hover/rail-item:bg-background-tab-hover group-focus-within/rail-item:focus-shadow",
              isActive ? "bg-background-tab-selected" : "transparent"
            )}
          >
            <Icon
              className="transition-transform transform-gpu ease-spring duration-75 group-hover:scale-[1.1]"
              icon={isActive ? iconIsActive : icon}
              size={20}
            />
          </span>
          <span className="font-semibold text-xs">{label}</span>
        </div>
      </div>
    </Button>
  );
};

const InboxTab = ({
  unreadCount,
  mentionCount,
}: { unreadCount: number; mentionCount: number }) => {
  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory();

  useShortcut(
    ["Meta", PartitionShortcut.Inbox],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: "inbox",
    route,
  });

  const nextRoute = useValidatedTabRoute(routes, "inbox");

  return (
    <RailItem
      badge={mentionCount ? "alert-strong" : unreadCount ? "alert" : undefined}
      badgeCount={unreadCount}
      icon="Inbox"
      iconIsActive="InboxFilled"
      isActive={!!isActivePartition}
      label="Inbox"
      onClick={() => {
        return history.push(nextRoute);
      }}
    />
  );
};

const FeedTab = ({ unseenCount }: { unseenCount: number }) => {
  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory();

  useShortcut(
    ["Meta", PartitionShortcut.Feed],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: "feed",
    route,
  });

  const nextRoute = useValidatedTabRoute(routes, "feed");

  return (
    <RailItem
      badge={unseenCount ? "alert" : undefined}
      icon="MyFeed"
      iconIsActive="MyFeedFilled"
      isActive={!!isActivePartition}
      label="Feed"
      onClick={() => history.push(nextRoute)}
    />
  );
};

const GroupsTab = () => {
  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory();

  useShortcut(
    ["Meta", PartitionShortcut.Groups],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: "groups",
    route,
  });

  const nextRoute = useValidatedTabRoute(routes, "groups");

  return (
    <RailItem
      icon="Groups"
      iconIsActive="GroupsFilled"
      isActive={!!isActivePartition}
      label="Groups"
      onClick={() => history.push(nextRoute)}
    />
  );
};

const DMsTab = ({ unreadCount }: { unreadCount: number }) => {
  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory();

  useShortcut(
    ["Meta", PartitionShortcut.DirectMessages],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: "dms",
    route: route,
  });

  const nextRoute = useValidatedTabRoute(routes, "dms");

  return (
    <RailItem
      badge={unreadCount ? "alert" : undefined}
      icon="ChatRounded"
      iconIsActive="ChatRoundedFilled"
      isActive={!!isActivePartition}
      label="DMs"
      onClick={() => history.push(nextRoute)}
    />
  );
};

const ActivityTab = ({ unseenCount }: { unseenCount: number }) => {
  const { isActivePartition, route } = usePartitionState(
    ({ isActivePartition, route }) => ({
      isActivePartition,
      route,
    })
  );

  const history = useHistory();

  useShortcut(
    ["Meta", PartitionShortcut.Activity],
    () => isNativeMac() && history.push(route)
  );

  const { routes } = useRouteMemory({
    isActive: !!isActivePartition,
    name: "activity",
    route: route,
  });

  const nextRoute = useValidatedTabRoute(routes, "activity");
  const url = new URL(nextRoute, window.location.origin);
  url.searchParams.set("t", FeedType.All);

  return (
    <RailItem
      badge={unseenCount ? "alert" : undefined}
      icon="Bell"
      iconIsActive="BellFilled"
      isActive={!!isActivePartition}
      label="Activity"
      onClick={() => history.push(url.pathname + url.search)}
    />
  );
};

const AppTabs = () => {
  const { routes } = useRoutesStore(({ routes }) => ({
    routes,
  }));

  const { superTab } = useRoutePartition();

  const {
    unseenFeedCount,
    unreadDirectMessages,
    unreadMentionCount,
    unreadThreadCount,
    unreadNotificationCount,
  } = useSidebarCounts();

  return (
    <div className="flex w-full flex-col select-none">
      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.inbox}
        isActive={superTab === "inbox"}
        unmountInactive={false}
      >
        <InboxTab
          unreadCount={unreadThreadCount}
          mentionCount={unreadMentionCount}
        />
      </RoutingPartition>

      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.feed}
        isActive={superTab === "feed"}
        unmountInactive={false}
      >
        <FeedTab unseenCount={unseenFeedCount} />
      </RoutingPartition>

      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.groups}
        isActive={superTab === "groups"}
        unmountInactive={false}
      >
        <GroupsTab />
      </RoutingPartition>

      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.dms}
        isActive={superTab === "dms"}
        unmountInactive={false}
      >
        <DMsTab unreadCount={unreadDirectMessages} />
      </RoutingPartition>

      <RoutingPartition
        className=""
        classNames={{}} // disable default classNames
        defaultRoute={routes.activity}
        isActive={superTab === "activity"}
        unmountInactive={false}
      >
        <ActivityTab unseenCount={unreadNotificationCount} />
      </RoutingPartition>

      {/* <TrashButton /> */}
    </div>
  );
};

export default AppTabs;
