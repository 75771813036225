import { FormEvent, useRef } from "react";

import { useEditorEvent } from "@remirror/react";
import { Paperclip } from "react-feather";

import { Button } from "components/design-system/Button";
import { isNativeAndroid } from "utils/platform";
import tw from "utils/tw";

import { FileUploadMenu } from "components/FileUploadMenu";

import buttonStyles from "../buttonStyles";

type Props = {
  onAttachFiles: (newFiles: File[]) => void;
  readOnly: boolean;
};

const FileAttachButton = ({ onAttachFiles, readOnly }: Props): JSX.Element => {
  const timestamp = Date.now();
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    event.currentTarget.files && onAttachFiles([...event.currentTarget.files]);
    event.currentTarget.value = "";
  };

  /* istanbul ignore useEditorEvent */
  useEditorEvent("paste", ({ clipboardData }) => {
    if (!clipboardData || clipboardData.files.length === 0) return;

    onAttachFiles([...clipboardData.files]);
  });

  return (
    <Button
      buttonStyle="subtle"
      className={tw(buttonStyles())}
      disabled={readOnly}
      onKeyPress={e =>
        (e.key === "Enter" || e.key === " ") && inputRef.current?.click()
      }
      type="button"
    >
      {!isNativeAndroid() ? (
        <>
          <label
            className="flex overflow-hidden items-center cursor-pointer"
            htmlFor={`file-upload${timestamp}`}
          >
            <Paperclip className="mr-0" size={20} />
            <span className="sr-only">Attach Files</span>
          </label>
          <input
            ref={inputRef}
            className="overflow-hidden absolute -z-1 w-0 h-0 opacity-0"
            disabled={readOnly}
            id={`file-upload${timestamp}`}
            multiple={true}
            onChange={onChange}
            tabIndex={-1}
            type="file"
          />
        </>
      ) : (
        /* istanbul ignore next */
        <FileUploadMenu
          onChange={onChange}
          placement="top-end"
          target={<Button buttonStyle="subtle" icon={Paperclip} />}
          multiple
        />
      )}
    </Button>
  );
};

export default FileAttachButton;
