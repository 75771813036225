import { useCallback } from "react";

import { Check, Edit } from "react-feather";

import { GroupEdge, Mailbox, UserEdge, WorkspaceEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import useModalStore from "store/useModalStore";

import ThreadComposeModal from "components/threads/ThreadComposeModal";
import { formatGroupName } from "utils/group/formatGroupName";

type Props = {
  mailbox: Mailbox | "drafts";
  recipient?: UserEdge | GroupEdge | WorkspaceEdge | undefined;
};

export const EmptyList = ({
  mailbox,
  recipient,
}: Props): JSX.Element | null => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openThreadCompose = useCallback(() => {
    openModal(
      <ThreadComposeModal
        initialDraft={recipient ? { recipients: [recipient.node] } : undefined}
      />
    );
  }, [openModal, recipient]);

  return (
    <li className="flex flex-row grow justify-center items-center my-48 w-full select-none">
      {mailbox === Mailbox.Inbox ? (
        <div className="text-2xl font-semibold text-interactive-subtle opacity-50">
          <Check className="inline-block" size="32" /> All done.
        </div>
      ) : (
        <div className="flex flex-wrap justify-center items-center mx-20 text-xl font-semibold text-interactive-subtle">
          <span className="my-5 cursor-pointer">
            <Button
              buttonStyle="simplePrimary"
              buttonType="none"
              className="flex justify-center"
              onClick={openThreadCompose}
            >
              <Edit className="mr-8" size={20} />
              Start a thread
            </Button>
          </span>
          {recipient ? (
            <span>
              &nbsp;with{" "}
              {recipient.__typename === "UserEdge"
                ? recipient.node.name
                : formatGroupName(recipient.node).nameWithEmoji}
            </span>
          ) : null}
        </div>
      )}
    </li>
  );
};

export default EmptyList;
