import {
  ComponentProps,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from "react";

import PhotoSwipe from "photoswipe";
import PhotoSwipeUIDefault from "photoswipe/dist/photoswipe-ui-default";

import { LayerProvider, LayerStackPriority } from "providers/LayerProvider";
import usePhotoSwipeStore from "store/usePhotoSwipeStore";
import getElementBounds from "utils/getElementBounds";
import { isNativeMobile } from "utils/platform";

import Context from "./Context";

import { PhotoSwipeItem } from ".";

type InternalItem = Omit<ComponentProps<typeof PhotoSwipeItem>, "children">;

type ItemRef = MutableRefObject<HTMLElement | null>;

interface IPhotoSwipeItem extends PhotoSwipe.Item {
  el: HTMLElement;
  pid?: string | number;
}

type Props = {
  /**
   * Gallery ID, for hash navigation
   */
  id?: string | number;
  /**
   * Triggers after PhotoSwipe.init() call
   *
   * Use it for accessing PhotoSwipe API
   *
   * https://photoswipe.com/documentation/api.html
   */
  onOpen?: (photoSwipe: PhotoSwipe<PhotoSwipe.Options>) => void;
  /**
   * PhotoSwipe options
   *
   * https://photoswipe.com/documentation/options.html
   */
  options?: PhotoSwipe.Options;
};

const PhotoSwipeGallery = ({
  children,
  id: galleryUID,
  onOpen,
  options,
}: WithChildren<Props>): JSX.Element => {
  const items = useRef(new Map<ItemRef, InternalItem>());
  const openedPId = useRef<string>();

  const { setState } = usePhotoSwipeStore;
  const { open: isPhotoSwipeOpen, photoSwipeLayoutElement } =
    usePhotoSwipeStore(({ open, photoSwipeLayoutElement }) => ({
      open,
      photoSwipeLayoutElement,
    }));
  const { open: isOpen } = usePhotoSwipeStore(({ open }) => ({ open }));

  const open = useCallback(
    async (targetRef?: ItemRef | null, targetId = "", itemIndex?: number) => {
      if (isPhotoSwipeOpen) return;
      let index: number | null = itemIndex || null;
      const normalized: IPhotoSwipeItem[] = [];
      const entries = Array.from(items.current);

      const prepare = (entry: [ItemRef, InternalItem], i: number) => {
        const [
          ref,
          { fullSizeURL, height, id: pid, thumbnail, title, width, ...rest },
        ] = entry;
        if (
          targetRef === ref ||
          (pid !== undefined && String(pid) === targetId)
        ) {
          index = i;
        }

        if (!ref.current) return;

        normalized.push({
          el: ref.current,
          h: Number(height),
          msrc: thumbnail,
          src: fullSizeURL,
          title,
          w: Number(width),
          ...(pid !== undefined ? { pid } : {}),
          ...rest,
        });
      };

      if (items.current.size > 1) {
        entries
          .sort(([{ current: a }], [{ current: b }]) => sortNodes(a, b))
          .forEach(prepare);
      } else {
        entries.forEach(prepare);
      }

      const layoutEl = photoSwipeLayoutElement;

      if (layoutEl) {
        const instance = new PhotoSwipe(
          layoutEl,
          PhotoSwipeUIDefault,
          normalized,
          {
            getThumbBoundsFn: thumbIndex => {
              const { el } = normalized[thumbIndex] || { el: null };
              if (!el) return { w: 0, x: 0, y: 0 };
              const { width, XOffset, YOffset } = getElementBounds(el);
              return { w: width, x: XOffset, y: YOffset };
            },
            history: false,
            index: index === null ? Number.parseInt(targetId, 10) - 1 : index,
            ...(galleryUID !== undefined
              ? { galleryUID: galleryUID as number, history: true }
              : {}),
            ...(options || {}),
          }
        );

        instance.init();
        setState({ open: true });

        usePhotoSwipeStore.setState({
          currentImageOriginalURL: instance.currItem.originalURL,
        });

        instance.listen("beforeChange", () => {
          usePhotoSwipeStore.setState({
            currentImageOriginalURL: instance.currItem.originalURL,
          });
        });

        if (!isNativeMobile()) {
          instance.options.shareButtons = [
            {
              download: true,
              id: "download",
              label: "Download image",
              url: "{{raw_image_url}}",
            },
          ];
        }

        instance.options.getImageURLForShare = _data => {
          const { src, title } = instance.currItem;
          return src.replace(/\?imgix(.*)/gi, `?imgix=dl=${title}`);
        };

        instance.listen("shareLinkClick", (_e, target) => {
          const parent = target.parentElement as HTMLDivElement;
          if (!parent) return;
          parent.removeChild(target);
          parent.parentElement?.classList.remove("pswp__share-modal--fade-in");
          if (!isNativeMobile()) return;
          // TODO: Open native share UI instead of default https://github.com/gluegroups/glue-web/issues/487
          instance.close();
        });

        instance.listen("destroy", () => {
          setState({ open: false });
          window.location.hash = "";
          openedPId.current = undefined;
        });

        if (onOpen !== undefined && typeof onOpen === "function") {
          onOpen(instance);
        }
      }
    },
    [
      isPhotoSwipeOpen,
      photoSwipeLayoutElement,
      galleryUID,
      options,
      setState,
      onOpen,
    ]
  );

  const remove = useCallback((ref: React.RefObject<HTMLElement | null>) => {
    items.current.delete(ref);
  }, []);

  const set = useCallback(
    (ref: React.RefObject<HTMLElement | null>, data: InternalItem) => {
      items.current.set(ref, data);
    },
    []
  );

  const openAt = useCallback(
    (index: number) => {
      open(null, undefined, index);
    },
    [open]
  );

  useEffect(() => {
    if (galleryUID === undefined) return;

    if (!photoSwipeLayoutElement) return;

    window.requestAnimationFrame(() => {
      const hash = window.location.hash.substring(1);
      const params: { [key: string]: string } = {};

      if (hash.length < 5) {
        return;
      }

      const vars = hash.split("&");

      vars.forEach(variable => {
        const [key, value] = variable.split("=");
        if (key && value) {
          params[key] = value;
        }
      });

      const { gid, pid } = params;

      if (pid && gid === String(galleryUID) && !openedPId.current) {
        open(null, pid);
        openedPId.current = pid;
      }
    });
  }, [open, galleryUID, photoSwipeLayoutElement]);

  return (
    <LayerProvider
      id={LayerStackPriority[LayerStackPriority.Topmost]}
      isActive={isOpen}
      zIndex={LayerStackPriority.Topmost}
    >
      <Context.Provider
        value={{ handleClick: open, open: openAt, remove, set }}
      >
        {children}
      </Context.Provider>
    </LayerProvider>
  );
};

export default PhotoSwipeGallery;

function sortNodes(a: Element | null, b: Element | null): number {
  if (!(a instanceof Element) || !(b instanceof Element)) {
    throw new Error(
      "PhotoSwipeGallery: No valid `ref` provided. \n You should use `ref` from render prop of PhotoSwipeItem component.\n\n Example: \n\n <PhotoSwipeItem>{({ ref }) => <div ref={ref}></div>}</PhotoSwipeItem>\n"
    );
  }

  if (a === b) return 0;
  if (a.compareDocumentPosition(b) & 2) {
    return 1;
  }
  return -1;
}
