import { Button } from "components/design-system/Button";
import type { TWMargin } from "components/design-system/types/margin";
import { useRouteMemoryStackBack } from "hooks/__mobile__/useRouteMemoryStack";
import { useHistory } from "react-router";
import breakpoints from "utils/breakpoints";
import tw from "utils/tw";

import {
  VariantPropsOf,
  variantProps,
} from "components/helper/classNameVariants";

const className = {
  base: "text-body",
  defaultVariants: {
    position: "default" as const,
    size: "normal" as const,
  },
  variants: {
    position: {
      default: "relative right-4 pr-16 pl-20 -ml-16",
      groupHeader: "relative right-4 pl-20 -ml-16",
      profile: "absolute top-16 left-0 pl-16 w-60",
      secondaryView: "relative right-4",
    },
    size: {
      normal: "h-44 min-w-44 px-4",
      small: "h-24 min-w-24 px-2",
    },
  },
};

const buttonProps = variantProps(className);

type ButtonVariantProps = VariantPropsOf<typeof buttonProps>;

const BackStackButton = ({
  backPath,
  label,
  margin,
  ...props
}: {
  backPath?: string;
  label?: string;
  margin?: TWMargin;
} & ButtonVariantProps) => {
  const history = useHistory();
  const { handleBack } = useRouteMemoryStackBack();

  if (breakpoints().md) return null;

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    backPath ? history.replace(backPath) : handleBack(e);

  return (
    <Button
      {...buttonProps({
        className: tw(margin),
        ...props,
      })}
      buttonStyle="none"
      buttonType="none"
      icon="ChevronLeft"
      iconClassName=""
      iconSize={24}
      onClick={onClick}
      type="button"
    >
      {label && <span className="inline-block ml-2">{label}</span>}
    </Button>
  );
};

export default BackStackButton;
