import { Mailbox } from "@utility-types";
import ThreadView from "components/threads/ThreadView";
import NoPersistentChat from "components/user/NoPersistentChat";
import EmptyView from "components/views/EmptyView";

const ThreadPane = ({
  messageID,
  secondaryPane,
  threadID,
}: { messageID?: string; secondaryPane?: boolean; threadID?: string }) => {
  if (!threadID) {
    return <EmptyView />;
  }

  const isUser = threadID?.match(/^usr_/);
  if (isUser) {
    return <NoPersistentChat userID={threadID} />;
  }

  return (
    <ThreadView
      mailbox={Mailbox.All}
      messageID={messageID}
      secondaryPane={secondaryPane}
      stackPriority={0}
      threadID={threadID}
    />
  );
};

export default ThreadPane;
