import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { User } from "@utility-types";
import { Button } from "components/design-system/Button";
import { routePath } from "components/routing/utils";
import { MemberRole } from "generated/graphql";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";
import { Badge } from "./Badge";
import { MembersListItemDetails } from "./MembersListItemDetails";
import { Select } from "./Select";

enum MemberChangeAction {
  MakeAdmin = "admin",
  MakeMember = "default",
  Remove = "remove",
}

type Props = {
  member: User & {
    role: MemberRole;
    pending?: boolean;
    badge?: React.ReactNode;
  };
  onUpdateRole: (params: { id: string; role: MemberRole }) => void;
  onDelete: (id: string) => void;
  onResend?: (id: string) => Promise<void>;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  canMessage: boolean;
  canEdit: boolean;
};

export function MembersListItem({
  member,
  onUpdateRole,
  onDelete,
  onResend,
  isEditing,
  setIsEditing,
  canMessage = true,
  canEdit,
}: Props) {
  const [isPendingUpdate, setIsPendingUpdate] = useState(false);
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const history = useHistory();
  const pathToMember = routePath({ recipientID: member.id });

  const handleResend = onResend
    ? async () => {
        setIsPendingUpdate(true);
        await onResend(member.id);
        setIsPendingUpdate(false);
      }
    : undefined;

  const handleChange = (value: string) => {
    setIsEditing(false);

    switch (value) {
      case MemberChangeAction.MakeAdmin:
        onUpdateRole({
          id: member.id,
          role: MemberRole.Admin,
        });
        break;
      case MemberChangeAction.MakeMember:
        onUpdateRole({
          id: member.id,
          role: MemberRole.Default,
        });
        break;
      case MemberChangeAction.Remove:
        onDelete(member.id);
        break;
    }
  };

  return (
    <li className="flex items-center gap-20 min-h-[46px]">
      <Link
        className="flex min-w-0 focus-visible-shadow mr-auto"
        to={pathToMember}
      >
        <MembersListItemDetails
          name={member.name}
          avatarURL={member.avatarURL}
          subtitles={[member.bio ?? "", member.addressDomains[0] ?? ""]}
          isAdmin={member.role === MemberRole.Admin}
          badge={member.pending ? <Badge>Invited</Badge> : member.badge}
        />
      </Link>

      {member.pending && handleResend && (
        <Button
          disabled={isPendingUpdate}
          type="button"
          icon="Refresh"
          className="text-subhead-bold"
          buttonStyle="subtle"
          buttonType="text"
          iconSize={20}
          onClick={handleResend}
        >
          Resend
        </Button>
      )}

      {!member.pending && canMessage && (
        <Button
          aria-label="Message"
          icon="ChatRounded"
          iconSize={breakpointMD ? 20 : 24}
          buttonType="icon"
          buttonStyle="icon-secondary"
          onClick={() => history.push(pathToMember)}
          className={tw({ hidden: isEditing })}
        />
      )}

      {canEdit && (
        <>
          {(breakpointMD || isEditing) && (
            <Select
              onChange={handleChange}
              value={member.role}
              className="min-w-[110px]"
              options={[
                { value: MemberChangeAction.MakeAdmin, label: "Admin" },
                { value: MemberChangeAction.MakeMember, label: "Member" },
                {
                  value: MemberChangeAction.Remove,
                  label: "Remove user",
                  className: "text-text-alert",
                },
              ]}
            />
          )}
          {isEditing ? (
            <Button
              aria-label="Done editing"
              icon="Close"
              buttonType="icon"
              iconSize={24}
              buttonStyle="icon-secondary"
              onClick={() => setIsEditing(false)}
              className="md:hidden"
            />
          ) : (
            <Button
              aria-label="Edit"
              icon="EditSimple"
              iconSize={20}
              buttonType="icon"
              buttonStyle="icon-secondary"
              onClick={() => setIsEditing(true)}
              className="md:hidden"
            />
          )}
        </>
      )}
    </li>
  );
}
