import { memo } from "react";

import { RefreshCcw } from "react-feather";

import { FileUploadState } from "@utility-types";
import { Button } from "components/design-system/Button";
import getFormattedFileSizeString from "utils/getFormattedFileSizeString";
import tw from "utils/tw";

import { FileIcon } from "components/helper";

import { LoadingIndicator } from ".";

type Props = {
  handleRetry: (id: string) => void;
  id: string;
  loadingState: FileUploadState;
  name: string;
  size: number;
  type: string;
  url?: string;
};

const FileAttachment = memo(
  ({
    handleRetry,
    id,
    loadingState,
    name,
    size,
    type,
    url,
  }: Props): JSX.Element => (
    <div
      className={tw(
        "relative h-80 rounded-md border",
        loadingState === "failed"
          ? "border-accent-error"
          : "border-background-subtle"
      )}
    >
      {loadingState === "uploading" && <LoadingIndicator />}
      <div className="flex justify-center items-center px-10 h-full">
        <span data-testid="file-icon">
          <FileIcon filename={name} mimeType={type} />
        </span>
        <div className="ml-10 h-36" style={{ maxWidth: "calc(100% - 30px)" }}>
          {loadingState === "failed" ? (
            <>
              {name}
              <Button
                buttonStyle="none"
                buttonType="none"
                className="absolute justify-center w-full h-full bg-accent-error/50 rounded-md top-0 left-0 flex items-center"
                data-testid="uploading-file-failed"
                onClick={() => handleRetry(id)}
              >
                <RefreshCcw className="text-background" />
              </Button>
            </>
          ) : (
            <a
              className="block truncate text-base font-semibold text-text-strong"
              href={url}
              download
              {...(loadingState !== "uploading"
                ? { "data-testid": "file-uploaded" }
                : {})}
            >
              {name}
            </a>
          )}

          {loadingState !== "failed" && (
            <div className="block truncate text-sm text-text-subtle">
              {getFormattedFileSizeString(size)}
            </div>
          )}
        </div>
      </div>
    </div>
  ),
  (prev, next) =>
    prev.loadingState === next.loadingState &&
    prev.name === next.name &&
    prev.size === next.size
);

export default FileAttachment;
