import { AlertTriangle, EyeOff, Icon } from "react-feather";

import { ModalProps } from "components/ModalKit/Modal";
import { Header, Main } from "components/ModalKit/Parts";
import { Button } from "components/design-system/Button";
import { useDeleteAccountMutation } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";

import { ConfirmationModal, StandardModal } from "components/Modals";
import Avatar from "components/design-system/Avatar/Avatar";

type ActionInfoProps = {
  icon: Icon;
  info: string;
  size: number | string;
  title: string;
};

const DeleteAccountModal = ({
  parentModalId,
  ...props
}: ModalProps & {
  parentModalId: ModalProps["modalId"];
}): JSX.Element | null => {
  const { authData, signOut } = useAuthData();
  const { openSnackbar } = useSnackbar();
  const { closeModals, openModal } = useModalStore(
    ({ closeModals, openModal }) => ({
      closeModals,
      openModal,
    })
  );

  const [deleteAccount] = useDeleteAccountMutation({ errorPolicy: "all" });

  const handleDeleteAccount = (deactivate: boolean) => {
    openModal(
      <ConfirmationModal
        confirmLabel={deactivate ? "Deactivate" : "Delete"}
        header={`Are you sure you want to ${
          deactivate ? "deactivate" : "delete"
        } your account?`}
        onConfirm={() =>
          deleteAccount({ variables: { input: { deactivate } } }).then(() => {
            signOut();

            const modalsToClose = parentModalId ? [parentModalId] : [];
            if (props.modalId) modalsToClose.push(props.modalId);
            closeModals(modalsToClose);

            openSnackbar(
              "info",
              `👋 Your account will be ${
                deactivate ? "deactivated" : "deleted"
              }.`,
              10000
            );
          })
        }
        isDestructive
      />
    );
  };

  const ActionInfo = ({ icon, info, size, title }: ActionInfoProps) => {
    const Icon = icon;
    return (
      <div className="flex gap-15 justify-between items-start">
        <Icon className={`text-text-subtle ${size}`} size={18} />
        <div className="flex flex-col">
          <p className="m-0 mb-10 text-base font-bold">{title}</p>
          <p className="m-0 text-sm text-text-subtle">{info}</p>
        </div>
      </div>
    );
  };

  const deactivateInfo = `Your profile, workspace, group and thread memberships will
   be temporarily suspended until you ask us to re-activate your account for you.`;

  const deleteInfo = `Your profile, workspace, group, and thread 
   memberships will be permanently deleted.`;

  return (
    <StandardModal
      header={
        <Header className="font-bold" variant="bordered">
          <h3 className="m-0">Delete Account</h3>
        </Header>
      }
      {...props}
    >
      <Main className="px-16 md:px-32">
        <div className="flex flex-col gap-30 items-center pt-16 h-full md:h-auto">
          <Avatar avatarURL={authData?.me.avatarURL} size="x-large" rounded />
          <h2 className="m-0 text-center">
            Deactivate your account <br /> instead of deleting it?
          </h2>
          <div className="flex flex-col gap-20 px-20 md:px-60">
            <ActionInfo
              icon={EyeOff}
              info={deactivateInfo}
              size={"w-48 md:w-36"}
              title="Deactivating your account is temporary"
            />
            <ActionInfo
              icon={AlertTriangle}
              info={deleteInfo}
              size={"w-32 md:w-24"}
              title="Deleting your account is permanent"
            />
          </div>
          <div className="flex flex-col gap-20 mt-auto">
            <Button
              className="justify-center p-5 px-12 text-text-accent"
              onClick={() => handleDeleteAccount(true)}
            >
              Deactivate Account
            </Button>
            <Button
              buttonStyle="simpleDestructive"
              className="justify-center mb-20"
              onClick={() => handleDeleteAccount(false)}
            >
              Delete Account
            </Button>
          </div>
        </div>
      </Main>
    </StandardModal>
  );
};

export default DeleteAccountModal;
