import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";
import Icon from "components/design-system/icons/Icon";
import GroupModalCreate from "components/group/GroupModal/GroupModalCreate";
import {
  currentPathWithSearch,
  locationFromRoute,
} from "components/routing/utils";
import { useCallback } from "react";
import { useHistory } from "react-router";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";

const AddGroupsButton = ({ workspaceID }: { workspaceID?: string }) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const history = useHistory();

  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const openCreateGroupModal = useCallback(() => {
    openModal(<GroupModalCreate workspaceID={workspaceID} />);
  }, [openModal, workspaceID]);

  const actions: ActionSheetItemGroups[] = [
    {
      items: [
        {
          icon: props => <Icon icon="Plus" {...props} />,
          onClick: () => openCreateGroupModal(),
          text: "Create group",
        },
        {
          icon: props => <Icon icon="Groups" {...props} />,
          onClick: () => {
            const { pathname, search } = locationFromRoute(
              currentPathWithSearch({ v: "directory" })
            );
            history.push({
              pathname,
              search,
              state: { workspaceID },
            });
          },
          text: "Browse groups",
        },
      ],
      name: "groups",
    },
  ];

  return (
    <Dropdown content={<DropdownActions actions={actions} />}>
      <Button
        buttonFont="subhead"
        buttonStyle="subtle"
        className="w-full"
        icon="Plus"
        iconMargin="mr-8"
        iconSize={breakpointMD ? 20 : 24}
        type="button"
      >
        Add groups
      </Button>
    </Dropdown>
  );
};

export default AddGroupsButton;
