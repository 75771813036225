import { ReactEventHandler, memo, useRef } from "react";

import {
  MessageActionsArray,
  MessageUIComponentProps,
  useChannelActionContext,
  useChatContext,
  useMessageContext,
} from "stream-chat-react";

import type { GlueDefaultStreamChatGenerics } from "@utility-types";

import { useThreadViewState } from "../../provider/ThreadViewProvider";
import { MessageActions as DefaultMessageActions } from "../MessageActions";
import { MessageInput as DefaultMessageInput } from "../MessageInput";

import {
  useDeleteMessageMutation,
  useFetchMessageMetadataQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { isMobile } from "utils/platform";
import env from "utils/processEnv";
import { MessageWithEditor } from "./components/MessageWithEditor";
import { areMessagePropsEqual } from "./utils";

const MemoizedMessage = memo(MessageWithEditor, (prev, next) =>
  areMessagePropsEqual?.(prev, next)
) as typeof MessageWithEditor;

export const Message = <T extends GlueDefaultStreamChatGenerics>({
  message: messageProps,
  ...props
}: MessageUIComponentProps<T> & {
  MessageActions?: typeof DefaultMessageActions;
  MessageInput?: typeof DefaultMessageInput;
  onReactionListClick?: ReactEventHandler;
}): JSX.Element | null => {
  const actionWrapperRef = useRef<HTMLDivElement | null>(null);
  const messageWrapperRef = useRef<HTMLDivElement | null>(null);
  const reactionSelectorRef = useRef<HTMLDivElement | null>(null);

  const { authData } = useAuthData();
  const { channel } = useChatContext<T>();
  const { dispatch } = useChannelActionContext<T>();
  const messageContext = useMessageContext<T>();
  const message = messageProps || messageContext.message;

  const { recipientRole } = useThreadViewState(({ recipientRole }) => ({
    recipientRole,
  }));

  const [deleteMessage] = useDeleteMessageMutation();
  const handleDeleteMessage = () => {
    deleteMessage({
      variables: { id: message.id },
    });
  };

  const handleDeleteEphemeralMessage = () => {
    if (!channel) return;
    channel.state.removeMessage({ id: message.id });
    dispatch({ channel, type: "copyStateFromChannelOnEvent" });
  };

  const isEphemeral = message.type === "ephemeral";
  const isGlueAI = message.user?.id === env.glueAIBotID;

  const { data: messageMetadata } = useFetchMessageMetadataQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    skip: !isGlueAI,
    variables: { id: message.id },
  });

  const isResponseToUser =
    messageMetadata?.messageMetadata?.aiResponseInfo?.toUserID ===
    authData?.me.id;

  const handleDelete = !isEphemeral
    ? props.handleDelete ?? handleDeleteMessage
    : handleDeleteEphemeralMessage;

  const getMessageActions = (): MessageActionsArray<string> => {
    if (isEphemeral) return ["delete"];

    const actions =
      props.getMessageActions?.() ?? messageContext.getMessageActions();

    if (actions.length) actions.push(...["copy", "reply", "quote"]);

    if (recipientRole === "admin" || isResponseToUser) {
      actions.push("delete");
    }

    if (isGlueAI && isMobile()) actions.push(...["info", "feedback"]);

    return actions;
  };

  return (
    <MemoizedMessage
      {...props}
      {...messageContext}
      actionWrapperRef={actionWrapperRef}
      getMessageActions={getMessageActions}
      handleDelete={handleDelete}
      message={message}
      messageWrapperRef={messageWrapperRef}
      reactionSelectorRef={reactionSelectorRef}
    />
  );
};
