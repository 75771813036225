import analytics from "analytics";
import { FeedbackProvided } from "analytics/events/feedback";

import type { EditorMessage } from "components/MessageEditor/types";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";
import { FeedbackType } from "../types";

type Props = { text: string; feedbackType?: FeedbackType; threadID?: string };

const useGlueAIFeedback = () => {
  const { composeThreadToGlue } = useComposeToGlue("support");

  const sendFeedback = ({
    text,
    feedbackType = FeedbackType.THREAD_SUMMARY,
    threadID,
  }: Props) => {
    analytics.track(FeedbackProvided, {
      feedbackType,
      score: -1,
      threadId: threadID,
    });

    const editorMessage: EditorMessage = {
      attachments: [],
      text,
    };

    composeThreadToGlue(editorMessage);
  };

  const sendGeneratedResponseFeedback = ({
    messageText,
    threadID,
  }: { messageText: string; threadID: string }) => {
    const text = `Glue AI response feedback \n\n ${threadID ? `Thread: \`${threadID}\`` : ""} \n\n \`\`\`\n${messageText || ""}\n\`\`\`\n\n Please provide feedback on how this response could be improved:`;
    sendFeedback({
      text,
      feedbackType: FeedbackType.GENERATED_RESPONSE,
      threadID,
    });
  };

  return { sendFeedback, sendGeneratedResponseFeedback };
};

export default useGlueAIFeedback;
