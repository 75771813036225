import { AlertTriangle } from "react-feather";

import { Button } from "components/design-system/Button";
import useClearStorage from "hooks/useClearStorage";
import useOnce from "hooks/useOnce";
import tw from "utils/tw";

type Props = {
  sentryEventID?: string;
};

const FallbackApp = ({ sentryEventID }: Props): JSX.Element => {
  // If we get stuck in a unrecoverable state caused by cached data, clearing cache here will increase the probability of recovering the app in a good state.
  const clearStorage = useClearStorage();
  useOnce(() => {
    clearStorage();
  });
  return (
    <div className="flex overflow-hidden fixed inset-0 justify-center items-end bg-background-overlay/25 md:items-center">
      <div
        className={tw(
          "flex flex-col p-0 w-full h-[calc(100vh_-_9px_-_env(safe-area-inset-top))] bg-background-modal rounded-t-lg", // mobile and up
          "md:max-w-[640px] md:h-auto md:rounded-lg" // desktop
        )}
      >
        <div className="modal-header px-20 pt-20 pb-10">
          <h3 className="m-0 font-semibold text-center md:text-left">
            Uh oh! Something went wrong.
          </h3>
        </div>
        <div className="modal-content flex px-20 pb-20">
          <div className="flex flex-col grow items-end">
            <div className="flex flex-col items-center md:flex-row">
              <div className="shrink-0 px-30 pt-10 text-accent-error">
                <AlertTriangle size={64} />
              </div>
              <div className="flex flex-col text-base align-middle">
                <p>
                  Glue experienced an unrecoverable error and must be reloaded
                  to continue. We've been notified and will investigate, but for
                  faster resolution please email{" "}
                  <a
                    className="text-interactive-primary"
                    href="mailto:support@gluegroups.com"
                  >
                    support@gluegroups.com
                  </a>
                  {!!sentryEventID && (
                    <>
                      {" "}
                      and provide the following ID:{" "}
                      <span className="font-mono text-text-subtle">
                        {sentryEventID}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
            <div>
              <Button
                buttonStyle="primary"
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FallbackApp;
