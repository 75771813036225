import { MouseEvent, useCallback, useEffect, useMemo, useRef } from "react";

import {
  Message,
  ThreadEdge,
  ThreadPreview,
  ThreadPreviewEdge,
  ThreadSimple,
} from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import SummarizeButton from "components/Summaries/SummarizeButton";
import Summary from "components/Summaries/Summary";
import { shouldSummarizeThread } from "components/Summaries/Util";
import useSummarizeThread from "components/Summaries/useSummarizeThread";
import PaneHeader from "components/design-system/ui/PaneHeader";
import ThreadInfoModal from "components/thread/ThreadView/components/ThreadInfoModal";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";

import ThreadActionMenu from "../ThreadActionMenu";

import FollowButton from "./FollowButton";
import ThreadInfo from "./ThreadInfo";
import ThreadMembersCount from "./ThreadMembersCount";

const desktopWidth = 480;

type Props = {
  headerType?: "simple" | "full";
  onClose?: () => void;
  replyTo:
    | {
        message: Message;
        thread: ThreadSimple | ThreadPreview;
      }
    | undefined;
  threadEdge: ThreadEdge | ThreadPreviewEdge | undefined;
};

const ThreadHeader = ({
  headerType = "full",
  onClose,
  replyTo,
  threadEdge,
}: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);

  const desktopBreakpoint = useElementBreakpoint(
    headerRef.current,
    desktopWidth
  );

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { memberEdge, previewEdge } = useMemberEdge(threadEdge);

  const recipients = useMemo(
    () => threadEdge?.node?.recipients.edges.map(e => e.node),
    [threadEdge?.node?.recipients.edges]
  );

  const onClickModal = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!threadEdge) return;
      openModal(<ThreadInfoModal threadID={threadEdge.node.id} focusSubject />);
    },
    [openModal, threadEdge]
  );

  // summarization
  const { points, setShowSummary, showSummary, summarize, summarizing } =
    useSummarizeThread(threadEdge?.node.id ?? "");

  useEffect(() => {
    setShowSummary(false);
  }, [threadEdge?.node?.id, setShowSummary]);

  const showSummarizeButton =
    memberEdge && shouldSummarizeThread(memberEdge.node);

  const summary = showSummary ? (
    <div className="mt-8 w-full">
      <Summary onClose={() => setShowSummary(false)} points={points} />
    </div>
  ) : null;

  return (
    <PaneHeader ref={headerRef} onClose={onClose} trailingContent={summary}>
      <div className="flex flex-col grow min-w-0">
        <div className="flex items-start min-h-[52px]">
          <div className="inline-flex grow min-w-0">
            <BackStackButton />
            <ThreadInfo
              headerType={headerType}
              onClick={onClickModal}
              recipients={recipients}
              replyTo={replyTo}
              subject={threadEdge?.node.subject}
            />
          </div>
          <div className="flex grow-0 shrink-0 justify-end gap-8">
            {headerType === "full" && desktopBreakpoint && !previewEdge ? (
              <ThreadMembersCount edge={memberEdge} onClick={onClickModal} />
            ) : null}

            {desktopBreakpoint && showSummarizeButton && (
              <SummarizeButton
                onSummarize={summarize}
                summarizing={summarizing}
              />
            )}

            {desktopBreakpoint && memberEdge && (
              <FollowButton threadEdge={memberEdge} />
            )}

            <ThreadActionMenu
              additionalActions={
                !desktopBreakpoint
                  ? [
                      {
                        icon: "SparkleFilled" as const,
                        text: "Summarize thread",
                        onClick: summarize,
                      },
                    ]
                  : []
              }
              threadEdge={threadEdge}
              breakpointMD={!!desktopBreakpoint}
              loading={!desktopBreakpoint && summarizing}
            />
          </div>
        </div>
      </div>
    </PaneHeader>
  );
};

export default ThreadHeader;
