import { User } from "@utility-types";
import Avatar from "components/design-system/Avatar/Avatar";

type Props = Pick<User, "avatarURL" | "name"> & {
  subtitles: string[];
  isAdmin: boolean;
  badge?: React.ReactNode;
};

export function MembersListItemDetails({
  name,
  subtitles,
  avatarURL,
  isAdmin,
  badge,
}: Props) {
  return (
    <div className="flex items-center gap-8 py-4 min-w-0">
      <Avatar
        avatarURL={avatarURL}
        name={name}
        rounded="rounded-md"
        size="medium"
      />
      <div className="flex flex-col min-w-0">
        <div className="text-subhead-bold text-text-primary">
          {name}
          {isAdmin && " (Admin)"}
        </div>
        <div className="flex gap-4 items-center">
          {subtitles.length > 0 && (
            <div className="flex min-w-0 text-footnote text-text-subtle">
              {subtitles.filter(Boolean).map((s, i, arr) => (
                <>
                  <span
                    key={s}
                    className={i < arr.length - 1 ? "truncate" : "shrink-0"}
                  >
                    {s}
                  </span>
                  {i < arr.length - 1 && <span>&nbsp;·&nbsp;</span>}
                </>
              ))}
            </div>
          )}
          {badge}
        </div>
      </div>
    </div>
  );
}
