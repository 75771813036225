import { ComponentProps, forwardRef } from "react";

import { Recipient } from "@utility-types";
import tw from "utils/tw";

import InboxAvatar from "./InboxAvatar";

type Props = {
  className?: string;
  isRead?: boolean;
  isSelected: boolean;
  onBulkClick?: (e: React.MouseEvent) => void;
  recipient?: Recipient;
  subject: string | JSX.Element;
} & Omit<ComponentProps<typeof InboxAvatar>, "onClick">;

const InboxListItem = forwardRef<HTMLDivElement, WithChildren<Props>>(
  (
    {
      bulkMode = false,
      children,
      className,
      emoji,
      isRead = true,
      isSelected,
      onBulkClick,
      recipient,
      subject,
      ...avatarProps
    }: WithChildren<Props>,
    ref
  ) => {
    const bulkModeOff = !bulkMode || bulkMode === "default";

    return (
      <div
        ref={ref}
        className={tw(
          "group/thread-item",
          "flex items-center justify-start",
          "pr-12 relative text-left select-none w-full h-44 md:h-36",
          "hover:bg-background-list-hover",
          {
            "!bg-background-list-selected text-text-primary":
              isSelected && bulkModeOff,
          },
          className
        )}
      >
        <InboxAvatar
          bulkMode={bulkMode}
          emoji={emoji}
          isRead={isRead}
          onBulkClick={onBulkClick}
          recipient={recipient}
          {...avatarProps}
        />

        <div className="flex grow items-center justify-between min-w-0 pl-8">
          <div
            className={tw(
              "text-body truncate w-full",
              isRead ? "font-normal" : "font-semibold text-text-primary"
            )}
          >
            {subject || recipient?.name}
          </div>

          {children}
        </div>
      </div>
    );
  }
);

export default InboxListItem;
