import { HTMLProps } from "react";

import { Message, isGlueFile } from "@utility-types";
import { formatDateWithTime } from "utils/formatDate";
import getRandomInt from "utils/getRandomInt";
import tw from "utils/tw";

import { Skeleton } from "components/Skeleton";

import MemoizedReactMarkdown from "./MemoizedReactMarkdown";
import QuotedAttachments from "./QuotedAttachments";

type Props = HTMLProps<HTMLDivElement> & {
  maxLines?: 2 | 3;
  message: Message;
};

const EditedLabel = (props: { updatedAt: string }): JSX.Element => (
  <div className="text-xs text-text-subtle">{` (edited ${formatDateWithTime(
    new Date(props.updatedAt)
  )})`}</div>
);

const QuotedMessage = ({
  className,
  maxLines = 2,
  message: { attachments, createdAt, text, updatedAt, user },
  ...props
}: Props): JSX.Element => (
  <div
    className={tw(
      "py-5 border-l-4 rounded px-15 text-text-subtle bg-background-ghost border-accent-highlight",
      className
    )}
    {...props}
  >
    <div className="flex items-baseline">
      <div className="min-w-0 text-base font-semibold truncate">
        {user.name || <Skeleton width={`${getRandomInt(60, 120)}px`} />}
      </div>
      <div className="shrink-0 ml-5 text-xs">
        {createdAt ? (
          formatDateWithTime(new Date(createdAt))
        ) : (
          <Skeleton width="80px" />
        )}
      </div>
    </div>
    <div
      className={tw(
        "mt-4 text-message leading-5",
        maxLines === 3 ? "max-h-60 line-clamp-3" : "max-h-40 line-clamp-2"
      )}
    >
      {text ? (
        <MemoizedReactMarkdown text={text} />
      ) : !createdAt ? (
        <>
          <Skeleton />
          <Skeleton width={`${getRandomInt(50, 100)}%`} />
        </>
      ) : null}
    </div>
    <div className="py-5">
      <QuotedAttachments quotedAttachments={attachments.filter(isGlueFile)} />
    </div>
    {updatedAt && <EditedLabel updatedAt={updatedAt} />}
  </div>
);

export default QuotedMessage;
