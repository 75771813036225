import { ComponentProps, useState } from "react";

import { Dropdown } from "components/design-system/FloatingUi";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import {
  WorkspaceModal,
  WorkspaceModalJoin,
} from "components/workspace/WorkspaceModal";
import useAuthData from "hooks/useAuthData";
import useShortcut from "hooks/useShortcut";
import useModalStore from "store/useModalStore";
import generateRandomId from "utils/generateRandomId";
import { isNativeMac } from "utils/platform";
import env from "utils/processEnv";
import tw from "utils/tw";

import DeveloperModal from "components/debug/DeveloperModal";
import Avatar from "components/design-system/Avatar/Avatar";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { EditProfileModal, PreferencesModal } from "components/user";
import useAppStateStore from "store/useAppStateStore";
import {
  AskGlueAIButton,
  ContactSupportButton,
  HelpDocsButton,
  ReleaseNotesButton,
} from "./HelpMenu";

const prefShortcut = isNativeMac() ? ["Meta", ","] : ["Control", "Meta", ","];

type Props = {
  className?: string;
  rounded?: ComponentProps<typeof Avatar>["rounded"];
  size?: ComponentProps<typeof Avatar>["size"];
};

export const ProfileMenu = ({
  className,
  rounded,
  size = "medium",
}: Props): JSX.Element => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData, signOut } = useAuthData();
  const [prefModalId] = useState(generateRandomId("preferences"));

  const { closeModal, modalIsOpen, openModal } = useModalStore(
    ({ closeModal, modalIsOpen, openModal }) => ({
      closeModal,
      modalIsOpen,
      openModal,
    })
  );

  useShortcut(prefShortcut, () => {
    if (modalIsOpen(prefModalId)) return closeModal(prefModalId);
    openModal(<PreferencesModal />, { id: prefModalId });
  });

  return (
    <Dropdown
      content={
        <DropdownActions>
          <DropdownActionButtonGroup>
            <DropdownActionButton
              icon="User"
              onClick={() => {
                openModal(<EditProfileModal />);
              }}
            >
              Edit profile
            </DropdownActionButton>
            <DropdownActionButton
              icon="Settings"
              onClick={() => {
                openModal(<PreferencesModal />, { id: prefModalId });
              }}
            >
              Preferences
            </DropdownActionButton>
            {env.glueEnv !== "production" && (
              <DropdownActionButton
                icon="Tool"
                onClick={() => {
                  openModal(<DeveloperModal />);
                }}
              >
                Developer
              </DropdownActionButton>
            )}
          </DropdownActionButtonGroup>

          <DropdownActionButtonGroup>
            {authData?.workspaces.edges.map(({ node }) => (
              <DropdownActionButton
                key={node.name}
                onClick={() => {
                  openModal(<WorkspaceModal workspaceID={node.id} />);
                }}
              >
                <Avatar
                  avatarURL={node.avatarURL}
                  name={node.name}
                  rounded="rounded-sm"
                  size={breakpointMD ? "x-small" : "small"}
                />
                {node.name}
              </DropdownActionButton>
            ))}
            <DropdownActionButton
              icon="Plus"
              onClick={() => {
                openModal(<WorkspaceModalJoin />);
              }}
            >
              Add workspace
            </DropdownActionButton>
          </DropdownActionButtonGroup>

          {!breakpointMD && (
            <DropdownActionButtonGroup>
              <HelpDocsButton />
              <ReleaseNotesButton />
              <AskGlueAIButton />
              <ContactSupportButton />
            </DropdownActionButtonGroup>
          )}

          <DropdownActionButtonGroup>
            <DropdownActionButton icon="Leave" onClick={signOut}>
              Sign out
            </DropdownActionButton>
          </DropdownActionButtonGroup>
        </DropdownActions>
      }
      disabled={!authData?.me}
      placement="bottom-end"
      disableFlip
    >
      <div
        className={tw(
          "cursor-pointer focus-visible-border focus-visible-shadow overflow-hidden",
          {
            "cursor-default": !authData?.me,
            "rounded-full": rounded,
          },
          className
        )}
        tabIndex={0}
      >
        <Avatar rounded={rounded} size={size} {...authData?.me} />
      </div>
    </Dropdown>
  );
};
