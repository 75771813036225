import { useEffect } from "react";

import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { Button } from "components/design-system/Button";
import { Icon } from "components/design-system/icons";
import useComposeToGlue from "components/threads/hooks/useComposeToGlue";
import { SlackImportStatus, useSlackImportQuery } from "generated/graphql";
import useModalStore from "store/useModalStore";
import ReviewModal from "./ReviewModal";

const ImportStatusModal = ({
  workspaceID,
  ...props
}: ModalProps & { workspaceID: string }) => {
  const { composeThreadToGlue } = useComposeToGlue("support");
  const { closeModal, openModal } = useModalStore(
    ({ closeModal, openModal }) => ({ closeModal, openModal })
  );
  const { data, stopPolling } = useSlackImportQuery({
    variables: { workspaceID },
    pollInterval: 3000,
  });

  const imported =
    (data?.slackImport?.progress?.channels.imported || 0) +
    (data?.slackImport?.progress?.messages.imported || 0) +
    (data?.slackImport?.progress?.users.imported || 0);
  const total =
    (data?.slackImport?.progress?.channels.total || 0) +
    (data?.slackImport?.progress?.messages.total || 0) +
    (data?.slackImport?.progress?.users.total || 0);

  useEffect(() => {
    if (data?.slackImport?.status !== SlackImportStatus.Running) {
      stopPolling();
      if (data?.slackImport?.status === SlackImportStatus.Completed) {
        openModal(<ReviewModal workspaceID={workspaceID} />, {
          closeModals: props.modalId ? [props.modalId] : [],
        });
      }
    }
  }, [
    data?.slackImport?.status,
    openModal,
    props.modalId,
    stopPolling,
    workspaceID,
  ]);

  return (
    <StandardModal {...props} height="half">
      <Header variant="bordered">Import status</Header>
      <Main className="flex flex-col px-32 py-16">
        <span className="text-body">
          {data?.slackImport?.status === SlackImportStatus.Error
            ? "An error was encountered during import. Please contact support."
            : "Your import is processing. Check back later, this might take a while."}
        </span>
        <div className="flex flex-col justify-center items-center grow border-1 border-border-container rounded-lg mt-10">
          <div className="flex items-center gap-8">
            <Icon icon="SlackLogo" size={32} />
            <Icon className="text-icon-secondary" icon="ArrowRight" size={16} />
            <Icon icon="GlueDrop" size={32} />
          </div>
          <span className="text-body text-text-secondary mt-16">
            {data?.slackImport?.status === SlackImportStatus.Error
              ? "Import stopped processing at"
              : "Processing"}{" "}
            {data?.slackImport?.status === SlackImportStatus.Completed
              ? total
              : imported}{" "}
            / {total} objects
          </span>
          {data?.slackImport?.status === SlackImportStatus.Error && (
            <Button
              className="mt-24"
              buttonStyle="secondary"
              type="button"
              onClick={() => composeThreadToGlue()}
            >
              Contact support
            </Button>
          )}
        </div>
      </Main>
      <Footer>
        <Button type="button" onClick={() => closeModal(props.modalId)}>
          Done
        </Button>
      </Footer>
    </StandardModal>
  );
};

export default ImportStatusModal;
