import { Users } from "react-feather";

import { GroupPreviewSimple } from "@utility-types";
import { useSnackbar } from "providers/SnackbarProvider";

const useJoinedGroupSnackBar = (
  group?: Pick<GroupPreviewSimple, "id" | "name">
) => {
  const { openSnackbar } = useSnackbar();

  const joinGroupContent = () => (
    <p className="ml-4 font-semibold">
      <span>
        <Users className="icon" size={18} />
      </span>
      Joined {group?.name} group.
    </p>
  );

  return () => openSnackbar("info", joinGroupContent);
};

export default useJoinedGroupSnackBar;
