import { ComponentProps, useCallback, useState } from "react";

import { animated, useSpring } from "@react-spring/web";
import { IconName } from "components/design-system/icons";
import { Icon as FeatherIcon } from "react-feather";

import { useNativeHaptics } from "hooks/native/useNativeHaptics";
import tw from "utils/tw";

import Button from "./Button";

const bounceScale = {
  output: [1, 0.8, 1.3, 1],
  range: [0, 0.4, 0.6, 1],
};

const animatePressed = {
  config: { clamp: true, friction: 5, mass: 0.25 },
  reset: true,
  to: { value: 0.4 },
};
const animatePopBack = {
  config: { clamp: false, friction: 15, mass: 1.2, tension: 400 },
  to: { value: 1 },
};

type IconState = {
  icon?: IconName | FeatherIcon; // TODO: remove feather support when done migrating
  iconClassName?: string;
  title: string;
};

type Props = Omit<ComponentProps<typeof Button>, "icon"> & {
  disabledState: IconState;
  enabledState: IconState;
  toggled: boolean;
};

export const ToggleButton = ({
  disabledState,
  enabledState,
  iconClassName,
  onClick,
  testId,
  toggled,
  ...props
}: Props): JSX.Element => {
  const [hover, setHover] = useState(false);
  const { lightImpactHaptic } = useNativeHaptics();

  const enabled = toggled !== hover;

  const [{ value }, animate] = useSpring({ from: { value: 0 } }, []);

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!onClick) return;

      lightImpactHaptic();

      animate.start(animatePopBack);

      onClick(e);
      setHover(false);
    },
    [animate, lightImpactHaptic, onClick]
  );

  return (
    <animated.div
      style={{
        transform: value.to(bounceScale).to((s: number) => `scale(${s})`),
      }}
    >
      <Button
        data-testid={`${testId}-${toggled ? "enabled" : "disabled"}`}
        icon={enabled ? enabledState.icon : disabledState.icon}
        iconClassName={tw(
          {
            [`${enabledState.iconClassName}`]: enabled,
            [`${disabledState.iconClassName}`]: !enabled,
          },
          iconClassName
        )}
        onClick={handleOnClick}
        onPointerDown={() => animate.start(animatePressed)}
        onPointerEnter={e => e.pointerType === "mouse" && setHover(true)}
        onPointerLeave={e => e.pointerType === "mouse" && setHover(false)}
        tooltip={(toggled ? enabledState : disabledState).title}
        {...props}
      />
    </animated.div>
  );
};

export default ToggleButton;
