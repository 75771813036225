import { ComponentType } from "react";

import { NodeViewComponentProps } from "@remirror/react";
import { MentionAtomExtension } from "remirror/extensions";

import tw from "utils/tw";

import { Styles } from "components/MessageElements";
import { RecipientTooltip } from "components/RecipientTooltip";

class GlueMentionAtomExtension extends MentionAtomExtension {
  ReactComponent: ComponentType<NodeViewComponentProps> = ({
    node,
    selected,
  }) => {
    const { id, label } = node.attrs;

    return (
      <RecipientTooltip
        className={tw("mention", Styles.mention, {
          "!bg-border-link": selected,
        })}
        id={id}
      >
        {label}
      </RecipientTooltip>
    );
  };
}

export default GlueMentionAtomExtension;
