import { memo } from "react";

import { EmojiPicker } from "../../EmojiPicker";
import { FileAttachButton } from "../../FileUploader";
import { MentionButton } from "../MentionButton";
import { ThreadActions } from "../ThreadActions";

type Props = {
  onAttachFiles: (newFiles: File[]) => void;
  readOnly: boolean;
  showThreadActions: boolean;
};

const ButtonBar = ({
  onAttachFiles,
  readOnly,
  showThreadActions,
}: Props): JSX.Element => (
  <div className="flex gap-8">
    <EmojiPicker readOnly={readOnly} />
    <MentionButton readOnly={readOnly} />
    <FileAttachButton onAttachFiles={onAttachFiles} readOnly={readOnly} />
    {showThreadActions && <ThreadActions readOnly={readOnly} />}
  </div>
);

ButtonBar.displayName = "ButtonBar";

/**
 * Memoized control button bar
 *
 * Emoji Picker Button
 *
 * File Attach Button
 *
 * Send/Save Button
 */
export default memo(
  ButtonBar,
  (prev, next) => prev.onAttachFiles === next.onAttachFiles
);
