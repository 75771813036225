import { ZapOff } from "react-feather";

const NetworkErrorSnackbar = (): JSX.Element => (
  <>
    <ZapOff className="icon" size={18} />
    <p>
      <span className="font-semibold">Glue cannot connect.</span>&nbsp;There may
      be a problem with your internet connection.
    </p>
  </>
);

export default NetworkErrorSnackbar;
