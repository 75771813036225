import { useEffect, useRef } from "react";

import { useApolloClient } from "@apollo/client";
import { readThreadEdge } from "apollo/cache/threadHelpers";
import { findIndex, first, last } from "lodash-es";

import { Node } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import breakpoints from "utils/breakpoints";

type Props = {
  autoSelect?: boolean;
  edges: { node: Node }[];
  selectID: (id?: string) => void;
  selectedID: string | undefined;
  skip?: boolean;
};

// Auto-select next item on wide breakpoint
// Items are expected to be in reverse order
const useListAutoSelect = ({
  autoSelect,
  edges,
  selectedID,
  selectID,
  skip,
}: Props) => {
  const apolloCache = useApolloClient().cache;
  const { authData } = useAuthData();
  const { topModalId } = useModalStore(({ topModalId }) => ({ topModalId }));

  const selectIDRef = useRef(selectID);
  selectIDRef.current = selectID;

  edges = edges.slice().reverse();
  const selectedIndex = findIndex(edges, e => e.node.id === selectedID);

  const edgesLenWas = useRef(edges.length);
  const selectedIDWas = useRef(selectedID);
  const selectedIndexWas = useRef(selectedIndex);

  useEffect(() => {
    if (!breakpoints().md || skip || topModalId) return;

    if (!selectedID) {
      // auto-select next thread only if previous selection
      const nextThreadEdge = edges?.[selectedIndexWas.current];
      if (nextThreadEdge) {
        selectIDRef.current(nextThreadEdge.node.id);
      } else if (autoSelect) {
        selectIDRef.current(first(edges)?.node.id);
      }
      return;
    }

    // if no edges, reset selection
    if (!edges.length && edgesLenWas.current !== 0) {
      selectIDRef.current(undefined);
      return;
    }

    if (
      edgesLenWas.current - edges.length < 2 && // pagination wasn't reset
      selectedID === selectedIDWas.current && // same id selected
      selectedIndexWas.current !== -1 && // was previously in list
      selectedIndex === -1 // no longer in list
    ) {
      const edgeID = `${selectedID}-${authData?.me.id}`;
      const edge = readThreadEdge(edgeID, apolloCache);
      if (edge && !edge.isArchived) {
        // thread wasn't removed or archived, so keep selection
        return;
      }

      const nextThreadEdge = edges?.[selectedIndexWas.current];
      if (nextThreadEdge) {
        selectIDRef.current(nextThreadEdge.node.id);
      } else {
        selectIDRef.current(last(edges)?.node.id);
      }
    }

    return () => {
      edgesLenWas.current = edges.length;
      selectedIDWas.current = selectedID;
      selectedIndexWas.current = selectedIndex;
    };
  }, [
    selectedID,
    selectedIndex,
    edges,
    topModalId,
    skip,
    autoSelect,
    authData?.me.id,
    apolloCache,
  ]);
};

export default useListAutoSelect;
