import { RecipientType } from "@utility-types";
import tw from "utils/tw";

import ExternalBadge from "./ExternalBadge";

type Props = {
  badge?: JSX.Element;
  badgeClassName?: string;
  badgeVisible?: boolean;
  className?: string;
  externalBadgeClassName?: string;
  type: RecipientType;
};

const AvatarWithBadge = ({
  badge,
  badgeClassName,
  badgeVisible = false,
  children,
  className,
  externalBadgeClassName,
  type,
}: WithChildren<Props>) => {
  return (
    <div className={tw("relative", className)}>
      {children}
      <div className={tw("absolute -bottom-5 right-5", badgeClassName)}>
        {badge ? (
          badge
        ) : (
          <ExternalBadge
            className={tw(
              "!m-0 !p-0 border-[1.5px] bg-background border-background rounded-full",
              "group-hover/item:border-accent-highlight/25 group-active/item:active:border-accent-highlight/25",
              externalBadgeClassName
            )}
            tooltipClassName="!block"
            type={type}
            visible={badgeVisible}
          />
        )}
      </div>
    </div>
  );
};

export default AvatarWithBadge;
