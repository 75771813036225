import { AlertTriangle } from "react-feather";

const ServerErrorSnackbar = (): JSX.Element => (
  <>
    <AlertTriangle className="icon" size={18} />
    <p>
      <span className="font-semibold">Server Error.</span>&nbsp;We’re sorry!
      Something went wrong, but we’re looking into it.
    </p>
  </>
);

export default ServerErrorSnackbar;
