import { memo, useMemo } from "react";

import {
  StreamLinkPreviewAttachment,
  StreamUnknownAttachment,
} from "@utility-types";
import { ExternalObjectType } from "generated/graphql";
import generateRandomId from "utils/generateRandomId";
import { matchURL } from "utils/matchURL";

import ExternalObject from "./LinkPreviews/ExternalObject";

type Props = {
  attachments: StreamUnknownAttachment[];
};

const Card = memo(UnMemoizedCard);

export default function Other({ attachments }: Props): JSX.Element {
  const withUniqueIds = useMemo(
    () =>
      attachments.map(attachment => {
        const id = generateRandomId();
        return <Card key={id} {...{ ...attachment, id }} />;
      }),
    [attachments]
  );

  return <div>{withUniqueIds}</div>;
}

function UnMemoizedCard({
  id,
  image_url,
  og_scrape_url,
  text,
  thumb_url,
  title,
  title_link,
}: StreamUnknownAttachment): JSX.Element | null {
  const image = thumb_url || image_url || "";
  const url = title_link || og_scrape_url || "";

  const externalObjectType: StreamLinkPreviewAttachment[] = [
    {
      __typename: "ExternalObject",
      audio: null,
      description: text || null,
      icon: null,
      id,
      image: matchURL(image)
        ? [{ __typename: "ExternalObjectMedia", blurHash: null, url: image }]
        : null,
      objectType: ExternalObjectType.Other,
      siteName: "",
      title: title || "",
      url,
      video: null,
    },
  ];

  return <ExternalObject externalObjects={externalObjectType} />;
}
