import { Lock, Users } from "react-feather";

import { User } from "@utility-types";
import { Button } from "components/design-system/Button";
import { useSnackbar } from "providers/SnackbarProvider";

import { Skeleton } from "components/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import useGroupMembership from "components/workspace-group/hooks/useGroupMembership";
import getRandomInt from "utils/getRandomInt";

type Props = {
  groupID: string;
};

const NotInGroup = ({ groupID }: Props): JSX.Element => {
  const { openSnackbar } = useSnackbar();
  const {
    actionPending, // basically, a waiting state between taking an action and getting a response
    cancelRequestToJoin,
    hasError,
    joinGroup,
    joinRequested,
    preview,
    requestToJoin,
  } = useGroupMembership(groupID);

  if (hasError) {
    openSnackbar("error", "Something went wrong.");
  }

  const adminData = preview
    ? preview.admin
    : ({ avatarURL: "", name: "" } as User | undefined);

  return (
    <div className="join-group" data-testid="JoinGroup">
      <div className="icons">
        <Users size="60" />
        <Lock className="-ml-16 bg-background" size="28" />
      </div>
      <span className="text-title">You're not in this group.</span>
      {preview ? (
        <div className="controls">
          {adminData && (
            <>
              <Avatar {...adminData} />
              <div className="admin">
                <span className="name">{adminData ? adminData.name : ""}</span>
                <span className="role">Group Admin</span>
              </div>
            </>
          )}

          {joinRequested ? (
            <Button
              buttonStyle="simpleSecondary"
              disabled={actionPending}
              onClick={cancelRequestToJoin}
            >
              Cancel Request
            </Button>
          ) : joinGroup ? (
            <Button
              buttonStyle="simplePrimary"
              disabled={actionPending}
              onClick={joinGroup}
            >
              Join Group
            </Button>
          ) : (
            <Button
              buttonStyle="simplePrimary"
              disabled={actionPending}
              onClick={requestToJoin}
            >
              Request to Join
            </Button>
          )}
        </div>
      ) : (
        <div className="flex gap-8 items-center">
          <Avatar loading />
          <div className="flex flex-col">
            <Skeleton width={`${getRandomInt(150, 200)}px`} />
            <Skeleton width={`${getRandomInt(100, 150)}px`} />
          </div>
          <Skeleton width={`${getRandomInt(75, 92)}px`} />
        </div>
      )}
    </div>
  );
};

export default NotInGroup;
