import { ArrowDownCircle } from "react-feather";

const UnreadMarker = (): JSX.Element => (
  <div className="flex before:block after:block justify-center items-center select-none before:w-full after:w-full before:h-2 h-30 after:h-2 text-base font-semibold text-interactive-primary after:bg-interactive-primary/25 before:bg-interactive-primary/25">
    <span className="pr-5 pl-15">New</span>
    <span className="pr-15 pl-5">
      <ArrowDownCircle size={18} strokeWidth={2} />
    </span>
  </div>
);
export default UnreadMarker;
