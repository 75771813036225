import { useApolloClient } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useAbortController } from "use-abort-controller-hook";
import { useDebouncedCallback } from "use-debounce";

import { MagicLoading } from "components/MessageEditor/components/MagicLoading";
import Avatar from "components/design-system/Avatar/Avatar";
import { EmojiSheet } from "components/design-system/EmojiSheet";
import { Form, InputButton, TextInput } from "components/design-system/Forms";
import { Icon } from "components/design-system/icons";
import {
  FetchWorkspaceOrPreviewEdgeDocument,
  JoinableBy,
  WorkspacesAndGroupsListDocument,
  useCreateGroupMutation,
  useEmojiSuggestionLazyQuery,
  useUpdateGroupMutation,
} from "generated/graphql";
import usePrevious from "hooks/usePrevious";
import useOnboardingStore from "store/useOnboardingStore";
import filterActiveQueries from "utils/filterActiveQueries";
import { formatGroupName } from "utils/group/formatGroupName";
import tw from "utils/tw";
import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

type FormValues = {
  emoji?: string;
  name: string;
};

const suggestions: Required<FormValues>[] = [
  { emoji: "🚀", name: "Marketing" },
  { emoji: "👩‍💻", name: "Development" },
  { emoji: "💰", name: "Sales" },
  { emoji: "🎨", name: "Design" },
  { emoji: "🎉", name: "Fun" },
];

const GroupSuggestion = ({ emoji, name }: Required<FormValues>) => {
  const { setValue } = useFormContext<FormValues>();
  const handleClick = () => {
    setValue("emoji", emoji, { shouldDirty: true });
    setValue("name", name, { shouldDirty: true, shouldValidate: true });
  };
  return (
    <div
      className="flex items-center h-32 pr-4 bg-background-subtle rounded-lg cursor-pointer"
      onClick={handleClick}
    >
      <Avatar emojiProps={{ emoji, size: 20 }} background="transparent" />
      <span className="text-subhead ml-2 mr-4">{name}</span>
    </div>
  );
};

const FormContent = ({ formSubmitting }: { formSubmitting: boolean }) => {
  const { currentStep, setState, groups } = useOnboardingStore(
    ({ currentStep, setState, groups }) => ({ currentStep, setState, groups })
  );
  const {
    watch,
    setValue,
    formState: { touchedFields },
  } = useFormContext<FormValues>();
  const { emoji, name } = watch();
  const prevName = usePrevious(name);

  const abortController = useAbortController();
  const [fetchEmojiSuggestion] = useEmojiSuggestionLazyQuery();
  const [emojiLoading, setEmojiLoading] = useState(false);
  const [suggestedEmoji, setSuggestedEmoji] = useState<string | undefined>();
  const firstRenderRef = useRef(true);

  const debouncedEmojiSuggestion = useDebouncedCallback(name => {
    (firstRenderRef.current || !emoji) && setEmojiLoading(true);
    fetchEmojiSuggestion({
      context: { fetchOptions: abortController },
      fetchPolicy: "no-cache",
      variables: { name },
    })
      .then(({ data }) => {
        setSuggestedEmoji(data?.emojiSuggestion.emoji ?? undefined);
        !emoji &&
          setValue("emoji", data?.emojiSuggestion.emoji || "", {
            shouldDirty: true,
            shouldTouch: false,
          });
      })
      .catch(err => {
        console.warn("Error: [emojiSuggestion] - ", err);
      })
      .finally(() => {
        setEmojiLoading(false);
      });
  }, 350);

  useEffect(() => {
    if (!firstRenderRef.current && prevName === name) return;

    firstRenderRef.current = false;

    abortController.abort();

    if (!name) {
      debouncedEmojiSuggestion.cancel();
      setEmojiLoading(false);
      setSuggestedEmoji(undefined);
      setValue("emoji", "");
      return;
    }

    debouncedEmojiSuggestion(name);
  }, [
    abortController,
    debouncedEmojiSuggestion,
    emoji,
    name,
    prevName,
    setValue,
  ]);

  const resetForm = () => {
    setValue("emoji", "", { shouldDirty: true });
    setValue("name", "", { shouldDirty: true });
  };

  return (
    <>
      <TextInput<FormValues>
        name="emoji"
        placeholder="Group Name"
        type="hidden"
        wrapperClassName="!m-0"
        disabled={formSubmitting}
      />
      <div className="flex w-full">
        <EmojiSheet
          onEmojiSelect={e => setValue("emoji", e.native ?? "❓")}
          suggestedEmoji={suggestedEmoji}
        >
          <InputButton
            className={tw(
              "flex justify-center border border-border-container rounded-lg w-40 h-40 p-12",
              { "!p-2": emoji }
            )}
            disabled={formSubmitting}
          >
            {emojiLoading ? (
              <MagicLoading />
            ) : touchedFields.emoji ? (
              emoji ? (
                <Avatar
                  emojiProps={{ emoji, size: 20 }}
                  background="transparent"
                />
              ) : (
                <Icon
                  className="text-icon-secondary"
                  icon="Reaction"
                  size={16}
                />
              )
            ) : emoji ? (
              <Avatar
                emojiProps={{ emoji, size: 20 }}
                background="transparent"
              />
            ) : (
              <Icon className="text-icon-secondary" icon="Reaction" size={16} />
            )}
          </InputButton>
        </EmojiSheet>
        <TextInput
          className="ml-12"
          config={{ required: true }}
          name="name"
          variant="line"
          placeholder="Team name..."
          wrapperClassName="w-full"
          icon={emoji || name ? "Close" : undefined}
          iconPosition="end"
          iconOnClick={resetForm}
          disabled={formSubmitting}
        />
      </div>
      {!name && (
        <div className="flex flex-wrap gap-6 w-full py-12 px-11 mt-16 border rounded-lg border-border-magic bg-background-body">
          {suggestions.map(({ emoji, name }) => (
            <GroupSuggestion key={name} emoji={emoji} name={name} />
          ))}
        </div>
      )}
      <Footer
        onClickBack={() =>
          setState({ view: "ChooseApps", currentStep: currentStep - 1 })
        }
        onClickSkip={() =>
          setState({ view: "InviteMembers", currentStep: currentStep + 1 })
        }
        formSubmitting={formSubmitting}
        submitDisabled={formSubmitting}
        requireChanges={!groups}
      />
    </>
  );
};

const CreateGroup = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { currentStep, setState, groups, workspace } = useOnboardingStore(
    ({ currentStep, setState, groups, workspace }) => ({
      currentStep,
      setState,
      groups,
      workspace,
    })
  );
  const apolloClient = useApolloClient();

  const [createGroup] = useCreateGroupMutation({
    refetchQueries: filterActiveQueries(apolloClient, [
      FetchWorkspaceOrPreviewEdgeDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  });

  const [updateGroup] = useUpdateGroupMutation({
    refetchQueries: filterActiveQueries(apolloClient, [
      FetchWorkspaceOrPreviewEdgeDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  });

  const createNewGroup = ({ name, emoji }: FormValues) => {
    setFormSubmitting(true);
    const groupName = formatGroupName({
      name: `${emoji}${name}`,
    }).nameWithEmoji;
    (groups?.[0]
      ? updateGroup({
          variables: {
            id: groups[0].id,
            input: { name: groupName },
          },
        })
      : createGroup({
          variables: {
            input: {
              name: groupName,
              workspaceID: workspace?.id,
              joinableBy: JoinableBy.Workspace,
            },
          },
        })
    )
      .then(({ data }) => {
        if (!data) return;
        setState({
          view: "InviteMembers",
          currentStep: currentStep + 1,
          groups: [
            {
              id:
                "createGroup" in data
                  ? data.createGroup.id
                  : data.updateGroup.id,
              name,
              emoji,
              type: "created",
            },
          ],
        });
      })
      .finally(() => setFormSubmitting(false));
  };

  return (
    <Form<FormValues>
      className="w-full"
      onSubmit={createNewGroup}
      {...(groups ? { useFormProps: { defaultValues: { ...groups[0] } } } : {})}
    >
      <ContentWrapper
        title="Create your first group."
        headline="Groups are great for teams, projects or topics."
      >
        <FormContent formSubmitting={formSubmitting} />
      </ContentWrapper>
    </Form>
  );
};

export default CreateGroup;
