import { useState } from "react";
import { useFormContext } from "react-hook-form";

import CopyInviteButton from "components/InviteToGlue/CopyInviteButton";
import { Form } from "components/design-system/Forms";
import EmailChipsArea from "components/design-system/Forms/EmailChipsArea";
import { Icon } from "components/design-system/icons";
import {
  MemberRole,
  useAddWorkspaceMembersMutation,
  useConnectWithUsersMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useOnboardingStore from "store/useOnboardingStore";
import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

type FormValues = {
  members: string[];
};

const InviteMembers = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { authData } = useAuthData();

  const {
    setState,
    currentStep,
    skippedJoinGroups,
    workspace,
    members: currentMembers,
  } = useOnboardingStore(
    ({ setState, currentStep, skippedJoinGroups, workspace, members }) => ({
      setState,
      currentStep,
      skippedJoinGroups,
      workspace,
      members,
    })
  );

  const FormContent = () => {
    const { watch } = useFormContext<FormValues>();
    const domain = authData?.me.addressDomains[0] ?? "company.com";
    const members = watch("members");
    return (
      <div className="flex flex-col h-full">
        <EmailChipsArea
          name="members"
          placeholder={`someone@${domain}`}
          label="Sent to"
        />
        <div className="flex mt-8">
          <Icon className="text-icon-secondary" icon="Info" size={12} />
          <span className="ml-4 text-caption text-text-subtle">
            You can paste a list of emails.
          </span>
        </div>
        {workspace && workspace?.role === MemberRole.Admin && (
          <div className="mt-32">
            <span className="text-subhead-bold text-text-secondary">
              Invite link
            </span>
            <div className="mt-4">
              <CopyInviteButton workspaceID={workspace.id} />
            </div>
          </div>
        )}
        <div className="grow" />
        <Footer
          onClickBack={() =>
            setState({
              view:
                workspace?.type === "created"
                  ? "CreateGroup"
                  : !skippedJoinGroups
                    ? "JoinGroups"
                    : "JoinWorkspace",
              currentStep: currentStep - 1,
            })
          }
          onClickSkip={() =>
            setState({ view: "Review", currentStep: currentStep + 1 })
          }
          submitDisabled={!members?.length || formSubmitting}
          requireChanges={!members?.length}
          formSubmitting={formSubmitting}
          fullHeight={false}
        />
      </div>
    );
  };

  const [addWorkspaceMembers] = useAddWorkspaceMembersMutation();
  const [connectWithUsers] = useConnectWithUsersMutation();
  const handleSubmit = ({ members }: FormValues) => {
    if (!members.length) return;
    setFormSubmitting(true);
    new Promise(() =>
      workspace?.role === MemberRole.Admin
        ? addWorkspaceMembers({
            variables: {
              id: workspace.id,
              members: members.map(m => ({
                member: m,
                role: MemberRole.Default,
              })),
            },
          })
        : connectWithUsers({ variables: { users: members } })
    ).finally(() => setFormSubmitting(false));
    setState({
      view: "Review",
      currentStep: currentStep + 1,
      members: [...(currentMembers ?? []), ...members],
    });
  };

  return (
    <Form<FormValues> className="w-full" onSubmit={handleSubmit}>
      <ContentWrapper
        title="Invite your team."
        headline="Glue is better with your coworkers."
      >
        <FormContent />
      </ContentWrapper>
    </Form>
  );
};

export default InviteMembers;
