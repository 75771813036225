import { nodeAs } from "@utility-types/graphql";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import { useFetchUserEdgeQuery } from "generated/graphql";
import useModalStore from "store/useModalStore";
import env from "utils/processEnv";
import useAuthData from "./useAuthData";

export const useComposeToGlueAI = () => {
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const { data } = useFetchUserEdgeQuery({
    fetchPolicy: "cache-first",
    skip: !authData?.me.id || !env.glueAIBotID,
    variables: {
      id: `${env.glueAIBotID}-${authData?.me.id}`,
    },
  });

  const glueAIBot = nodeAs(data?.node, ["UserEdge"])?.node;

  return () => {
    openModal(
      <ThreadComposeModal
        initialDraft={{
          recipients: glueAIBot ? [glueAIBot] : undefined,
          message: { attachments: [], text: "" },
        }}
      />
    );
  };
};
