import {
  ButtonHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import tw from "utils/tw";

import UnreadBadge from "components/Navigation/UnreadBadge";
import useResizeObserver from "hooks/useResizeObserver";
import breakpoints from "utils/breakpoints";

export type Tab<T extends string> = ButtonHTMLAttributes<HTMLButtonElement> & {
  badgeCount?: number;
  tab: T;
  tabTitle?: React.ReactNode;
  unreadMentionCount?: number;
};

type Props<T extends string> = {
  buttonClassName?: string;
  buttonSelectedClassName?: string;
  buttonUnselectedClassName?: string;
  buttonWrapperClassName?: string;
  className?: string;
  onClickTab: (tab: T) => void;
  selectedTab: T;
  tabs: Tab<T>[];
};

const Tabs = <T extends string>({
  buttonClassName = "py-4 px-16 h-28 capitalize border-b-2",
  buttonSelectedClassName = "border-accent-primary",
  buttonUnselectedClassName = "border-transparent cursor-pointer",
  buttonWrapperClassName = "flex items-center",
  className,
  onClickTab,
  selectedTab,
  tabs,
}: Props<T>): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showIndicator, setShowIndicator] = useState(false);
  const isSmallScreen = !breakpoints().md;
  const handler = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const maxScrollLeft = container.scrollWidth - container.clientWidth - 1;

    setShowIndicator(container.scrollLeft < maxScrollLeft && maxScrollLeft > 0);
  }, []);

  useResizeObserver(containerRef.current, () => {
    if (isSmallScreen) return;

    handler();
  });

  useEffect(() => {
    if (isSmallScreen) return;

    const container = scrollContainerRef.current;

    container?.addEventListener("scroll", handler);

    return () => {
      container?.removeEventListener("scroll", handler);
    };
  }, [handler, isSmallScreen]);

  return (
    <div
      ref={containerRef}
      className={tw(
        "relative",
        "after:content-[''] after:absolute after:top-0 after:right-0 after:bottom-0 after:w-32",
        "after:bg-[linear-gradient(to_left,rgb(var(--color-background-app)),transparent_90%)] after:z-1 after:opacity-0 after:pointer-events-none",
        { "after:!opacity-100": showIndicator }
      )}
    >
      <div
        ref={scrollContainerRef}
        className={tw(
          "flex flex-row max-w-full overflow-x-auto",
          { "webkit-scrollbar-hidden": isSmallScreen },
          className
        )}
      >
        {tabs.map(
          ({ badgeCount, tab, tabTitle, unreadMentionCount, ...props }) => (
            <div key={tab.toString()} className={tw(buttonWrapperClassName)}>
              <button
                {...props}
                className={tw(
                  "flex shrink-0 items-center select-none",
                  selectedTab === tab
                    ? buttonSelectedClassName
                    : buttonUnselectedClassName,
                  buttonClassName
                )}
                onClick={() => onClickTab(tab)}
              >
                <div className="flex items-center relative">
                  {tabTitle || tab}
                  {(badgeCount || 0) > 0 && (
                    <UnreadBadge
                      badge={{
                        color: unreadMentionCount ? "red" : "blue",
                        count: badgeCount || 0,
                      }}
                      className="absolute left-[100%] ml-2"
                    />
                  )}
                </div>
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Tabs;
