import { RecipientType } from "@utility-types";
import { Tooltip } from "components/design-system/FloatingUi";

import { ExternalBadge } from "components/Icons";

type Props = {
  className?: string;
  title?: string;
  tooltipClassName?: string;
  type?: RecipientType;
  visible?: boolean;
};
const Badge = ({
  className = "",
  title,
  tooltipClassName,
  type,
  visible = true,
}: Props): JSX.Element | null => {
  if (!visible) return null;

  const content = title?.length
    ? title
    : type === "User"
      ? "This user is from an external workspace."
      : type === "Group" || type === "GroupPreview"
        ? "This group contains users from an external workspace."
        : type === "Workspace" || type === "WorkspacePreview"
          ? "This is an external workspace."
          : "";

  return (
    <Tooltip className={tooltipClassName} content={content}>
      <div
        className={`${className} shrink-0 px-5 py-3`}
        data-testid="external-badge"
      >
        <ExternalBadge height={10} width={10} />
      </div>
    </Tooltip>
  );
};

export default Badge;
