import { useEffect, useRef } from "react";

import { useFormContext, useWatch } from "react-hook-form";

import { Button } from "components/design-system/Button";
import { TextInput } from "components/design-system/Forms";
import { useRouteParams } from "components/routing/utils";
import { UserEdgeStatus } from "generated/graphql";
import useComponentMounted from "hooks/useComponentMounted";
import QuickSearchResults from "./QuickSearchResults";

import useShortcut from "hooks/useShortcut";
import useSearchStore from "store/useSearchStore";
import { isNative } from "utils/platform";
import { useInstantSearch } from "../hooks";

const QuickSearchInput = ({
  floatingProps,
  isOpen,
  setIsOpen,
}: {
  floatingProps: Record<string, unknown>;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const isMounted = useComponentMounted();

  useEffect(() => {
    if (!isMounted.current) return;

    const inputElement = inputRef.current;

    const onFocus = () => setIsOpen(true);
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key !== "Escape" || !inputElement) return;
      inputElement.blur();
    };

    if (inputElement) {
      inputElement.addEventListener("focus", onFocus);
      inputElement.addEventListener("keydown", onKeyDown);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("focus", onFocus);
        inputElement.addEventListener("keydown", onKeyDown);
      }
    };
  }, [isMounted, setIsOpen]);

  const { setFocus, setValue } = useFormContext();
  const searchTerm: string = useWatch({ name: "searchTerm" });

  const { superTab } = useRouteParams();
  const prevSuperTab = useRef(superTab);

  useEffect(() => {
    if (
      prevSuperTab.current === "search" &&
      prevSuperTab.current !== superTab
    ) {
      return setValue("searchTerm", "");
    }
    prevSuperTab.current = superTab;
  }, [setValue, superTab]);

  useEffect(() => {
    setValue("searchTerm", searchTerm);
  }, [searchTerm, setValue]);

  const { search, searchResults } = useInstantSearch({ sharedState: true });

  useEffect(() => {
    if (!searchTerm?.length) return search({ match: undefined });

    const matchTerm = searchTerm.startsWith("@")
      ? searchTerm.slice(1)
      : searchTerm;

    search({
      limit: superTab === "search" ? 50 : 20,
      limitLocal: 10,
      match: matchTerm,
      matchMessages: matchTerm,
      userEdgeStatus: [
        UserEdgeStatus.Blocked,
        UserEdgeStatus.Connected,
        UserEdgeStatus.Hidden,
        UserEdgeStatus.None,
        UserEdgeStatus.Starred,
      ],
    });
  }, [search, searchTerm, superTab]);

  const { addSearchHistory } = useSearchStore(({ addSearchHistory }) => ({
    addSearchHistory,
  }));

  const handleSelectResult = () => {
    addSearchHistory(searchTerm);
    setIsOpen(false);
  };

  useShortcut(["Control", "k"], () => inputRef.current?.focus());
  useShortcut(["Control", "g"], () => inputRef.current?.focus());
  useShortcut(["Meta", "k"], () => inputRef.current?.focus());
  useShortcut(["Meta", "g"], () => inputRef.current?.focus());
  useShortcut(["Meta", "f"], () => isNative() && inputRef.current?.focus());
  useShortcut(["Meta", "t"], () => isNative() && inputRef.current?.focus());

  return (
    <>
      <div className="h-32 relative">
        <TextInput
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className="h-32 bg-background-app-base-subtle text-text-action-inverse border-none select-none"
          inputRef={inputRef}
          name="searchTerm"
          placeholder="Search for conversations, groups, or people..."
          wrapperClassName="!my-0"
        />

        {!!searchTerm?.length && (
          <Button
            buttonStyle="action-inverse"
            className="absolute top-0 right-0 !px-8 h-full"
            icon="Close"
            onClick={() => {
              setValue("searchTerm", "");
              setFocus("searchTerm");
            }}
          />
        )}
      </div>

      {isOpen ? (
        <QuickSearchResults
          floatingProps={floatingProps}
          onSelectResult={handleSelectResult}
          searchResults={searchResults}
          searchTerm={searchTerm}
          setSearchTerm={searchTerm => setValue("searchTerm", searchTerm)}
        />
      ) : null}
    </>
  );
};

export default QuickSearchInput;
