import { Button } from "components/design-system/Button";
import tw from "utils/tw";

const BackButton = ({
  disabled,
  onClick,
}: { disabled?: boolean; onClick: () => void }) => {
  return (
    <Button
      buttonType="icon"
      buttonStyle="none"
      className={tw(
        "rounded-md p-6",
        "bg-background-body border border-border-strong hover:border-border-active",
        "text-icon-secondary hover:text-icon-secondary-hover"
      )}
      icon="ArrowLeft"
      iconSize={20}
      onClick={onClick}
      type="button"
      disabled={disabled}
    />
  );
};

export default BackButton;
