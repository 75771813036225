import { FetchResult, useApolloClient } from "@apollo/client";
import { JoinApproval, WorkspacePreview, nodeAs, nodeIs } from "@utility-types";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { InformationBubble } from "components/design-system/InformationBubble";
import { Icon } from "components/design-system/icons";
import {
  ApproveJoinApprovalMutation,
  AuthConfigDocument,
  FetchJoinApprovalsDocument,
  FetchPendingJoinApprovalsDocument,
  FetchUsersDocument,
  FetchWorkspaceOrPreviewEdgeDocument,
  FetchWorkspaceOrPreviewEdgeQuery,
  JoinWorkspaceMutation,
  JoinableBy,
  MemberRole,
  NotificationsDocument,
  PersistentChatsDocument,
  WorkspacesAndGroupsListDocument,
  useApproveJoinApprovalMutation,
  useFetchDomainsQuery,
  useFetchJoinApprovalsQuery,
  useJoinWorkspaceMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { find } from "lodash-es";
import { useCallback, useState } from "react";
import useOnboardingStore from "store/useOnboardingStore";
import filterActiveQueries from "utils/filterActiveQueries";
import tw from "utils/tw";
import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";

const WorkspaceItem = ({
  avatarURL,
  name,
  admin,
  members,
  compact = false,
  isInvitation = false,
  membership = false,
  submitDisabled,
  handleJoin,
  loading = false,
}: {
  avatarURL?: string;
  name: string;
  admin: string;
  members: number;
  compact?: boolean;
  isInvitation?: boolean;
  membership?: "joined" | "added" | boolean;
  submitDisabled: boolean;
  handleJoin: () => void;
  loading?: boolean;
}) => {
  const skeletonClasses = "bg-background";
  return (
    <div
      className={tw("flex flex-row items-center w-full", {
        "flex-col text-center": !compact,
      })}
    >
      {loading ? (
        <Skeleton
          className={skeletonClasses}
          height={compact ? "36px" : "80px"}
          width={compact ? "36px" : "80px"}
        />
      ) : (
        <Avatar
          size={compact ? "large" : "x-large"}
          rounded="rounded-lg"
          name={name}
          avatarURL={avatarURL}
        />
      )}
      <div className={tw("w-full overflow-hidden", compact ? "ml-8" : "mt-8")}>
        <span className="text-subhead-bold">
          {loading ? (
            <Skeleton className={skeletonClasses} height="17px" width="130px" />
          ) : (
            name
          )}
        </span>
        <div
          className={tw("flex text-footnote text-text-subtle", {
            "justify-center": !compact,
          })}
        >
          <span className="truncate">
            {loading ? (
              <Skeleton
                className={skeletonClasses}
                height="18px"
                width="105px"
              />
            ) : (
              `Admin: ${admin}`
            )}
          </span>
          <span className="px-4">·</span>
          <span className="whitespace-nowrap">
            {loading ? (
              <Skeleton
                className={skeletonClasses}
                height="18px"
                width="65px"
              />
            ) : (
              `${members} members`
            )}
          </span>
        </div>
      </div>
      {!loading && (
        <Button
          buttonStyle={compact ? "secondary" : "primary"}
          className={tw("whitespace-nowrap", compact ? "ml-16" : "mt-16")}
          onClick={handleJoin}
          disabled={submitDisabled || !!membership}
        >
          {isInvitation
            ? "Accept invite"
            : membership === "joined"
              ? "Joined"
              : membership === "added"
                ? "Added"
                : "Join"}
        </Button>
      )}
    </div>
  );
};

const JoinWorkspace = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    setState,
    currentStep,
    workspace: userWorkspace,
    skippedJoinGroups,
  } = useOnboardingStore(
    ({ setState, currentStep, workspace, skippedJoinGroups }) => ({
      setState,
      currentStep,
      workspace,
      skippedJoinGroups,
    })
  );
  const { fetchAuthData, authData } = useAuthData();
  const apolloClient = useApolloClient();

  const { data: joinApprovalsData, loading: joinApprovalsLoading } =
    useFetchJoinApprovalsQuery({
      fetchPolicy: "cache-and-network",
      skip: !!userWorkspace,
    });
  const joinApprovals = joinApprovalsData?.joinApprovals.edges.filter(
    j => j.node.joinable.__typename === "WorkspacePreview"
  );

  const { data, loading: domainsLoading } = useFetchDomainsQuery({
    fetchPolicy: "cache-and-network",
    skip: !!userWorkspace,
  });

  const memberWorkspaces = data?.domains.edges
    .flatMap(domain => domain.node.workspaces?.edges)
    .filter(w => {
      return (
        w.__typename === "WorkspaceEdge" &&
        find(joinApprovals, {
          node: { joinable: { id: w.node.id } },
        }) === undefined
      );
    });

  const workspaces = data?.domains.edges
    .flatMap(domain => domain.node.workspaces?.edges)
    .filter(w => {
      return (
        w.__typename === "WorkspacePreviewEdge" &&
        find(joinApprovals, {
          node: { joinable: { id: w.node.id } },
        }) === undefined
      );
    });

  const multipleWorkspaces = !!(workspaces?.length && workspaces?.length > 1);

  const headline: string = (() => {
    if (userWorkspace?.type === "added") {
      return "You've been added to this workspace by your admin.";
    }
    if (userWorkspace?.type === "joined") {
      return "You've joined this workspace.";
    }
    if (joinApprovals?.length) {
      return "You've been invited to join this workspace.";
    }
    if (multipleWorkspaces) {
      return "Join any of these workspaces.";
    }
    return "You can join this workspace based on your email.";
  })();

  const [approveJoin] = useApproveJoinApprovalMutation({
    awaitRefetchQueries: true,
    errorPolicy: "all" as const,
    refetchQueries: filterActiveQueries(apolloClient, [
      FetchJoinApprovalsDocument,
      FetchPendingJoinApprovalsDocument,
      FetchUsersDocument,
      FetchWorkspaceOrPreviewEdgeDocument,
      NotificationsDocument,
      PersistentChatsDocument,
      WorkspacesAndGroupsListDocument,
    ]),
  });

  const [joinWorkspace] = useJoinWorkspaceMutation({
    errorPolicy: "all",
    refetchQueries: [AuthConfigDocument, WorkspacesAndGroupsListDocument],
  });

  const handleJoinWorkspace = useCallback(
    (workspace?: WorkspacePreview, invitation?: JoinApproval) => {
      if (!workspace) return;

      setIsSubmitting(true);

      const fireMutation = (
        promise: Promise<
          FetchResult<ApproveJoinApprovalMutation | JoinWorkspaceMutation>
        >,
        isInvitation = false
      ) => {
        promise
          .then(({ data }) => {
            if (!data) return;
            fetchAuthData({ refresh: true });

            apolloClient
              .query<FetchWorkspaceOrPreviewEdgeQuery>({
                query: FetchWorkspaceOrPreviewEdgeDocument,
                variables: { id: `${workspace?.id}-${authData?.me.id}` },
              })
              .then(({ data: workspaceData }) => {
                const edge = nodeAs(workspaceData.node, ["WorkspaceEdge"]);
                const groupsAvailable = edge?.node.groups.edges.some(
                  g => g.node.joinableBy === JoinableBy.Workspace
                );
                if (!groupsAvailable) {
                  setState({
                    view: "InviteMembers",
                    currentStep: currentStep + 1,
                    totalSteps: 3,
                    skippedJoinGroups: true,
                    workspace: {
                      ...workspace,
                      admin: workspace.admin?.name ?? "",
                      members: workspace.members.totalCount,
                      role: edge?.memberRole ?? MemberRole.Default,
                      type: isInvitation ? "invited" : "joined",
                    },
                  });
                  return;
                }
                setState({
                  view: "JoinGroups",
                  currentStep: currentStep + 1,
                  workspace: {
                    ...workspace,
                    admin: workspace.admin?.name ?? "",
                    members: workspace.members.totalCount,
                    role: edge?.memberRole ?? MemberRole.Default,
                    type: isInvitation ? "invited" : "joined",
                  },
                });
              });
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => setIsSubmitting(false));
      };

      if (invitation) {
        fireMutation(
          approveJoin({
            variables: { joinApprovalID: invitation.id },
          }),
          true
        );
        return;
      }
      fireMutation(joinWorkspace({ variables: { id: workspace.id } }));
    },
    [
      apolloClient,
      approveJoin,
      authData?.me.id,
      currentStep,
      fetchAuthData,
      joinWorkspace,
      setState,
    ]
  );

  const multipleItems = !!workspaces?.length || !!joinApprovals?.length;
  const Invitations = () =>
    joinApprovals?.map((j, i) => {
      const joinApproval = j?.node;
      const workspacePreview = nodeAs(joinApproval?.joinable, [
        "WorkspacePreview",
      ]);
      if (!joinApproval || !workspacePreview) return null;
      return (
        <div className={tw({ "mt-16": i > 0 })} key={joinApproval.id}>
          <WorkspaceItem
            admin={joinApproval.admin?.name ?? ""}
            avatarURL={workspacePreview.avatarURL ?? ""}
            handleJoin={() =>
              handleJoinWorkspace(workspacePreview, joinApproval)
            }
            submitDisabled={isSubmitting || !!userWorkspace}
            name={workspacePreview.name}
            members={workspacePreview.members.totalCount ?? 0}
            compact={multipleItems}
            loading={joinApprovalsLoading || domainsLoading}
            isInvitation
          />
        </div>
      );
    });

  const Workspaces = () => (
    <>
      {joinApprovals?.length && workspaces?.length ? (
        <span className="mt-32 text-headline text-text-secondary">
          Other workspaces you can join:
        </span>
      ) : null}
      {[...(memberWorkspaces ?? []), ...(workspaces ?? [])]?.map(
        ({ node }, i) => {
          const isMember = nodeIs(node, ["Workspace"]);
          const workspacePreview = nodeAs(node, ["WorkspacePreview"]);
          return (
            <div
              className={tw({
                "mt-16": i > 0 || (i === 0 && joinApprovals?.length),
              })}
              key={node.id}
            >
              <WorkspaceItem
                admin={node.admin?.name ?? ""}
                avatarURL={node.avatarURL ?? ""}
                handleJoin={() => handleJoinWorkspace(workspacePreview)}
                submitDisabled={isSubmitting || !!userWorkspace}
                membership={isMember ? "joined" : false}
                name={node.name}
                members={node.members.totalCount}
                compact={multipleItems}
                loading={joinApprovalsLoading || domainsLoading}
              />
            </div>
          );
        }
      )}
    </>
  );

  const isSingleItem =
    (workspaces?.length || 0) + (joinApprovals?.length || 0) === 1;
  return (
    <ContentWrapper title="Join a workspace." headline={headline}>
      <div
        className={tw("flex flex-col overflow-auto mb-16 md:mt-0", {
          "mt-16": isSingleItem,
        })}
      >
        {userWorkspace ? (
          <WorkspaceItem
            {...userWorkspace}
            avatarURL={userWorkspace.avatarURL ?? ""}
            handleJoin={() => null}
            membership={userWorkspace.type === "added" ? "added" : "joined"}
            submitDisabled
          />
        ) : joinApprovalsLoading || domainsLoading ? (
          cloneElementForSkeletons(
            <div className="mb-16">
              <WorkspaceItem
                admin={""}
                avatarURL={""}
                handleJoin={() => null}
                submitDisabled={false}
                name={""}
                members={0}
                compact
                loading
              />
            </div>,
            3
          )
        ) : (
          <>
            <Invitations />
            <Workspaces />
          </>
        )}
      </div>
      <InformationBubble
        className={tw("mt-32 md:mt-32 mb-16", {
          "mt-64": isSingleItem,
        })}
      >
        Looking for a different workspace? Sign in with another email, ask for
        an invite or{" "}
        <span
          className={tw("text-footnote", {
            "text-text-action hover:underline cursor-pointer": !userWorkspace,
          })}
          onClick={() =>
            !userWorkspace ? setState({ view: "CreateWorkspace" }) : null
          }
        >
          create a workspace
        </span>
      </InformationBubble>
      <div className="grow" />
      <Footer
        onClickBack={() =>
          setState({
            view: "CompleteYourProfile",
            currentStep: currentStep - 1,
          })
        }
        fullHeight={false}
        hideSkip
      >
        <Button
          buttonStyle={userWorkspace ? "primary" : "subtle"}
          className={tw({
            "text-text-subtle hover:text-text-subtle-hover": !userWorkspace,
          })}
          onClick={() =>
            setState({
              view: userWorkspace
                ? !skippedJoinGroups
                  ? "JoinGroups"
                  : "InviteMembers"
                : "Review",
              currentStep: currentStep + 1,
            })
          }
        >
          {userWorkspace ? "Next" : "Skip"}
          {userWorkspace && (
            <Icon className="ml-6" icon="ArrowRight" size={20} />
          )}
        </Button>
      </Footer>
    </ContentWrapper>
  );
};

export default JoinWorkspace;
