import Icon from "components/design-system/icons/Icon";
import breakpoints from "utils/breakpoints";
import tw from "utils/tw";

type Props = {
  avatarComponent: JSX.Element | null;
  avatarSize?: 20 | 24 | 32;
  className?: string;
  isBulkEditMode?: boolean;
  isSelected?: boolean;
  itemBulkMode?: "default" | "selected" | "unselected";
  showSkeleton?: boolean;
};

const ThreadItemAvatar = (props: Props) => {
  const {
    avatarSize = breakpoints().md ? 20 : 24,
    isBulkEditMode = false,
    itemBulkMode,
  } = props;

  return (
    <div
      className={tw(
        "bulk-edit-target group/select relative shrink-0",
        "pl-8 pr-4 md:px-0",
        isBulkEditMode ? "flex" : "hidden md:flex"
      )}
      style={{
        height: avatarSize,
        width: avatarSize,
      }}
    >
      <div
        className={tw(
          "flex items-center justify-center w-full h-full shrink-0",
          {
            "group-hover/select:opacity-100": itemBulkMode !== undefined,
            "group-hover/select:text-icon-subtle-hover":
              itemBulkMode === "unselected" || !isBulkEditMode,
          },
          isBulkEditMode ? "opacity-100" : "opacity-0",
          itemBulkMode === "selected"
            ? "text-icon-action group-hover/select:text-icon-action-hover"
            : "text-icon-subtle"
        )}
      >
        <Icon
          icon={
            breakpoints().md
              ? itemBulkMode === "selected"
                ? "ThreadSelected"
                : "Checkbox"
              : itemBulkMode === "selected"
                ? "IconTapSelected"
                : "IconTapUnselected"
          }
          size={avatarSize}
        />
      </div>

      <div
        className={tw(
          "absolute top-0 text-icon-subtle justify-center items-center flex",
          { "hover:opacity-0": itemBulkMode !== undefined },
          isBulkEditMode ? "opacity-0" : "opacity-100"
        )}
        style={{
          height: avatarSize,
          width: avatarSize,
        }}
      >
        {props.avatarComponent}
      </div>
    </div>
  );
};

export default ThreadItemAvatar;
