import { useCallback } from "react";

import {
  Edit2,
  ExternalLink,
  Flag,
  MicOff,
  MoreVertical,
  RefreshCcw,
  Trash2,
} from "react-feather";
import {
  MessageUIComponentProps,
  StreamMessage,
  useChatContext,
} from "stream-chat-react";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";
import { formatDateWithTime } from "utils/formatDate";

import { Timestamp } from "../Message/components";

type MessageActionsArray<T extends string = string> = (
  | "delete"
  | "edit"
  | "flag"
  | "mute"
  | "pin"
  | "react"
  | "reply"
  | T
)[];

const MESSAGE_ACTIONS = {
  copy: "copy",
  delete: "delete",
  edit: "edit",
  flag: "flag",
  mute: "mute",
  pin: "pin",
  quote: "quote",
  react: "react",
  reply: "reply",
  info: "info",
  feedback: "feedback",
};

/**
 * Tell if the owner of the current message is muted
 */
const isUserMuted = <T extends GlueDefaultStreamChatGenerics>(
  message: StreamMessage<T>,
  mutes?: T["userType"]["mutes"]
) => {
  if (!mutes || !message) return false;

  const userMuted = mutes.filter(el => el.target.id === message.user?.id);
  return !!userMuted.length;
};

export type MessageActionsProps<T extends GlueDefaultStreamChatGenerics> =
  Partial<MessageUIComponentProps<T>> & {
    actionWrapperRef?: React.RefObject<HTMLDivElement>;
    getMessageActions: () => MessageActionsArray;
    handleCopyMessageLink?: () => void;
    handleQuoteReply?: () => void;
    handleReplyInThread?: () => void;
    handleShowInfo?: () => void;
    handleSendFeedback?: () => void;
    message: StreamMessage<T>;
  };

export const MessageActions = <T extends GlueDefaultStreamChatGenerics>({
  getMessageActions,
  handleCopyMessageLink,
  handleDelete,
  handleFlag,
  handleMute,
  handleQuoteReply,
  handleReplyInThread,
  handleRetry,
  handleShowInfo,
  handleSendFeedback,
  message,
  setEditingState,
}: MessageActionsProps<T>): JSX.Element | null => {
  const { mutes } = useChatContext<T>();
  const isMuted = useCallback(
    () => isUserMuted(message, mutes),
    [message, mutes]
  );
  const messageActions = getMessageActions();
  let header: JSX.Element | undefined;

  const actions: ActionSheetItemGroups[] =
    message.status === "sending"
      ? [
          {
            items: [
              {
                onClick: () => void 0,
                text: "Sending...",
              },
            ],
            name: "sending",
          },
        ]
      : message.status === "failed"
        ? [
            {
              items: [
                {
                  icon: RefreshCcw,
                  onClick: () => handleRetry?.(message),
                  text: handleRetry ? "Retry" : "Send failed",
                },
              ],
              name: "failed",
            },
          ]
        : [
            {
              items: [
                ...(messageActions.includes(MESSAGE_ACTIONS.quote) &&
                handleQuoteReply
                  ? [
                      {
                        icon: "Quote" as const,
                        onClick: handleQuoteReply,
                        text: "Quote reply",
                      },
                    ]
                  : []),
                ...(messageActions.includes(MESSAGE_ACTIONS.reply) &&
                handleReplyInThread
                  ? [
                      {
                        icon: "Thread" as const,
                        onClick: handleReplyInThread,
                        text: "Start new thread",
                      },
                    ]
                  : []),
                ...(messageActions.includes(MESSAGE_ACTIONS.copy) &&
                handleCopyMessageLink
                  ? [
                      {
                        icon: ExternalLink,
                        onClick: handleCopyMessageLink,
                        text: "Copy share link",
                      },
                    ]
                  : []),
              ],
              name: "actions",
            },
            {
              items: [
                ...(messageActions.includes(MESSAGE_ACTIONS.info)
                  ? [
                      {
                        icon: "Info" as const,
                        onClick: handleShowInfo,
                        text: "Response details",
                      },
                    ]
                  : []),
                ...(messageActions.includes(MESSAGE_ACTIONS.feedback)
                  ? [
                      {
                        icon: "ThumbsDown" as const,
                        onClick: handleSendFeedback,
                        text: "Bad response",
                      },
                    ]
                  : []),
              ],
              name: "Glue AI actions",
            },
            {
              items: [
                ...(messageActions.includes(MESSAGE_ACTIONS.flag)
                  ? [
                      {
                        icon: Flag,
                        onClick: handleFlag,
                        text: "Flag",
                      },
                    ]
                  : []),
                ...(messageActions.includes(MESSAGE_ACTIONS.edit)
                  ? [
                      {
                        icon: Edit2,
                        onClick: setEditingState,
                        text: "Edit message",
                      },
                    ]
                  : []),
                ...(messageActions.includes(MESSAGE_ACTIONS.delete)
                  ? [
                      {
                        className:
                          "!text-interactive-alert hover:!text-interactive-alert-hover",
                        icon: Trash2,
                        iconClassName:
                          "!text-interactive-alert group-hover/action-sheet-item:!text-interactive-alert-hover",
                        onClick: handleDelete,
                        text: "Delete",
                      },
                    ]
                  : []),
                ...(messageActions.includes(MESSAGE_ACTIONS.mute)
                  ? [
                      {
                        icon: MicOff,
                        onClick: handleMute,
                        text: `${isMuted?.() ? "Unmute" : "Mute"}`,
                      },
                    ]
                  : []),
              ],
              name: "Admin actions",
            },
          ];

  if (messageActions.length === 0) return null;

  if (message.user?.name) {
    header = (
      <div className="flex flex-col justify-center py-12 px-20 border-b-thin border-background-subtle">
        <p className="m-0 font-bold">{message.user.name}</p>
        <Timestamp
          customClass="text-xs text-text-subtle"
          date={message.created_at}
          formatDate={formatDateWithTime}
        />
      </div>
    );
  }

  return (
    <span className="flex flex-1 justify-center" data-testid="message-actions">
      <span data-testid="message-actions-sheet">
        <Dropdown
          className="min-w-[200px]"
          content={<DropdownActions actions={actions} />}
          header={header}
          placement="bottom-end"
        >
          <Button
            buttonStyle="subtle"
            className="justify-center -mr-2 w-32 h-32 select-none"
            icon={MoreVertical}
            iconSize={20}
            type="button"
          />
        </Dropdown>
      </span>
    </span>
  );
};
