import { useHistory } from "react-router";

import { last } from "lodash-es";

import { ThreadEdgeSimple, UserEdge, nodeAs, nodeIs } from "@utility-types";
import { SectionItem } from "components/design-system/ui/sections-sidebar";
import {
  DMTabs,
  TabName,
  currentPathWithSearch,
  routePath,
  routeToThread,
  superTabNames,
  useRouteParams,
} from "components/routing/utils";
import {
  InboxListItem,
  InboxThreadListItem,
} from "components/views/inbox/InboxMain";
import {
  ChatType,
  ThreadsOrder,
  useFetchUserEdgeQuery,
  useFetchUsersQuery,
  usePersistentChatsQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useLocalSettingsStore from "store/useLocalSettingsStore";

import { unreadThreadEdges } from "utils/sumUnreadCount";
import tw from "utils/tw";

import InviteToGlueModal from "components/InviteToGlue/InviteToGlueModal";
import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import ComposeDMModal from "components/views/dms/ComposeDMModal";
import { useInboxSidebarSectionsState } from "components/views/inbox/providers/InboxSidebarSectionsProvider";
import useChatRecipient from "hooks/useChatRecipient";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import MailboxSection from "./MailboxSection";
import { useLoadMoreOnFirstPage } from "./hooks";

const sectionKey = "DirectMessages";
const initialItemCount = 5;
const pageSize = 9;

const DirectMessages = () => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { authData, authReady } = useAuthData();
  const { threadID, userID } = useRouteParams();
  const selectedID = threadID || userID;
  const history = useHistory();

  const { swipedOpenItemId, setState } = useInboxSidebarSectionsState(
    ({ swipedOpenItemId }) => ({
      swipedOpenItemId,
    })
  );

  const setSwipedOpenItemId = (id?: string) =>
    setState({ swipedOpenItemId: id });

  const { collapsedSidebarSections } = useLocalSettingsStore(
    ({ collapsedSidebarSections }) => ({ collapsedSidebarSections })
  );
  const collapsed = collapsedSidebarSections.includes(sectionKey);

  const queryOptions = {
    fetchPolicy: authReady
      ? ("cache-and-network" as const)
      : ("cache-only" as const),
    nextFetchPolicy: "cache-first" as const,
  };

  const { data, fetchMore } = usePersistentChatsQuery({
    ...queryOptions,
    variables: {
      chatType: ChatType.User,
      last: pageSize,
      order: ThreadsOrder.Unread,
    },
  });

  const { data: userData } = useFetchUserEdgeQuery({
    ...queryOptions,
    skip: !authData?.me.id || !userID,
    variables: { id: `${userID}-${authData?.me.id}` },
  });

  const { data: suggestedUsers } = useFetchUsersQuery({
    ...queryOptions,
    skip: !data || data.persistentChats.edges.length >= initialItemCount,
    variables: {
      filter: {
        mutualGroups: true,
        mutualWorkspaces: true,
      },
    },
  });

  const reversedChats =
    data?.persistentChats.edges
      .slice()
      .reverse()
      .filter(e => !e.isStarred) ?? [];

  const chatRecipient = useChatRecipient();
  const chatsRecipients = reversedChats.map(r => chatRecipient(r.node));

  const chats =
    reversedChats.length >= initialItemCount
      ? reversedChats
      : [
          ...reversedChats,
          ...(suggestedUsers?.users.edges
            .filter(u => !chatsRecipients.find(c => c?.id === u.node.id))
            .slice(0, initialItemCount - reversedChats.length) ?? []),
        ];

  const pendingChatEdge = nodeAs(userData?.node, ["UserEdge"]);
  const newChatEdge = !chats.find(c => c.node.id === pendingChatEdge?.node.id)
    ? pendingChatEdge
    : undefined;

  const isNonPersistentChat = newChatEdge?.persistentChatEdge === null;

  const sectionItems = (edges: (ThreadEdgeSimple | UserEdge)[]) => {
    if (collapsed) edges = edges.filter(e => e.node.id === selectedID);
    return edges.map(edge => (
      <SectionItem
        key={edge.node.id}
        canFollow={false}
        className={tw("!px-0 mx-0 group/thread-list-item", {
          "font-semibold":
            edge.__typename === "ThreadEdge"
              ? !!edge.unreadMessageCounts.total
              : false,
        })}
        flipId={`dm-${edge.node.id}`}
        onClick={e => {
          const to = e.ctrlKey || e.metaKey ? "secondary" : "primary";
          history.push(
            routeToThread({ superTab: "inbox", threadID: edge.node.id, to })
          );
        }}
        {...(edge.__typename === "ThreadEdge"
          ? {
              itemData: edge,
              swipedOpenItemId,
              setSwipedOpenItemId,
              canArchive: false,
            }
          : { setSwipedOpenItemId: undefined })}
      >
        {edge.__typename === "ThreadEdge" ? (
          <InboxThreadListItem
            canFollow={false}
            isSelected={edge.node.id === threadID}
            sectionIsOpen={!collapsed}
            threadID={edge.node.id}
          />
        ) : (
          <InboxListItem
            bulkMode={false}
            isSelected={edge.node.id === userID}
            recipient={edge.node}
            subject={""}
          />
        )}
      </SectionItem>
    ));
  };

  const loadNextPage = () =>
    fetchMore({
      variables: {
        before: data?.persistentChats.pageInfo.startCursor,
        last: pageSize, //Total of 10
      },
    });

  const lastChat = last(chats);
  const { firstPageSize } = useLoadMoreOnFirstPage({
    loadMore:
      (nodeIs(lastChat, ["ThreadEdge"])
        ? lastChat.unreadMessageCounts.total
        : 0) > 0,
    loadNextPage,
    pageSize,
  });

  // Only show chats with activity in the last week
  const lastRecentIdx = reversedChats?.findLastIndex(e => {
    const lastMessageDate = new Date(
      e.node.lastMessage?.createdAt || e.node.createdAt
    );
    const diff = new Date().getTime() - lastMessageDate.getTime();
    return diff / (1000 * 60 * 60 * 24) < 7;
  });

  // Show at least 5 chats, even if not recent
  const moreCutoffMax =
    Math.min(lastRecentIdx ?? firstPageSize, firstPageSize) + 1;
  const moreCutoff = Math.max(initialItemCount, moreCutoffMax);

  const initialChats = chats?.slice(0, moreCutoff);
  const moreChats = chats?.slice(moreCutoff);

  return (
    <MailboxSection
      flipKey={data?.persistentChats.edges.map(e => e.node.id).join()}
      hasPreviousPage={data?.persistentChats.pageInfo.hasPreviousPage}
      moreItems={sectionItems(moreChats) ?? []}
      onClickMore={loadNextPage}
      sectionItems={
        sectionItems(
          isNonPersistentChat ? [newChatEdge, ...initialChats] : initialChats
        ) ?? []
      }
      sectionKey={sectionKey}
      sectionTitle="Direct messages"
      sectionTitleDropdown={
        <DropdownActions>
          <DropdownActionButtonGroup>
            <DropdownActionButton
              icon="Compose"
              onClick={() => {
                breakpointMD
                  ? history.push(currentPathWithSearch({ v: "compose-dm" }))
                  : openModal(<ComposeDMModal />);
              }}
            >
              New direct message
            </DropdownActionButton>

            <DropdownActionButton
              icon="Plus"
              onClick={() => openModal(<InviteToGlueModal inviteToWorkspace />)}
            >
              Invite to workspace
            </DropdownActionButton>
          </DropdownActionButtonGroup>
          <DropdownActionButtonGroup>
            <DropdownActionButton
              icon="ArrowRightCircle"
              onClick={() =>
                history.push(
                  routePath({
                    superTab: superTabNames[TabName.DMs],
                    t: DMTabs.Directory,
                  })
                )
              }
            >
              See all
            </DropdownActionButton>
          </DropdownActionButtonGroup>
        </DropdownActions>
      }
      selectedItem={
        !initialChats?.find(c => c.node.id === selectedID)
          ? sectionItems(moreChats?.filter(d => d.node.id === selectedID))[0]
          : undefined
      }
      showSkeleton={!data || !authData}
      unreadCount={unreadThreadEdges(reversedChats, "total")}
      unreadMentions={unreadThreadEdges(reversedChats, "total")}
    />
  );
};

export default DirectMessages;
