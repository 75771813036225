import { Link } from "react-feather";

import glueImageURL from "utils/glueImageURL";
import { trimUrl } from "utils/trimUrl";
import tw from "utils/tw";

type InformationSummaryProps = {
  compact?: boolean;
  description?: string | null;
  icon?: { url: string } | null;
  isOther: boolean;
  title?: string;
  url?: string;
};

const InformationSummary = ({
  compact = false,
  description,
  icon,
  isOther,
  title,
  url,
}: InformationSummaryProps): JSX.Element => (
  <div
    className={`px-15 basis-[50%] grow ${
      isOther ? "py-5" : "py-10"
    } max-h-128 flex items-center min-w-0 `}
  >
    <div className="group flex flex-col py-5 w-full min-w-0 max-h-128">
      {title && (
        <div className="flex flex-row">
          {icon && (
            <img
              alt="favicon"
              className="shrink-0 mt-2 mr-8 w-14 h-14 rounded-sm"
              src={glueImageURL(icon.url)}
            />
          )}

          <div className="min-w-0 text-base font-semibold text-text-strong group-hover:underline text-ellipsis break-words line-clamp-1">
            {title}
          </div>
        </div>
      )}
      {description && (
        <div
          className={tw(
            "mt-5 max-h-48 text-sm leading-4 text-ellipsis break-words",
            compact ? "line-clamp-1" : "line-clamp-3"
          )}
        >
          {description}
        </div>
      )}
      {url && (
        <div className="flex items-center mt-5 text-interactive-subtle">
          <Link className="shrink-0 mt-1" size="12" />
          <div className="ml-6 min-w-0 text-sm leading-4 truncate">
            {trimUrl(url)}
          </div>
        </div>
      )}
    </div>
  </div>
);

export default InformationSummary;
