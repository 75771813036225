import { SendMessageHelperText } from "components/MessageEditor/components/SendMessageHelperText";
import { SecondaryBar } from "components/MessageEditor/components/controls";
import { TWBorder } from "components/design-system/types/border";
import {
  VariantPropsOf,
  variantProps,
} from "components/helper/classNameVariants";
import { ComponentProps } from "react";
import tw from "utils/tw";

type Props = {
  border?: TWBorder;
  hideEditor?: boolean;
  safeAreaPadding?: boolean;
  secondaryBarButtons?: ComponentProps<typeof SecondaryBar>["buttons"];
  showHelperText?: boolean;
};

const borderBase =
  "border drag:border-dashed rounded bg-background-body border-border-container";

const exteriorClassNames = {
  base: "flex flex-col min-h-0 relative w-full",
  defaultVariants: {
    border: "none" as const,
    padding: "none" as const,
    variant: "default" as const,
  },
  variants: {
    variant: {
      default: "",
      "edit-message": "max-h-[80%] pt-12 pb-20 pl-[38px]",
      "no-persistent-chat":
        "max-h-[80%] md:min-h-[122px] md:pb-16 pb-0 px-0 md:px-16",
      "thread-compose": "grow",
      "thread-compose-border-none": "grow",
      "thread-reply":
        "max-h-[80%] md:min-h-[122px] md:pb-16 pb-0 px-0 md:px-16",
    },
  },
};

const interiorClassNames = {
  base: "flex flex-col grow min-h-0",
  defaultVariants: {
    variant: "default" as const,
  },
  variants: {
    variant: {
      default: "",
      "edit-message": tw(borderBase),
      "no-persistent-chat": tw(
        borderBase,
        "native:border-thin native:border-x-none native:border-b-none native:rounded-none"
      ),
      "thread-compose":
        "border-b-1 drag:border-dashed border-border-subtle pt-16 px-6 md:pt-10 md:px-10 native:pt-0 native:px-0 overflow-y-auto",
      "thread-compose-border-none":
        "border-none drag:border-dashed border-border-subtle pt-16 px-6 md:pt-10 md:px-10 native:pt-0 native:px-0 overflow-y-auto",
      "thread-reply": tw(
        borderBase,
        "border-thin border-x-none border-b-none rounded-none px-0 md:px-10 md:border md:rounded pt-6"
      ),
    },
  },
};

const exteriorProps = variantProps(exteriorClassNames);
const internalProps = variantProps(interiorClassNames);

type VariantProps = VariantPropsOf<typeof exteriorProps>;

const MessageEditorContainer = ({
  children,
  hideEditor,
  safeAreaPadding,
  secondaryBarButtons,
  showHelperText = true,
  variant,
}: WithChildren<Props & VariantProps>) => {
  return (
    <div
      {...exteriorProps({
        className: tw("", {
          hidden: hideEditor,
          "native:!pb-safe-area": safeAreaPadding,
        }),
        variant,
      })}
      data-testid="message-editor-container-outer"
    >
      <div
        {...internalProps({
          variant,
        })}
        data-testid="message-editor-container-inner"
      >
        {children}
      </div>

      {showHelperText && <SendMessageHelperText className="!pt-8 !pr-0" />}

      {secondaryBarButtons && <SecondaryBar buttons={secondaryBarButtons} />}
    </div>
  );
};

export default MessageEditorContainer;
