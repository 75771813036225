import {
  GoBack,
  GoForward,
  HistoryButton,
} from "components/App/AppLayoutDesktop/AppHeader";
import { HelpMenu } from "components/SideBar/HelpMenu";
import { ProfileMenu } from "components/SideBar/ProfileMenu";
import QuickSearch from "components/views/search/QuickSearch";
import UpgradeButton from "components/workspace/WorkspaceModal/billing/UpgradeButton";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import { useRef } from "react";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";

const AppHeader = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const isWideView = useElementBreakpoint(headerRef.current, "wide");
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  return (
    <div id="app-header" ref={headerRef}>
      <div
        className={tw(
          "h-48 w-full",
          "flex items-center justify-between pl-[80px] select-none" // 80px is the width of the rail, and allows space for macOS window UI in the app
        )}
      >
        {/**
         * whatever adjustments are made to the content or width of adjacent DIV elements,
         * this DIV should remain centered, relative to the viewport
         */}
        <div
          className="flex grow min-w-0 items-center justify-center"
          data-testid="app-header-left"
        >
          <GoBack />
          <GoForward />
          <HistoryButton />
          <QuickSearch />
        </div>

        <div
          className={tw(
            "shrink-0",
            "flex items-center justify-end px-16 min-w-[120px] gap-10"
          )}
          data-testid="app-header-right"
        >
          <UpgradeButton isWideView={!!isWideView} />
          {breakpointMD && <HelpMenu />}
          <ProfileMenu className="!rounded-md shrink-0" rounded={false} />
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
