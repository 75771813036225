import { useState } from "react";

import { ChevronUp } from "react-feather";

import { Message, nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import MessageAvatar from "components/design-system/ui/MessageAvatar";
import { Author } from "components/thread/ThreadView/stream-components/Message/components/Author";
import { useFetchReplyToMessageQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import tw from "utils/tw";

import { ReplyToMessageContent } from "./ReplyToMessageContent";

type Props = {
  message: Pick<Message, "id" | "threadID"> | Message;
};

const ReplyToMessage = ({ message: { id, threadID } }: Props) => {
  const { authData } = useAuthData();
  const [collapsed, setCollapsed] = useState(true);

  const { data } = useFetchReplyToMessageQuery({
    fetchPolicy: "cache-first",
    skip: !id || !threadID,
    variables: {
      messageID: id || "",
      threadID: threadID || "",
    },
  });

  const message = nodeAs(data?.message, ["Message"]);
  const thread = nodeAs(data?.thread, ["Thread"]);

  const isMyMessage = authData?.me.id === message?.user.id;

  const toggleCollapsed = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setCollapsed(prevCollapsed => !prevCollapsed);
  };

  return (
    <div
      className="relative mx-10 mt-10 flex flex-col shrink-0"
      data-testid="inline-message-preview"
    >
      <article className="relative flex grow min-w-0 pb-8">
        <div className="flex flex-col shrink-0 pr-12 w-48">
          <MessageAvatar
            clickable={false}
            image={message?.user.avatarURL ?? undefined}
            isMyMessage={isMyMessage}
            name={message?.user.name}
            size="small"
            userID={message?.user.id}
          />
        </div>
        <div className="relative w-full min-w-0">
          <Author
            className="!pr-0"
            clickable={false}
            createdAt={message?.createdAt}
            isMyMessage={isMyMessage}
            messageActionMenu={null}
            messageID={message?.id}
            messageThread={thread}
            name={message?.user.name}
            onUserClick={() => null}
            userID={message?.user.id}
          />
          <ReplyToMessageContent collapsed={collapsed} message={message} />
        </div>
      </article>

      <Button
        buttonStyle="simplePrimary"
        className="h-36 !pl-0 ml-44"
        data-testid="collapseButton"
        icon={ChevronUp}
        iconClassName={tw("text-interactive-primary duration-350 ease-spring", {
          "rotate-180": collapsed,
        })}
        onClick={toggleCollapsed}
      >
        {collapsed ? "More" : "Less"}
      </Button>
    </div>
  );
};

export default ReplyToMessage;
