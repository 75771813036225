import { devtools, persist } from "zustand/middleware";

import { Recipient } from "@utility-types";
import { MemberRole } from "generated/graphql";
import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "OnboardingStore";

type User = Pick<Recipient, "avatarURL" | "name">;

type Views =
  | "CompleteYourProfile"
  | "JoinWorkspace"
  | "CreateWorkspace"
  | "JoinGroups"
  | "InviteMembers"
  | "ChooseApps"
  | "CreateGroup"
  | "Review";

type Workspace = {
  id: string;
  admin: string;
  avatarURL: string | null;
  joinable?: boolean;
  name: string;
  members: number;
  role: MemberRole;
  type: "invited" | "joined" | "created" | "added";
};

type Group = {
  id: string;
  emoji?: string;
  name: string;
  type: "joined" | "created";
};

type OnboardingStore = {
  authenticated?: boolean;
  apps?: { id: string; avatarURL: string }[];
  currentStep: number;
  totalSteps: number;
  groups?: Group[];
  hasWorkspacesToJoin?: boolean;
  members?: string[];
  onboarding?: boolean;
  reset: () => void;
  setState: (values: Partial<OnboardingStore>) => void;
  skippedJoinGroups?: boolean;
  skippedWorkspaces?: boolean;
  user?: User;
  view: Views;
  workspace?: Workspace;
};

const initialState: Omit<OnboardingStore, "setState" | "reset"> = {
  authenticated: undefined,
  view: "CompleteYourProfile",
  totalSteps: 5,
  currentStep: 1,
  user: undefined,
  hasWorkspacesToJoin: undefined,
  skippedJoinGroups: undefined,
  skippedWorkspaces: undefined,
  workspace: undefined,
  groups: undefined,
  apps: undefined,
  members: undefined,
};

const useOnboardingStore = glueCreateZustand<OnboardingStore>({
  name: STORE_NAME,
})(
  devtools(
    persist(
      set => ({
        setState: values => set(state => ({ ...state, ...values })),
        reset: () => set(() => ({ ...initialState, onboarding: false })),
        ...initialState,
      }),
      { name: STORE_NAME }
    ),
    { name: STORE_NAME, serialize: true }
  )
);

export default useOnboardingStore;
