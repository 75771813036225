import { useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { isEqual, uniq, uniqBy } from "lodash-es";

import { Recipient, nodeAs } from "@utility-types";
import {
  FetchWorkspaceProfileDocument,
  FetchWorkspaceProfileQuery,
} from "generated/graphql";
import useComponentMounted from "hooks/useComponentMounted";
import useValidateRecipientOrigin from "hooks/useValidateRecipientOrigin";

export type Workspace = { domain?: string; name?: string };

export function useGetExternalBadgeData(recipients: Recipient | Recipient[]) {
  const [workspaces, setWorkspaces] = useState<Workspace[]>();

  const apolloClient = useApolloClient();
  const isMounted = useComponentMounted();

  const { containsExternalRecipients } = useValidateRecipientOrigin();
  const { externalRecipients } = containsExternalRecipients(
    [recipients].flat(),
    true
  );

  useEffect(() => {
    if (!externalRecipients?.length) return;

    (async () => {
      let newWorkspaces = await Promise.all(
        externalRecipients.map(async r => {
          const workspaceID = r.workspaceID || r.workspaceIDs?.[0];
          if (!workspaceID) {
            return { domain: r.addressDomains?.[0] };
          }
          const result = await apolloClient.query<FetchWorkspaceProfileQuery>({
            query: FetchWorkspaceProfileDocument,
            variables: { id: workspaceID },
          });

          const workspace = nodeAs(result.data?.node, ["WorkspacePreview"]);
          return {
            domain: workspace?.domains[0],
            name: workspace?.name,
          };
        })
      );

      if (!isMounted.current) return;

      newWorkspaces = uniqBy(
        newWorkspaces.filter(w => !!(w.domain || w.name)),
        "domain"
      );

      if (!isEqual(workspaces, newWorkspaces)) {
        setWorkspaces(newWorkspaces);
      }
    })();
  }, [apolloClient, externalRecipients, isMounted, workspaces]);

  if (!externalRecipients?.length) {
    return {
      label: undefined,
      tooltip: undefined,
    };
  }

  let label = "";
  let tooltip = "";

  const formatWorkspace = ({ domain, name }: Workspace) =>
    `${name || domain}${
      name && domain && name !== domain ? ` (${domain})` : ""
    }`;

  const fromPrefix = "length" in recipients ? "Contains users from " : "From ";
  if (workspaces?.[0] && workspaces?.length === 1) {
    const workspaceName = formatWorkspace(workspaces[0]);
    label = workspaces[0].name || "External";
    tooltip = workspaceName ? `${fromPrefix} ${workspaceName}` : "";
  } else {
    label = "External";
    tooltip =
      uniq(workspaces?.map(formatWorkspace))
        .filter(name => name !== "")
        .join(", ") || `${fromPrefix} outside your workspace.`;
  }
  return { label, tooltip };
}
