import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { find, uniqBy } from "lodash-es";
import { X } from "react-feather";
import { SubmitHandler, useFormContext, useWatch } from "react-hook-form";
import { useAbortController } from "use-abort-controller-hook";
import { useDebouncedCallback } from "use-debounce";

import type { Group, Recipient } from "@utility-types";
import { MagicLoading } from "components/MessageEditor/components/MagicLoading";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import type { RecipientValue } from "components/RecipientsSelect/types";
import { EmojiSheet } from "components/design-system/EmojiSheet";
import {
  Form,
  InputButton,
  Select,
  SubmitButton,
  TextArea,
  TextInput,
  Toggle,
  useDisableSubmit,
} from "components/design-system/Forms";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import {
  EmojiSuggestionDocument,
  EmojiSuggestionQuery,
  JoinableBy,
  MemberRole as MemberRoleValue,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import usePrevious from "hooks/usePrevious";
import useModalStore from "store/useModalStore";
import { formatNameEmoji } from "utils/formatNameEmoji";

import { useApolloClient } from "@apollo/client";
import { EmojiPlus } from "components/Icons";
import { ConfirmationModal, StandardModal } from "components/Modals";
import { Icon } from "components/design-system/icons";

type MemberRole = { member: string; role: MemberRoleValue };

type GroupInput = {
  description: string;
  joinableBy: JoinableBy;
  members: MemberRole[];
  name: string;
  workspaceID: string | null;
};

type Props = {
  disabled?: boolean;
  editMode?: boolean;
  group?: Group;
  groupWorkspaceID?: string;
  onDelete?: () => Promise<void>;
  onLeave?: () => Promise<void>;
  onSave: (id: string, input: GroupInput) => Promise<void>;
  title?: string;
  isAdmin: boolean;
  focusOnDescriptionField?: boolean;
};

type FormData = {
  admins: RecipientValue[];
  description: string;
  emoji: string;
  joinableBy: boolean;
  members: RecipientValue[];
  name: string;
  workspaceID: string;
};

type GroupModalFormContentProps = {
  allowDeletion: boolean;
  allowLeaving: boolean;
  editMode?: boolean;
  groupName?: string;
  groupWorkspaceID?: string;
  handleDeleteGroup: () => void;
  handleLeaveGroup: () => void;
  onSubmit: SubmitHandler<FormData>;
  title?: string;
  isAdmin: boolean;
} & ModalProps;

export type FormRef = {
  submitDisabled: boolean;
  submitForm: () => void;
};

const GroupModalFields = ({
  editMode,
  groupWorkspaceID,
  isAdmin,
}: Partial<Props>) => {
  const { authData } = useAuthData();
  const {
    formState: { touchedFields },
    setValue,
  } = useFormContext<FormData>();
  const [disabledWorkspace, setDisabledWorkspace] = useState(false);

  useEffect(() => {
    if (groupWorkspaceID) {
      setDisabledWorkspace(
        !authData?.me.workspaceIDs.includes(groupWorkspaceID)
      );
    }
  }, [authData?.me, groupWorkspaceID]);

  const emoji = useWatch({ name: "emoji" });
  const name = useWatch({ name: "name" });
  const prevName = usePrevious(name);
  const workspaceID = useWatch({ name: "workspaceID" });

  const apolloClient = useApolloClient();
  const abortController = useAbortController();
  const [hover, setHover] = useState(false);
  const [emojiLoading, setEmojiLoading] = useState(false);
  const [suggestedEmoji, setSuggestedEmoji] = useState<string | undefined>();
  const firstRenderRef = useRef(true);

  const debouncedEmojiSuggestion = useDebouncedCallback(name => {
    (firstRenderRef.current || !emoji) && setEmojiLoading(true);
    apolloClient
      .query<EmojiSuggestionQuery>({
        query: EmojiSuggestionDocument,
        fetchPolicy: "no-cache",
        context: { fetchOptions: abortController },
        variables: { name },
      })
      .then(({ data }) => {
        setSuggestedEmoji(data?.emojiSuggestion.emoji ?? undefined);
        !emoji &&
          setValue("emoji", data?.emojiSuggestion.emoji || "", {
            shouldDirty: true,
            shouldTouch: false,
          });
      })
      .catch(err => {
        console.warn("Error: [emojiSuggestion] - ", err);
      })
      .finally(() => {
        setEmojiLoading(false);
      });
  }, 350);

  useEffect(() => {
    if (!firstRenderRef.current && prevName === name) return;

    firstRenderRef.current = false;

    abortController.abort();

    if (!name) {
      debouncedEmojiSuggestion.cancel();
      setEmojiLoading(false);
      setSuggestedEmoji(undefined);
      setValue("emoji", "");
      return;
    }

    if (emoji) return;

    debouncedEmojiSuggestion(name);
  }, [
    abortController,
    debouncedEmojiSuggestion,
    emoji,
    name,
    prevName,
    setValue,
  ]);

  const groupWorkspaceOptions = [
    ...(authData?.workspaces.edges.map(e => ({
      label: e.node.name,
      value: e.node.id,
    })) || []),
  ];

  const selectedWorkspace = find(
    groupWorkspaceOptions,
    workspace => workspace.value === workspaceID
  );

  const handleHover = (hover: boolean) => {
    if (!emojiLoading) {
      setHover(false);
      return;
    }
    setHover(hover);
  };

  return (
    <>
      <TextInput<FormData>
        name="emoji"
        placeholder="Group Name"
        type="hidden"
        wrapperClassName="!m-0"
      />
      <div className="flex items-start">
        <div className="hover:text-interactive-subtle-hover flex justify-center items-center mt-20 mr-16 text-interactive-subtle rounded border border-background-subtle focus:outline-none">
          <EmojiSheet
            onEmojiSelect={t => {
              setValue("emoji", t.native || "", {
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
            suggestedEmoji={suggestedEmoji}
          >
            <InputButton
              className="flex justify-center items-center px-10 h-[38px] w-[40px] text-2xl"
              onBlur={() => handleHover(false)}
              onPointerEnter={() => handleHover(true)}
              onPointerLeave={() => handleHover(false)}
            >
              {hover ? (
                <Icon icon={EmojiPlus} size={22} />
              ) : emojiLoading ? (
                <MagicLoading />
              ) : touchedFields.emoji ? (
                emoji || <Icon icon={EmojiPlus} size={22} />
              ) : (
                emoji || <Icon icon={EmojiPlus} size={22} />
              )}
            </InputButton>
          </EmojiSheet>
          {emoji && (
            <InputButton
              className="hover:text-interactive-subtle-hover flex justify-center items-center pr-8 w-[28px] h-[38px] text-interactive-subtle"
              onClick={() => setValue("emoji", "", { shouldDirty: true })}
            >
              <X size={20} />
            </InputButton>
          )}
        </div>

        <TextInput<FormData>
          className="font-semibold"
          config={{ required: true }}
          name="name"
          placeholder="Group Name"
          wrapperClassName="grow !mb-0"
        />
      </div>
      <TextArea<FormData>
        name="description"
        placeholder="Description (optional)"
      />
      {!editMode && (
        <RecipientsSelect
          label="Members"
          name="members"
          placeholder="Users or emails ..."
          selectGroups={false}
        />
      )}
      {!editMode && (
        <RecipientsSelect label="Admins" name="admins" selectGroups={false} />
      )}

      {isAdmin && (disabledWorkspace || groupWorkspaceOptions.length > 0) && (
        <div
          className="flex flex-col md:flex-row md:justify-between md:items-end"
          data-testid="workspace-select"
        >
          <Select<FormData>
            disabled={disabledWorkspace}
            label="Workspace"
            name="workspaceID"
            options={groupWorkspaceOptions}
            wrapperClassName="max-w-[35%] mt-0"
          />
          <Toggle<FormData>
            disabled={disabledWorkspace}
            label={`Allow anyone in ${
              selectedWorkspace?.label || "workspace"
            } to join?`}
            labelClassName="text-md"
            name="joinableBy"
            wrapperClassName="shrink-0 max-w-[60%] mt-0"
          />
        </div>
      )}
    </>
  );
};

const memberRoles = (
  recipients: Recipient[],
  role = MemberRoleValue.Default
): MemberRole[] =>
  uniqBy(
    recipients.map(r => ({ member: r.id, role })),
    "member"
  );

const GroupModalFormContent = forwardRef<FormRef, GroupModalFormContentProps>(
  (
    {
      allowDeletion,
      allowLeaving,
      editMode,
      groupName,
      groupWorkspaceID,
      handleDeleteGroup,
      handleLeaveGroup,
      onSubmit,
      title,
      isAdmin,
      ...props
    }: GroupModalFormContentProps,
    ref
  ) => {
    const { handleSubmit } = useFormContext<FormData>();

    const submitForm = useCallback(() => {
      handleSubmit(onSubmit)();
    }, [handleSubmit, onSubmit]);

    const disabled = useDisableSubmit();

    useImperativeHandle(
      ref,
      () => ({
        submitDisabled: disabled,
        submitForm,
      }),
      [disabled, submitForm]
    );

    const SaveButton = useMemo(
      () => <SubmitButton>{editMode ? "Save" : "Create"}</SubmitButton>,
      [editMode]
    );

    const CommonFormFields = (
      <>
        <GroupModalFields
          editMode={editMode}
          groupWorkspaceID={groupWorkspaceID}
          isAdmin={isAdmin}
        />

        <div className="flex items-center mb-16">
          <div className="flex items-center">
            {allowDeletion && (
              <InputButton
                buttonStyle="simpleDestructive"
                buttonType="text"
                className="mr-16"
                icon="Trash"
                onClick={handleDeleteGroup}
                type="button"
              >
                Delete Group
              </InputButton>
            )}
            {allowLeaving && (
              <InputButton
                buttonStyle="simpleDestructive"
                buttonType="text"
                icon="Leave"
                onClick={handleLeaveGroup}
                type="button"
              >
                Leave Group
              </InputButton>
            )}
          </div>
          <div className="hidden ml-auto md:flex">{editMode && SaveButton}</div>
        </div>
      </>
    );

    return editMode ? (
      <div className="flex flex-col h-full">{CommonFormFields}</div>
    ) : (
      <StandardModal
        contentHandlesSafeArea={false}
        header={
          <Header
            mobileCtaLabel={!groupName ? "Save" : "Create"}
            mobileCtaProps={{ disabled, type: "submit" }}
            variant="bordered"
          >
            <h3 className="m-0">{title}</h3>
          </Header>
        }
        {...props}
      >
        <Main className="px-16 md:px-32">{CommonFormFields}</Main>

        <Footer className="hidden md:flex">{SaveButton}</Footer>
      </StandardModal>
    );
  }
);

const GroupModalForm = forwardRef<FormRef, Props & ModalProps>(
  (
    {
      editMode,
      group,
      groupWorkspaceID,
      onDelete,
      onLeave,
      onSave,
      title,
      isAdmin,
      focusOnDescriptionField,
      ...props
    }: Props & ModalProps,
    ref
  ) => {
    const { authData } = useAuthData();

    const { openModal } = useModalStore(({ openModal }) => ({
      openModal,
    }));

    const hasMultipleAdmins =
      (group?.members?.edges.filter(e => e.memberRole === "admin").length ??
        0) > 1;

    const handleDeleteGroup = useCallback(() => {
      if (!onDelete || !group) return;

      openModal(
        <ConfirmationModal
          confirmLabel="Delete"
          header={`Delete "${group.name}" group?`}
          message="All group users will lose access to threads sent to the group."
          onConfirm={onDelete}
          isDestructive
        />
      );
    }, [group, onDelete, openModal]);

    const handleLeaveGroup = useCallback(() => {
      if (!onLeave || !group) return;

      openModal(
        <ConfirmationModal
          confirmLabel="Leave"
          header={`Leave "${group.name}" group?`}
          message="You'll lose access to threads sent to the group."
          onConfirm={onLeave}
          isDestructive
        />
      );
    }, [group, onLeave, openModal]);

    const onSubmit = useCallback(
      (data: FormData) => {
        if (!data.workspaceID) return;

        const { description, emoji, joinableBy, name, workspaceID } = data;

        const members = [
          ...memberRoles(data.admins, MemberRoleValue.Admin),
          ...memberRoles(data.members, MemberRoleValue.Default),
        ];

        return onSave(group?.id || "", {
          description,
          joinableBy: joinableBy ? JoinableBy.Workspace : JoinableBy.Approval,
          members,
          name: formatNameEmoji({ name: `${emoji}${name}` }).nameWithEmoji,
          workspaceID,
        });
      },
      [group?.id, onSave]
    );

    const allowDeletion = isAdmin && !!group?.id && !!onDelete;
    const allowLeaving =
      (!isAdmin || hasMultipleAdmins) && !!group?.id && !!onLeave;

    const parsedName = formatNameEmoji({ name: group?.name });

    return (
      <Form
        className="h-full"
        onSubmit={onSubmit}
        resetDefault={false}
        useFormProps={{
          defaultValues: {
            admins:
              group?.members?.edges
                .filter(
                  ({ memberRole }) => memberRole === MemberRoleValue.Admin
                )
                .map(e => e.node) || (authData ? [authData.me] : []),
            description: group?.description ?? "",
            emoji: parsedName.emoji || "",
            joinableBy: group?.joinableBy !== JoinableBy.Approval,
            members:
              group?.members?.edges
                .filter(
                  ({ memberRole }) => memberRole !== MemberRoleValue.Admin
                )
                .map(e => e.node) || [],
            name: parsedName.name || "",
            workspaceID: groupWorkspaceID,
          },
        }}
        onSubmitReset
        initialFocusOnField={
          focusOnDescriptionField ? "description" : undefined
        }
      >
        <GroupModalFormContent
          ref={ref}
          allowDeletion={allowDeletion}
          allowLeaving={allowLeaving}
          editMode={editMode}
          groupWorkspaceID={groupWorkspaceID}
          handleDeleteGroup={handleDeleteGroup}
          handleLeaveGroup={handleLeaveGroup}
          onSubmit={onSubmit}
          title={title}
          isAdmin={isAdmin}
          {...props}
        />
      </Form>
    );
  }
);

export default GroupModalForm;
