type AttachmentErrorProps = {
  message: string;
};

const AttachmentError = ({ message }: AttachmentErrorProps): JSX.Element => (
  <div className="mt-10 bg-background-ghost rounded-md border border-background-subtle">
    <div className="py-10 px-15 text-text-subtle">
      <i>{message}</i>
    </div>
  </div>
);

export default AttachmentError;
