import { ThreadEdge, nodeAs } from "@utility-types";
import { Skeleton } from "components/Skeleton";
import { Button } from "components/design-system/Button";
import FacePile from "components/design-system/FacePile";
import { Icon } from "components/design-system/icons";
import RecipientsLineSimple from "components/design-system/ui/RecipientsLineSimple";
import { routeToThread } from "components/routing/utils";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { isSubscribed } from "components/threads-list/ThreadItem/utils";
import ThreadComposeModal from "components/threads/ThreadComposeModal";
import { useFetchThreadEdgeQuery } from "generated/graphql";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useAuthData from "hooks/useAuthData";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useModalStore from "store/useModalStore";
import breakpoints from "utils/breakpoints";
import { formatRelativeTime } from "utils/formatDate";
import generateRandomId from "utils/generateRandomId";
import getRandomInt from "utils/getRandomInt";
import getRecipients from "utils/thread/getRecipients";
import { truncateMarkdown } from "utils/thread/truncateMarkdown";
import tw from "utils/tw";
import { renderText } from "../utils";

const truncateLength = 300;

type ThreadAttachmentProps = {
  threadID: string;
  isReply?: boolean;
};

export function ThreadAttachment({ threadID, isReply }: ThreadAttachmentProps) {
  const { authData, authReady } = useAuthData();
  const history = useHistory();

  const { recipientID } = useThreadViewState(({ recipientID }) => ({
    recipientID,
  }));

  const { data, error, loading } = useFetchThreadEdgeQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id,
    variables: {
      id: `${threadID}-${authData?.me.id}`,
    },
  });

  const threadEdge = nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"]);
  const memberEdge = nodeAs(threadEdge, ["ThreadEdge"]);

  const recipients = getRecipients(threadEdge);

  const unreadCount = memberEdge?.unreadMessageCounts.total;

  const usersThatReplied = useMemo(() => {
    if (!memberEdge) {
      return;
    }

    // Don't count first thread starter message as a replier
    if (
      !isReply &&
      (memberEdge.node.recentMessages.replyCount || 0) < 2 &&
      memberEdge.node.recentMessagesUsers[0]?.id ===
        memberEdge.node.lastMessage?.user.id
    ) {
      return memberEdge.node.recentMessagesUsers.slice(1);
    }

    return memberEdge.node.recentMessagesUsers;
  }, [isReply, memberEdge]);

  const replyCount = usersThatReplied?.length ?? 0;
  const isDeleted = !loading && !error && !threadEdge;

  if (isDeleted) {
    return (
      <div
        className="border border-border-container rounded-lg flex gap-4 py-7 px-12 mt-4"
        data-testid="thread-preview"
      >
        <Icon icon="Trash" size="20" className="text-icon-secondary" />
        <div className="text-subhead italic text-text-subtle">
          Thread deleted
        </div>
      </div>
    );
  }

  return (
    <ThreadAttachmentCard
      onClick={() => {
        history.push(routeToThread({ threadID }));
      }}
      unreadCount={unreadCount}
      subject={threadEdge?.node.subject}
      message={!isReply ? memberEdge?.node.firstMessage?.text : undefined}
      recipientsLine={
        <RecipientsLineSimple
          me={authData?.me}
          recipients={recipients}
          excludeID={recipientID}
        />
      }
      followButton={
        memberEdge ? <FollowButton threadEdge={memberEdge} /> : undefined
      }
      footerClassName={usersThatReplied?.length === 0 ? undefined : "pb-2"}
      footerIcon={
        usersThatReplied?.length === 0 ? (
          <Icon
            icon="CornerDownRight"
            size={16}
            className="text-icon-secondary group-hover:text-icon-secondary-hover"
          />
        ) : (
          <FacePile
            limit={5}
            users={usersThatReplied}
            reverse
            size="small"
            className="ml-2"
          />
        )
      }
      footerText={
        replyCount ? (
          <>
            {`${replyCount} ${replyCount === 1 ? "reply" : "replies"}`}
            {!!unreadCount && `, ${unreadCount} unread`}
          </>
        ) : (
          <>No replies yet</>
        )
      }
      footerDate={
        memberEdge?.node.lastMessage
          ? new Date(memberEdge.node.lastMessage.createdAt)
          : undefined
      }
    />
  );
}

type ThreadAttachmentDraftProps = {
  replyToMessageID: string;
};

export function ThreadAttachmentDraft({
  replyToMessageID,
}: ThreadAttachmentDraftProps) {
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const history = useHistory();

  const { threadDrafts, recipientID } = useThreadViewState(
    ({ threadDrafts, recipientID }) => ({
      threadDrafts,
      recipientID,
    })
  );

  const draft =
    replyToMessageID &&
    threadDrafts?.drafts?.find(
      ({ node }) => node.replyToMessage?.streamID === replyToMessageID
    )?.node;

  if (!draft) {
    return null;
  }

  return (
    <ThreadAttachmentCard
      onClick={() => {
        if (breakpoints().md) {
          history.push(routeToThread({ threadID: draft.id, to: "secondary" }));
        } else {
          openModal(<ThreadComposeModal draftID={draft.id} />);
        }
      }}
      subject={draft.subject}
      message={draft.message.text}
      recipientsLine={
        <RecipientsLineSimple
          me={authData?.me}
          recipients={draft.recipients}
          excludeID={recipientID}
        />
      }
      footerIcon={
        <Icon
          icon="EditSimple"
          size={16}
          className="text-icon-secondary group-hover:text-icon-secondary-hover"
        />
      }
      footerText="1 draft"
      footerDate={new Date(draft.updatedAt)}
    />
  );
}

function FollowButton({ threadEdge }: { threadEdge: ThreadEdge }) {
  const { toggleThreadSubscribed } = useInboxThreadActions();
  const isFollowing = isSubscribed(threadEdge);

  return (
    <Button
      icon={isFollowing ? "BellFilled" : "Bell"}
      buttonType="none"
      buttonStyle="secondary"
      type="button"
      className="h-28 text-text-subtle text-footnote hover:text-text-subtle-hover gap-4 px-6 group/follow-btn"
      iconClassName="text-icon-secondary group-hover/follow-btn:text-icon-secondary-hover"
      iconSize={16}
      tooltip={isFollowing ? "Unfollow thread" : "Follow thread"}
      onClick={e => {
        e.stopPropagation();
        toggleThreadSubscribed(threadEdge);
      }}
    >
      {!isFollowing && "Follow"}
    </Button>
  );
}

type ThreadAttachmentCardProps = {
  onClick: () => void;
  unreadCount?: number;
  subject?: string;
  message?: string;
  recipientsLine?: React.ReactNode;
  followButton?: React.ReactNode;
  footerClassName?: string;
  footerIcon: React.ReactNode;
  footerText: React.ReactNode;
  footerDate?: Date;
};

function ThreadAttachmentCard({
  onClick,
  unreadCount,
  subject,
  message,
  recipientsLine,
  followButton,
  footerClassName,
  footerIcon,
  footerText,
  footerDate,
}: ThreadAttachmentCardProps) {
  const seeMoreButtonID = useRef(generateRandomId("see-more-btn"));
  const [isTextTruncated, setIsTextTruncated] = useState(true);

  const messageMarkdown = useMemo(() => {
    if (!message) {
      return;
    }

    return isTextTruncated
      ? truncateMarkdown(
          message,
          truncateLength,
          `<button id="${seeMoreButtonID.current}" classname="!inline-block hover:underline !p-0 border-none text-text-primary font-semibold">See more</button>`
        )
      : message;
  }, [message, isTextTruncated]);

  const hasUnread = !!unreadCount;

  return (
    <button
      className="w-full border border-border-container rounded-lg text-left mt-8 px-8 py-4 hover:border-border-container-hover group"
      onClick={onClick}
      data-testid="thread-preview"
    >
      <header className="flex">
        <div className="flex items-center gap-8 p-4 flex-1">
          <div className="flex gap-4">
            <Icon
              icon="Thread"
              size="20"
              className={
                hasUnread
                  ? "text-icon-link group-hover:text-icon-link-hover"
                  : "text-icon-secondary group-hover:text-icon-secondary-hover"
              }
            />
            <div
              className={tw(
                "text-subhead-bold line-clamp-1",
                hasUnread
                  ? "text-text-link group-hover:text-text-link-hover"
                  : "text-text-secondary group-hover:text-text-secondary-hover"
              )}
            >
              {subject ?? <Skeleton width="120px" />}
            </div>
          </div>
          <div className="flex items-center mt-1 min-w-0 max-w-full text-footnote text-text-secondary">
            {recipientsLine}
          </div>
        </div>
        {followButton}
      </header>

      {messageMarkdown && (
        <div
          className="text-subhead text-text-secondary p-4"
          onClick={e => {
            const { target } = e;

            if (
              target instanceof HTMLButtonElement &&
              target.id === seeMoreButtonID.current
            ) {
              e.stopPropagation();
              setIsTextTruncated(false);
            }
          }}
        >
          {renderText(`${messageMarkdown}\n`)}
        </div>
      )}

      <footer
        className={tw(
          "flex items-center gap-8 p-4",
          !messageMarkdown && "pt-2",
          footerClassName
        )}
      >
        {footerIcon}

        <div className="flex items-baseline gap-8">
          <div
            className={tw(
              "text-footnote-bold pt-2",
              hasUnread
                ? "text-text-link group-hover:text-text-link-hover"
                : "text-text-subtle group-hover:text-text-subtle-hover"
            )}
          >
            {footerText}
          </div>

          {footerDate ? (
            <div className="text-caption text-text-subtle group-hover:text-text-subtle-hover">
              {formatRelativeTime(footerDate)}
            </div>
          ) : (
            <div className="h-18 overflow-hidden flex items-center">
              <Skeleton width={`${getRandomInt(60, 90)}px`} />
            </div>
          )}
        </div>
      </footer>
    </button>
  );
}
