import { nodeAs } from "@utility-types/graphql";
import { EditorMessage } from "components/MessageEditor/types";
import { useFetchGroupOrPreviewEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";
import env from "utils/processEnv";
import ThreadComposeModal from "../ThreadComposeModal";

const channels = {
  sales: env.salesGroupId,
  support: env.helpGroupId,
};

const useComposeToGlue = (channel: keyof typeof channels) => {
  const { authData } = useAuthData();
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));

  const { data } = useFetchGroupOrPreviewEdgeQuery({
    fetchPolicy: "cache-first",
    skip: !channels[channel] || !authData?.me.id,
    variables: {
      id: `${channels[channel]}-${authData?.me.id}`,
    },
  });

  const group = nodeAs(data?.node, ["GroupEdge", "GroupPreviewEdge"])?.node;

  const composeThreadToGlue = (message?: EditorMessage) =>
    openModal(
      <ThreadComposeModal
        initialDraft={{
          ...(group ? { recipients: [group] } : {}),
          message: message ?? { attachments: [], text: "" },
        }}
      />
    );

  return { group, composeThreadToGlue };
};

export default useComposeToGlue;
