import { useContext, useEffect } from "react";

import { EphemeralMessage } from "@utility-types";
import useAuthData from "hooks/useAuthData";
import { StreamClientContext } from "providers/StreamClientProvider";
import useRefreshChannelStore from "store/useRefreshChannelStore";
import env from "utils/processEnv";
import { glueEphemeralMessageToStreamResponse } from "utils/stream/message";

const useEphemeralMessage = () => {
  const { streamClient } = useContext(StreamClientContext);
  const { authData } = useAuthData();

  const { channelID } = useRefreshChannelStore(({ channelID }) => ({
    channelID,
  }));

  useEffect(() => {
    useRefreshChannelStore.setState({ channelID: null });
  }, [channelID]);

  const postEphemeralMessage = async (message: EphemeralMessage) => {
    if (!authData) return;
    const targetChannel =
      streamClient?.activeChannels[
        `${env.streamChannelType}:${message.threadID}`
      ];
    if (!targetChannel) return;
    targetChannel.state.addMessageSorted(
      glueEphemeralMessageToStreamResponse(message)
    );
    useRefreshChannelStore.setState({ channelID: message.threadID });
  };

  return { postEphemeralMessage };
};

export default useEphemeralMessage;
