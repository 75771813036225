import { createElement, memo } from "react";

import { type Element as hyperElement, toH } from "hast-to-hyperscript";
import ReactMarkdown, { type Components } from "react-markdown";
import refractor from "refractor/core";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import * as syntaxLang from "components/helper/syntax-highlighter";

import {
  Blockquote,
  Code,
  CodeBlock,
  Emphasis,
  HeaderFour,
  HeaderOne,
  HeaderThree,
  HeaderTwo,
  HorizontalRule,
  Hyperlink,
  ListItem,
  OrderedList,
  Paragraph,
  Strikethrough,
  Strong,
  UnorderedList,
} from "components/MessageElements";

import {
  GlueActionButton,
  glueHrefAction,
} from "components/MessageElements/GlueActionButton";
import { unescapeCodeFence } from "./util";

type RenderMarkdownOptions = { handleMasonryLink?: (url: string) => void };

Object.values(syntaxLang).forEach(lang => refractor.register(lang));

const reactMarkdownComponents = (
  options?: RenderMarkdownOptions
): Components => ({
  a: ({ children, node }) => {
    const href = node.properties?.href;

    if (typeof href !== "string") return null;

    const { action, params } = glueHrefAction(href);
    if (action) {
      return (
        <GlueActionButton action={action} params={params}>
          {children}
        </GlueActionButton>
      );
    }

    return (
      <Hyperlink url={href} handleMasonryLink={options?.handleMasonryLink}>
        {children}
      </Hyperlink>
    );
  },
  blockquote: ({ children }) => <Blockquote>{children}</Blockquote>,
  code: ({ children, className, inline }) => {
    const match = /language-(\w+)/.exec(className || "");
    const language = match?.[1] || "text";
    const content = unescapeCodeFence(children[0]?.toString() || "");

    if (inline || !refractor.registered(language)) {
      return <Code>{content}</Code>;
    }

    const isHyperElement = (el: { type: unknown }): el is hyperElement =>
      el.type === "element";

    return (
      <Code {...{ "data-code-language": language }}>
        {toH(createElement, {
          children: refractor.highlight(content, language).map(
            el =>
              (isHyperElement(el) && {
                ...el,
                children: el.children,
              }) || {
                type: "text",
                value: el.type === "text" ? el.value : "",
              }
          ),
          tagName: "div", // root element need because we are using an older version
          type: "element",
        })}
      </Code>
    );
  },
  del: ({ children }) => <Strikethrough>{children}</Strikethrough>,
  em: ({ children }) => <Emphasis>{children}</Emphasis>,
  h1: ({ children }) => <HeaderOne>{children}</HeaderOne>,
  h2: ({ children }) => <HeaderTwo>{children}</HeaderTwo>,
  h3: ({ children }) => <HeaderThree>{children}</HeaderThree>,
  h4: ({ children }) => <HeaderFour>{children}</HeaderFour>,
  hr: () => <HorizontalRule />,
  img: ({ node }) => {
    if (!node.properties) return null;
    const { alt, src } = node.properties;
    if (typeof src !== "string") return null;
    return <Hyperlink url={src}>Image: {alt || src}</Hyperlink>;
  },
  li: ({ children, index }) => <ListItem key={index}>{children}</ListItem>,
  ol: ({ children }) => <OrderedList>{children}</OrderedList>,
  p: ({ children }) => <Paragraph>{children}</Paragraph>,
  pre: ({ children }) => <CodeBlock>{children}</CodeBlock>,
  strong: ({ children }) => <Strong>{children}</Strong>,
  ul: ({ children }) => <UnorderedList>{children}</UnorderedList>,
});

const MemoizedMarkdownComponent = memo(
  ({ text, options }: { text: string; options?: RenderMarkdownOptions }) => (
    <ReactMarkdown
      children={text}
      components={reactMarkdownComponents(options)}
      rehypePlugins={[rehypeRaw, rehypeKatex]}
      remarkPlugins={[remarkGfm, [remarkMath, { singleDollarTextMath: false }]]}
    />
  )
);

const renderMarkdown = (
  markdown: string,
  options?: RenderMarkdownOptions
): JSX.Element | null => (
  <MemoizedMarkdownComponent text={markdown} options={options} />
);

export default renderMarkdown;
