import React, { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

import { ColorSchemeTypes } from "@utility-types";
import { ReactComponent as LLMGlueLogo } from "assets/llm-logo-glue.svg";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Header, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import { Form, RadioGroup, SubmitButton } from "components/design-system/Forms";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import Hr from "components/design-system/Hr";
import {
  NotifyMessagesSetting,
  useFetchLlmModelsQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";

import Key from "components/design-system/ui/Key";
import { environment } from "remirror";
import { isMobile } from "utils/platform";
import AppVersion from "./AppVersion";

type Props = {
  data: DataType;
  loading: boolean;
  onUpdate: (props: DataType) => Promise<void> | undefined;
};

type DataType = {
  chatModel?: string;
  colorScheme: ColorSchemeTypes;
  enterKeyBehavior: "new-line" | "send-message";
  notifyMessages?: NotifyMessagesSetting;
};

const notifyMessagesOptions = [
  {
    label: "All inbox messages",
    value: NotifyMessagesSetting.Inbox,
  },
  {
    label: "Mentions, replies, & direct messages",
    value: NotifyMessagesSetting.Direct,
  },
  {
    label: "Nothing",
    value: NotifyMessagesSetting.None,
  },
];

const schemeColorOptions = [
  { label: "System default", value: ColorSchemeTypes.Default },
  { label: "Light", value: ColorSchemeTypes.Light },
  { label: "Dark", value: ColorSchemeTypes.Dark },
];

type FieldSetProps = {
  children: React.ReactNode;
  label: string;
};

const FieldSet = ({ children, label }: FieldSetProps) => {
  return (
    <fieldset className="mb-32">
      <div className="font-bold mb-16">{label}</div>
      {children}
    </fieldset>
  );
};

const Field = ({
  children,
  label,
  labelPosition = "center",
}: {
  children: React.ReactNode;
  label: React.ReactNode | string;
  labelPosition?: "top" | "center";
}) => (
  <div
    className={tw("grid gap-16 md:grid-cols-[200px_1fr]", {
      "items-center": labelPosition === "center",
    })}
  >
    <div>{label}</div>
    {children}
  </div>
);

const EditorOptions = () => {
  if (isMobile()) return null; // is irrelevant on mobile

  const metaKey = environment.isMac ? "⌘" : "Ctrl";

  return (
    <>
      <FieldSet label="Editor options (this device)">
        <Field
          label={
            <>
              Press <Key>Enter</Key> to:
            </>
          }
          labelPosition="top"
        >
          <RadioGroup<DataType>
            groupClassName="!my-0"
            name="enterKeyBehavior"
            options={[
              {
                label: (
                  <>
                    Start a new line (use <Key>{metaKey}</Key> <Key>Enter</Key>{" "}
                    to send)
                  </>
                ),
                value: "new-line",
              },
              {
                label: "Send a message",
                value: "send-message",
              },
            ]}
            wrapperClassName=""
          />
        </Field>
      </FieldSet>
      <Hr className="mb-20" />
    </>
  );
};

const Appearance = () => {
  const colorScheme: ColorSchemeTypes = useWatch({
    name: "colorScheme",
  });

  useEffect(() => {
    useLocalSettingsStore.setState({ colorSchemePreview: colorScheme });
  }, [colorScheme]);

  return (
    <FieldSet label="Appearance (this device)">
      <Field label="Color scheme:">
        <SelectDropdown name="colorScheme" options={schemeColorOptions} />
      </Field>
    </FieldSet>
  );
};

const PushNotifications = () => (
  <FieldSet label="Notifications (desktop & mobile)">
    <Field label="Notify me about:" labelPosition="top">
      <RadioGroup<DataType>
        groupClassName="!my-0"
        name="notifyMessages"
        options={notifyMessagesOptions}
        wrapperClassName=""
      />
    </Field>
  </FieldSet>
);

const AiModel = ({ workspaceID }: { workspaceID: string }) => {
  const { data } = useFetchLlmModelsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      workspaceID,
    },
  });

  const options = data?.llmModels.edges.map(({ node }) => {
    return {
      iconSrc: glueImageURL(node.providerLogoURL, { fit: "max", w: 40, h: 40 }),
      label: node.name,
      value: node.id,
    };
  });

  return (
    <FieldSet label="Glue AI model">
      <Field label="Preferred model:">
        <SelectDropdown
          name="chatModel"
          options={[
            {
              icon: LLMGlueLogo,
              label: "Automatic",
              value: "auto",
            },
            ...(options ?? []),
          ]}
        />
      </Field>
    </FieldSet>
  );
};

const PreferencesForm = ({
  data,
  loading,
  onUpdate,
  ...props
}: Props & ModalProps) => {
  const { authData } = useAuthData();
  const workspaceID = authData?.me.workspaceIDs[0];

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const onSubmit = (data: DataType) => {
    setSubmitDisabled(true);
    return onUpdate(data);
  };

  const { colorScheme, colorSchemePreview } = useLocalSettingsStore(
    ({ colorScheme, colorSchemePreview }) => ({
      colorScheme,
      colorSchemePreview,
    })
  );

  const afterClose = () =>
    colorSchemePreview !== colorScheme &&
    useLocalSettingsStore.setState({ colorSchemePreview: colorScheme });

  return (
    <Form
      loading={loading}
      onSubmit={onSubmit}
      useFormProps={{ defaultValues: data }}
    >
      <StandardModal
        afterClose={afterClose}
        contentHandlesSafeArea={false}
        header={
          <Header
            mobileCtaLabel="Save"
            mobileCtaProps={{ type: "submit" }}
            variant="bordered"
            className="md:!px-20"
          >
            <h3>Preferences</h3>
          </Header>
        }
        {...props}
      >
        <Main className="p-20">
          <PushNotifications />
          <Hr className="mb-20" />
          <EditorOptions />
          <Appearance />

          {workspaceID && (
            <>
              <Hr className="mb-20" />
              <AiModel workspaceID={workspaceID} />
            </>
          )}
        </Main>
        <Footer
          flexboxClassName="justify-between items-center"
          className="!px-20"
        >
          <AppVersion />

          <SubmitButton
            className="hidden md:block"
            disabled={submitDisabled}
            icon="Check"
            iconStroke={3}
          >
            Save Preferences
          </SubmitButton>
        </Footer>
      </StandardModal>
    </Form>
  );
};

export default PreferencesForm;
