import { StreamMessage } from "stream-chat-react";
import {
  DefaultChannelType,
  DefaultMessageType,
  DefaultUserType,
} from "stream-chat-react/dist/types/types";
import { Channel } from "stream-chat/dist/types/channel";
import { StreamChat } from "stream-chat/dist/types/client";
import {
  Attachment,
  DefaultGenerics,
  Event,
  ExtendableGenerics,
  LiteralStringForUnion,
  UnknownType,
} from "stream-chat/dist/types/types";

import { ExternalObject, FileType } from "@utility-types";
import { GlueFile, ThreadPreview } from "./graphql";

type StreamFileInfo = {
  // backwards compatibility
  file_id: string;
  file_size: number;
};

export type StreamImageType = FileType.Image;
export type StreamImageAttachment = Partial<GlueFile> &
  StreamFileInfo & {
    fallback: string;
    image_blurhash?: string;
    image_height?: number;
    image_url: string;
    image_width?: number;
    type: StreamImageType;
  };

export type StreamFileType = FileType.File | FileType.Video | FileType.Audio;
export type StreamFileAttachment = Partial<GlueFile> &
  StreamFileInfo & {
    asset_url: string;
    mime_type: string;
    previewable?: boolean;
    title: string;
    type: StreamFileType;
  };

export type StreamLinkPreviewAttachment = ExternalObject & {
  // Card attachment props for fallback
  file_size?: number;
  image_url?: string;
  text?: string;
  title_link?: string;
  type?: undefined;
};

export type StreamThreadAttachment = Pick<
  ThreadPreview,
  "__typename" | "id"
> & {
  type?: undefined;
};

export type StreamUnknownAttachment = Attachment<DefaultGenerics> & {
  __typename?: "__unknown";
  file_size?: number;
  id: string;
  type?: "__unknown";
};

export type StreamGlueAttachment =
  | StreamImageAttachment
  | StreamFileAttachment
  | StreamLinkPreviewAttachment
  | StreamThreadAttachment
  | StreamUnknownAttachment;

export type StreamGlueAdditionalProps = {
  content_updated_at?: string;
  in_stream?: boolean;
  reply_thread_ids?: string[];
};

export type StreamGlueMessage = DefaultMessageType &
  Pick<
    StreamMessage,
    | "cid"
    | "created_at"
    | "errorStatusCode"
    | "id"
    | "quoted_message_id"
    | "status"
    | "text"
    | "type"
    | "updated_at"
    | "user"
  > & {
    attachments?: StreamGlueAttachment[];
    quoted_message?: StreamGlueMessage;
  } & StreamGlueAdditionalProps;

export enum StreamCustomEvents {
  EPHEMERAL_MESSAGE = "glue:ephemeral_message",
  NOTIFICATION_NEW = "glue:notification_new",
  LAMBDA_LOGS = "glue:lambda_logs",
}

export type StreamGlueEvent = Event<GlueDefaultStreamChatGenerics>;

export type StreamGlueChannel = Channel<GlueDefaultStreamChatGenerics>;

export type StreamGlueChat = StreamChat<GlueDefaultStreamChatGenerics>;

export type GlueDefaultStreamChatGenerics = ExtendableGenerics & {
  attachmentType: StreamGlueAttachment;
  channelType: DefaultChannelType;
  commandType: LiteralStringForUnion;
  eventType: UnknownType;
  messageType: StreamGlueMessage;
  reactionType: UnknownType;
  userType: DefaultUserType;
};
