import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ThreadEdge, ThreadPreviewEdge, nodeAs } from "@utility-types";
import FacePile from "components/design-system/FacePile";
import { useFetchThreadEdgeQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import { formatRelativeTime } from "utils/formatDate";
import getRandomInt from "utils/getRandomInt";
import tw from "utils/tw";

import { Styles } from "components/MessageElements";
import { Skeleton } from "components/Skeleton";
import { Icon } from "components/design-system/icons";
import { routeToThread } from "components/routing/utils";

const colorClassName =
  "text-text-subtle group-hover/thread-preview:!text-interactive-strong-hover group-active/thread-preview:text-interactive-strong-hover";

type Props = {
  className?: string;
  replyToMessageID?: string;
  threadID: string;
};

const UnMemoizedThreadPreview = ({
  className,
  replyToMessageID,
  threadID,
}: Props): JSX.Element | null => {
  const { authData, authReady } = useAuthData();
  const [isDeleted, setIsDeleted] = useState(false);
  const isReply = !!replyToMessageID;

  const { data, error, loading } = useFetchThreadEdgeQuery({
    fetchPolicy: authReady && !isDeleted ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id,
    variables: {
      id: `${threadID}-${authData?.me.id}`,
    },
  });

  useEffect(() => {
    if (data?.node) {
      setIsDeleted(false);
      return;
    }

    if (threadID && !loading && !error && !data?.node) {
      setIsDeleted(true);
    }
  }, [threadID, error, loading, data?.node]);

  const threadEdge = nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"]);
  const { memberEdge } = useMemberEdge(threadEdge);

  if (isDeleted)
    return (
      <div
        className={tw("italic text-sm text-text-subtle h-50 mt-4", className)}
        data-testid="thread-preview"
      >
        <div className="flex">
          <span className="inline-block h-24 leading-6">
            This thread was deleted.
          </span>
        </div>
        <ThreadFooter
          isReply={isReply}
          loading={false}
          threadEdge={undefined}
          threadReplyCount={0}
        />
      </div>
    );

  const replyCount = memberEdge?.node.recentMessages.replyCount || 0;

  return (
    <Link
      className={tw(
        "group/thread-preview pr-16 select-none block max-w-full h-50 mt-4",
        className
      )}
      data-testid="thread-preview"
      to={routeToThread({ threadID, to: "secondary" })}
    >
      <div className="flex">
        <ThreadHeader threadEdge={threadEdge} />
      </div>
      <ThreadFooter
        isReply={isReply}
        loading={!threadEdge}
        threadEdge={threadEdge}
        threadReplyCount={replyCount}
      />
    </Link>
  );
};

const ThreadHeader = ({
  threadEdge,
}: {
  threadEdge: ThreadEdge | ThreadPreviewEdge | undefined;
}) => (
  <span
    {...{ "data-mention-atom-id": threadEdge?.node.id }}
    className={tw(
      "inline-flex items-center w-full text-interactive-strong group-hover/thread-preview:text-interactive-strong-hover"
    )}
  >
    <span
      className={tw(
        Styles.mention,
        "leading-5 truncate group-hover/thread-preview:border-accent-highlight"
      )}
    >
      {threadEdge?.node.subject ?? <Skeleton width="120px" />}
    </span>
  </span>
);

const ThreadFooter = ({
  isReply,
  loading,
  threadEdge,
  threadReplyCount,
}: {
  isReply: boolean;
  loading: boolean;
  threadEdge: ThreadEdge | ThreadPreviewEdge | undefined;
  threadReplyCount: number;
}) => {
  const memberEdge = nodeAs(threadEdge, ["ThreadEdge"]);

  const state =
    !loading && memberEdge
      ? memberEdge.isRead
        ? "default"
        : memberEdge.unreadMessageCounts.mentioned
          ? "mentioned"
          : "unread"
      : "default";

  const unreadColorClassName = tw({
    [colorClassName]: state === "default",
    "group-hover/thread-preview:text-text-primaryLegacy-hover group-active/thread-preview:text-text-primaryLegacy-hover text-text-primaryLegacy":
      state === "unread",
    "text-interactive-alert group-hover:text-interactive-alert-hover group-active/thread-preview:!text-interactive-alert-hover":
      state === "mentioned",
  });

  // Don't count first thread starter message as a replier
  let repliers = memberEdge?.node.recentMessagesUsers || [];
  if (
    !isReply &&
    (memberEdge?.node.recentMessages.replyCount || 0) < 2 &&
    repliers[0]?.id === memberEdge?.node.lastMessage?.user.id
  ) {
    repliers = repliers.slice(1);
  }

  const replyCount = () => {
    let count = threadReplyCount;
    if (!memberEdge?.node.replyToMessage) {
      count = Math.max(0, count - 1);
    }
    return count
      ? `${count} ${count === 1 ? "reply" : "replies"}`
      : "No replies yet";
  };

  const unreadCount = () =>
    memberEdge?.unreadMessageCounts.total
      ? `, ${memberEdge.unreadMessageCounts.total} unread`
      : "";

  const relativeTime = () =>
    repliers.length && memberEdge?.node.lastMessage?.createdAt
      ? formatRelativeTime(new Date(memberEdge.node.lastMessage?.createdAt))
      : null;

  return (
    <div
      className={tw("flex mt-4 h-24 select-none items-center", {
        "glue-skeleton-static": !loading,
      })}
    >
      {loading || repliers.length ? (
        <FacePile
          className="ml-3 mr-8"
          limit={5}
          users={loading ? undefined : repliers}
          reverse
          size="small"
        />
      ) : (
        <Icon
          icon="Thread"
          className="m-2 mr-8 text-interactive-subtle group-hover/thread-preview:text-interactive-subtle-hover"
          size={20}
        />
      )}

      <div
        className={tw(
          "flex items-center text-sm font-semibold leading-4",
          unreadColorClassName
        )}
      >
        {loading || !threadEdge ? (
          <Skeleton width={`${getRandomInt(60, 90)}px`} />
        ) : memberEdge ? (
          <>
            {replyCount()}
            {unreadCount()}
          </>
        ) : threadEdge ? (
          <>
            <Icon
              icon="Lock"
              className="inline-block align-middle relative mr-8 text-interactive-subtle group-hover/thread-preview:text-interactive-subtle-hover"
              size={16}
            />
            Private
          </>
        ) : null}
      </div>
      <div
        className={tw("text-sm leading-4 ml-8 hidden md:block", colorClassName)}
      >
        {loading || !threadEdge ? (
          <Skeleton className="ml-4" width={`${getRandomInt(60, 90)}px`} />
        ) : (
          relativeTime()
        )}
      </div>
    </div>
  );
};

const ThreadPreview = memo(UnMemoizedThreadPreview);

export default ThreadPreview;
