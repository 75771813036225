import { Recipient } from "@utility-types";
import Tooltip from "components/design-system/FloatingUi/Tooltip";

import { Icon } from "components/design-system/icons";
import tw from "utils/tw";
import { useGetExternalBadgeData } from "../../../hooks/useGetExternalBadgeData";

const ExternalBadge = ({
  className,
  label: labelProps = null,
  recipients = [],
  showLabel = true,
  tooltip: tooltipProps = "",
}: {
  className?: string;
  label?: string | null;
  recipients?: Recipient | Recipient[];
  showLabel?: boolean;
  tooltip?: string | null;
}): JSX.Element | null => {
  const { label: labelData, tooltip: tooltipData } =
    useGetExternalBadgeData(recipients);
  const tooltip = tooltipData || tooltipProps;
  const label = labelData || labelProps;

  if (!label) return null;

  return (
    <Tooltip
      content={tooltip}
      disabled={tooltip === ""}
      placement="bottom"
      tooltipStyle="inverted"
    >
      <div
        className={tw(
          "flex shrink-0 items-center text-xs font-semibold px-4 py-2 mx-8 text-text-warning bg-background-warning rounded-sm select-none",
          className
        )}
        data-testid="external-badge"
      >
        <Icon icon="ExternalAccount" size={10} />
        {showLabel && <span className="ml-2"> {label}</span>}
      </div>
    </Tooltip>
  );
};

export default ExternalBadge;
