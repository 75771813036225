import { useWorkspacesAppsLazyQuery } from "generated/graphql";

import useConfigureApps from "./useConfigureApps";

type Props = {
  className?: string;
  href: string;
  reactTestId?: string;
};

const ConfigureAppLink = ({
  children,
  className,
  href,
  reactTestId,
}: WithChildren<Props>) => {
  const [appsQuery] = useWorkspacesAppsLazyQuery();
  const { configureApp } = useConfigureApps();

  const onClick = async (e: React.MouseEvent) => {
    e.preventDefault();

    const splitUrl = href
      .split("/")
      .filter(u => u.startsWith("app_") || u.startsWith("usr_"));

    const [appID, configurableID] = splitUrl;

    if (!appID || !configurableID) return;

    const { data } = await appsQuery();

    const app = data?.workspaces.edges.flatMap(edge =>
      edge.node.apps.edges.filter(app => app.node.id === appID)
    )[0];
    if (!app) return;

    configureApp({
      app: app.node,
      configurableID,
      workspaceID: app.workspaceID,
    });
  };

  return (
    <a
      className={className}
      data-testid={reactTestId}
      href={href}
      onClick={onClick}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default ConfigureAppLink;
