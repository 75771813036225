import { useEffect, useRef, useState } from "react";

import type { GetReactionsAPIResponse } from "stream-chat/dist/types/types";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import { HorizontalScrollingList } from "components/HorizontalScrollingList";
import { ModalProps } from "components/ModalKit/Modal";
import { Footer, Main } from "components/ModalKit/Parts";
import { StandardModal } from "components/Modals";
import Avatar from "components/design-system/Avatar/Avatar";
import { Emoji } from "components/design-system/Emoji";
import useCustomReactionsData from "components/emoji/hooks/useCustomReactionsData";
import tw from "utils/tw";

const EmojiTab = ({
  children,
  current,
  currentReactionButton,
  emojiID,
  setCurrent,
}: WithChildren<{
  emojiID: string;
  current: string;
  currentReactionButton: React.RefObject<HTMLButtonElement>;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
}>) => {
  return (
    <button
      key={`modal-${emojiID}`}
      ref={emojiID === current ? currentReactionButton : undefined}
      className={tw(
        "h-[54px]",
        "flex shrink-0 justify-center items-center",
        "border-b-2 border-transparent",
        {
          "border-interactive-primary": emojiID === current,
        }
      )}
      onClick={() => setCurrent(emojiID)}
      type="button"
    >
      {children}
    </button>
  );
};

const ReactionsModal = ({
  getAllReactions,
  openedWith,
  reactionTypes,
  ...props
}: {
  getAllReactions: () => Promise<GetReactionsAPIResponse | undefined>;
  openedWith: string;
  reactionTypes: string[];
} & ModalProps) => {
  const [allReactions, setAllReactions] =
    useState<GetReactionsAPIResponse<GlueDefaultStreamChatGenerics>>();
  const [current, setCurrent] = useState(openedWith);
  const [startPosition, setStartPosition] = useState<number>(0);

  const currentReactionButton = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const fn = async () => {
      const getReactionsAPIResponse = await getAllReactions();
      setAllReactions(getReactionsAPIResponse);

      const columnBox = currentReactionButton.current
        ?.closest("li")
        ?.getBoundingClientRect();

      setStartPosition(columnBox ? columnBox.left - columnBox.width : 0);
    };
    fn();
  }, [getAllReactions]);

  const { customReactions } = useCustomReactionsData(reactionTypes);
  const customTarget = customReactions.get(current);

  return (
    <StandardModal
      contentClassName="!h-auto max-h-[50vh]"
      header={
        <div className="pl-[54px]">
          <HorizontalScrollingList
            columns={5}
            gutter={0}
            startPosition={startPosition}
          >
            {reactionTypes.map(emojiID => {
              const customReaction = customReactions.get(emojiID);
              if (customReaction) {
                return (
                  <EmojiTab
                    emojiID={emojiID}
                    current={current}
                    currentReactionButton={currentReactionButton}
                    setCurrent={setCurrent}
                  >
                    <Avatar
                      avatarURL={customReaction.imageURL}
                      name={customReaction.name}
                      size="medium"
                    />
                  </EmojiTab>
                );
              }

              return (
                <EmojiTab
                  emojiID={emojiID}
                  current={current}
                  currentReactionButton={currentReactionButton}
                  setCurrent={setCurrent}
                >
                  <Emoji name={emojiID} />
                </EmojiTab>
              );
            })}
          </HorizontalScrollingList>
        </div>
      }
      {...props}
    >
      <Main className="p-16">
        <div className="font-semibold">{`:${
          customTarget?.name ?? current
        }:`}</div>

        {allReactions?.reactions
          .filter(reaction => reaction.type === current && reaction.user)
          .map(reaction => (
            <div key={reaction.user?.id} className="flex items-center my-15">
              <div className="mr-10">
                <Avatar
                  avatarURL={`${reaction.user?.image}`}
                  name={reaction.user?.name}
                  size="large"
                />
              </div>
              <div>{reaction.user?.name}</div>
            </div>
          ))}
      </Main>
      <Footer />
    </StandardModal>
  );
};

export default ReactionsModal;
