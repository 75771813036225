import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { ReactExtensions, useRemirrorContext } from "@remirror/react";
import { useDropzone } from "react-dropzone";
import { useDebouncedCallback } from "use-debounce";

import {
  FileOrImageUpload,
  isExternalObject,
  isGlueFile,
} from "@utility-types";
import { ExternalObjectPreview } from "@utility-types/linkPreview";

import { DropzoneTarget, FileUploader, LinkPreviewList } from "../components";
import { useEditorContentState } from "../providers/EditorContentProvider";
import { GlueWysiwygPreset } from "../remirror-extensions/GlueWysiwyg";
import { fileToFileUpload } from "../stream-helpers";

import useValidateAttachments from "./useValidateAttachments";

type UploadsState = ComponentProps<typeof FileUploader>["orderedUploads"];

type LinkPreviewState = ComponentProps<typeof LinkPreviewList>["state"];

type Props = {
  onChange: () => void;
  readOnly: boolean;
};

export default function useAttachments({ onChange, readOnly }: Props) {
  const context = useRemirrorContext<ReactExtensions<GlueWysiwygPreset>>();
  const editorContentUpdater = useEditorContentState().setState;

  const { validateAttachments } = useValidateAttachments();

  const [linkPreviewState, setLinkPreviewState] = useState<LinkPreviewState>(
    new Map()
  );

  const [uploadsState, setUploadsState] = useState<UploadsState>(new Map());

  const stateRef = useRef({ linkPreviewState, uploadsState });

  // MessageEditor onChange callback
  const debouncedChange = useDebouncedCallback(onChange, 158);

  const setProcessing = useCallback(() => {
    const loading = [...stateRef.current.linkPreviewState.values()].find(
      ({ state }) => state === "loading"
    );

    const uploading = [...stateRef.current.uploadsState.values()].find(
      ({ uploadInfo: { state } }) => state === "uploading"
    );

    const hasUploads =
      getFinishedAttachments(stateRef.current.uploadsState).length > 0;

    const hasLinkPreviews =
      getFinishedAttachments(stateRef.current.linkPreviewState).length > 0;

    editorContentUpdater({
      hasAttachments: hasLinkPreviews || hasUploads,
      hasUploads,
      isProcessing: !!loading || !!uploading,
    });
  }, [editorContentUpdater]);

  const setAttachments = useCallback(
    (
      attachments: (FileOrImageUpload | ExternalObjectPreview)[],
      skipOnChange = false,
      focus = true
    ) => {
      const externalObjectPreview = attachments.filter(isExternalObject);
      const fileAttachments = attachments.filter(isGlueFile);

      setLinkPreviewState(() => {
        const temp = new Map();

        externalObjectPreview.forEach(externalObject => {
          temp.set(externalObject.id, externalObject);
        });

        stateRef.current.linkPreviewState = temp;

        return temp;
      });

      setUploadsState(() => {
        const temp = new Map();

        fileAttachments.forEach(file => {
          temp.set(file.id, file);
        });

        stateRef.current.uploadsState = temp;

        return temp;
      });

      focus && context.commands.focus(true);

      !skipOnChange && debouncedChange();
    },
    [context.commands, debouncedChange]
  );

  const setAttachmentsRef = useRef(setAttachments);

  const onAttachFiles = useCallback(
    (newFiles: File[]) => {
      if (
        !validateAttachments(
          {
            linkPreviews: [
              ...stateRef.current.linkPreviewState.values(),
            ].filter(previews => previews.state === "finished"),
            uploads: [...stateRef.current.uploadsState.values()],
          },
          newFiles.length
        )
      ) {
        return;
      }
      setAttachmentsRef.current([
        ...stateRef.current.linkPreviewState.values(),
        ...stateRef.current.uploadsState.values(),
        ...newFiles.map(fileToFileUpload),
      ]);
    },
    [validateAttachments]
  );

  const addLinkPreview = (preview: ExternalObjectPreview): boolean => {
    const temp = new Map(stateRef.current.linkPreviewState);
    const existing = [...temp.values()].find(
      ({ previewId, url }) =>
        previewId === preview.previewId ||
        url === preview.url ||
        url.replace(/\/$/, "") === preview.url.replace(/\/$/, "")
    );

    if (
      preview.state === "loading" &&
      !validateAttachments(
        {
          linkPreviews: [...stateRef.current.linkPreviewState.values()],
          uploads: [...stateRef.current.uploadsState.values()],
        },
        1
      )
    ) {
      return false;
    }

    if (!existing && preview.state === "failed") {
      return false;
    }

    if (existing && existing.previewId !== preview.previewId) {
      return false;
    }

    const added = !temp.has(preview.previewId);

    temp.set(preview.previewId, preview);

    setAttachmentsRef.current([
      ...temp.values(),
      ...stateRef.current.uploadsState.values(),
    ]);

    return added;
  };

  const { getRootProps: getDropzoneRootProps } = useDropzone({
    maxFiles: 10,
    noKeyboard: true,
    onDrop: onAttachFiles,
  });

  const linkPreviewsComponent = (
    <LinkPreviewList
      addLinkPreview={addLinkPreview}
      onChange={state => {
        setAttachmentsRef.current([
          ...state.values(),
          ...stateRef.current.uploadsState.values(),
        ]);
      }}
      state={linkPreviewState}
    />
  );

  const dropzoneTargetComponent = (
    <DropzoneTarget
      getDropzoneRootProps={getDropzoneRootProps}
      readOnly={readOnly}
    />
  );

  const fileUploaderComponent = (
    <FileUploader
      onChange={state => {
        if (uploadsState.size === 0) return;

        setAttachmentsRef.current([
          ...state.values(),
          ...stateRef.current.linkPreviewState.values(),
        ]);
      }}
      orderedUploads={uploadsState}
    />
  );

  useEffect(() => {
    setAttachmentsRef.current = setAttachments;
  }, [setAttachments]);

  useEffect(() => {
    setProcessing();
  }, [linkPreviewState, setProcessing, uploadsState]);

  useEffect(
    () => () => {
      debouncedChange.cancel();
    },
    [debouncedChange]
  );

  return {
    attachmentComponents: (
      <>
        {dropzoneTargetComponent}
        {linkPreviewsComponent}
        {fileUploaderComponent}
      </>
    ),
    attachmentState: stateRef,
    getFinishedAttachments,
    onAttachFiles,
    setAttachmentsRef,
  };
}

function getFinishedAttachments<T extends UploadsState | LinkPreviewState>(
  state: T
): (T extends UploadsState ? FileOrImageUpload : ExternalObjectPreview)[] {
  return [...state.values()].filter(
    (
      item
    ): item is T extends UploadsState
      ? FileOrImageUpload
      : ExternalObjectPreview => {
      if (item.__typename === "ExternalObject") {
        return item.state === "finished";
      }
      return item.uploadInfo.state === "finished";
    }
  );
}
