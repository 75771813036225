import { useController } from "react-hook-form";

const Error = ({
  customError,
  name,
}: {
  customError?: string;
  name: string;
}) => {
  const {
    fieldState: { error },
  } = useController({ name });

  if (
    !customError &&
    (!error || (error.type === "required" && error.message === ""))
  )
    return null;

  return (
    <p className="m-0 mt-4 text-footnote text-text-alert">
      {customError || error?.message || `Error: ${error?.type}`}
    </p>
  );
};

export default Error;
