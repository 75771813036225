import tw from "utils/tw";

export default function buttonStyles(active = false, filled = false) {
  const className = {
    styles: tw(
      "!p-4 mr-5 last:mr-0 rounded-md border-none disabled:cursor-default",
      { "!text-interactive-strong !bg-background-ghost": active },
      { "!bg-transparent": !active && !filled },
      {
        "!bg-interactive-primary hover:!bg-interactive-primary-hover disabled:!bg-interactive-primary-disabled":
          filled,
      }
    ),
  };

  return className.styles;
}
