import { DropzoneRootProps } from "react-dropzone";
import { Paperclip } from "react-feather";

import tw from "utils/tw";

type Props = {
  getDropzoneRootProps: <T extends DropzoneRootProps>(
    props?: T | undefined
  ) => T;
  readOnly: boolean;
};

export default function DropzoneTarget({
  getDropzoneRootProps,
  readOnly,
}: Props) {
  return (
    <div
      className={tw(
        "drop-zone-target",
        "hidden absolute z-9 select-none",
        {
          "drag:flex": !readOnly,
        },
        "justify-center items-center w-full h-full font-ui text-lg font-semibold",
        "text-text-subtle bg-background/75 rounded"
      )}
      data-testid="dropzone-target"
      {...getDropzoneRootProps()}
    >
      <Paperclip
        className="mr-11 text-interactive-subtle pointer-events-none select-none"
        size="15"
      />
      Drop files here to attach
    </div>
  );
}
