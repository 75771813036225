import { Button } from "components/design-system/Button";
import breakpoints from "utils/breakpoints";
import tw from "utils/tw";

type Props = {
  isFollowed: boolean;
  onClick: () => void;
};

export const GroupNotificationsButton = ({ isFollowed, onClick }: Props) => {
  return (
    <Button
      icon={isFollowed ? "BellFilled" : "Bell"}
      iconSize={16}
      buttonStyle="subtle"
      className={tw(
        "group !text-footnote text-text-subtle",
        "h-28 justify-center rounded-md !border-border-container hover:bg-background-secondary",
        isFollowed && breakpoints().md ? "!px-8" : "w-28"
      )}
      iconClassName={tw(
        "text-icon-secondary group-hover:text-icon-secondary-hover",
        isFollowed && breakpoints().md ? "!mr-4" : undefined
      )}
      onClick={onClick}
      tooltip="View notification settings"
    >
      {isFollowed && breakpoints().md && "Everything"}
    </Button>
  );
};
