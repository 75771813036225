import { useHistory } from "react-router";

import { Recipient, UserEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import getRandomInt from "utils/getRandomInt";
import { formatGroupName } from "utils/group/formatGroupName";

import { Skeleton } from "components/Skeleton";

import ExternalBadge from "components/design-system/ui/ExternalBadge";
import { routeToUser } from "components/routing/utils";
import tw from "utils/tw";
import Avatar from "../design-system/Avatar/Avatar";

const UserInfo = ({
  onClick,
  recipient,
}: {
  onClick?: () => void;
  recipient: Recipient;
}) => (
  <div className="flex flex-col px-24 py-4 -ml-6 mr-4 -my-4">
    <span className="flex items-center m-0 text-title-5 font-semibold">
      {recipient ? (
        formatGroupName(recipient).name
      ) : (
        <Skeleton height="22px" width={`${getRandomInt(200, 300)}px`} />
      )}
    </span>
    <div className="flex items-center min-h-[22px]">
      {recipient.bio && (
        <span className="leading-[22px] text-text-secondary">
          {recipient.bio}
        </span>
      )}
      <div className={tw({ "ml-8": !!recipient.bio })}>
        <ExternalBadge
          className={tw({ "!m-0": !recipient.bio })}
          recipients={recipient}
        />
      </div>
    </div>
    <Button
      buttonStyle="simplePrimary"
      className="!px-0 leading-5"
      onClick={onClick}
    >
      View Profile
    </Button>
  </div>
);

const NoPersistentChatProfile = ({ userEdge }: { userEdge?: UserEdge }) => {
  const history = useHistory();
  const userPath =
    userEdge &&
    routeToUser({
      to: "secondary",
      userID: userEdge.node.id ?? "",
    });

  if (!userEdge) return null;

  return (
    <div className="flex items-center w-full p-16">
      <div className="flex items-center grow">
        <div className="flex items-center">
          <Avatar {...userEdge.node} rounded="rounded-md" size="x-large" />

          <div className="ml-8">
            <UserInfo
              onClick={userPath ? () => history.push(userPath) : undefined}
              recipient={userEdge.node}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPersistentChatProfile;
