import React, { useRef } from "react";

import type { Placement } from "@floating-ui/react";
import { Camera, Folder, Image } from "react-feather";

import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";

type Props = {
  multiple?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;
  onlyImages?: boolean;
  placement?: Placement;
  readOnly?: boolean;
  target: JSX.Element;
};

const FileUploadMenu = ({
  multiple = false,
  onChange,
  onlyImages = false,
  placement = "bottom-start",
  readOnly = false,
  target,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const menuElements = [
    {
      icon: Image,
      onClick: () => {
        fileInputRef.current?.setAttribute("accept", "image/*");
        fileInputRef.current?.removeAttribute("capture");
        fileInputRef.current?.click();
      },
      text: "Photo Library",
    },
    {
      icon: Camera,
      onClick: () => {
        fileInputRef.current?.setAttribute("accept", "image/*");
        fileInputRef.current?.setAttribute("capture", "");
        fileInputRef.current?.click();
      },
      text: "Take Photo",
    },
  ];

  if (!onlyImages) {
    menuElements.push({
      icon: Folder,
      onClick: () => {
        fileInputRef.current?.removeAttribute("capture");
        fileInputRef.current?.removeAttribute("accept");
        fileInputRef.current?.click();
      },
      text: "Choose Files",
    });
  }

  const itemGroups: ActionSheetItemGroups[] = [
    {
      items: !readOnly ? [...menuElements] : [],
      name: "File upload menu",
    },
  ];

  return (
    <div className="-mt-4">
      <Dropdown
        content={<DropdownActions actions={itemGroups} />}
        placement={placement}
      >
        {target}
      </Dropdown>

      <input
        ref={fileInputRef}
        className="hidden"
        disabled={readOnly}
        id="picture-input"
        multiple={multiple}
        onChange={onChange}
        type="file"
      />
    </div>
  );
};

export default FileUploadMenu;
