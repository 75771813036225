import { Link } from "react-router-dom";

import type { GroupStyle } from "stream-chat-react/dist/components/MessageList/utils";

import type { GlueDefaultStreamChatGenerics } from "@utility-types";
import { routeToThread } from "components/routing/utils";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { formatDateWithTime } from "utils/formatDate";
import env from "utils/processEnv";
import { streamMessageToGlueMessage } from "utils/stream/message";

import QuotedMessage from "components/QuotedMessage/QuotedMessage";

import { MessageAttachments } from "../../MessageAttachments";
import { isOnlyEmojis as isOnlyEmojisFunction } from "../utils";

import { Timestamp } from "./Timestamp";

export const MessageContent = ({
  firstGroupStyle,
  message,
  messageActionMenu,
  messageGlueStatus,
  messageText,
  reactionsList,
}: {
  collapsible?: boolean;
  firstGroupStyle?: GroupStyle;
  message: GlueDefaultStreamChatGenerics["messageType"];
  messageActionMenu?: JSX.Element | null;
  messageGlueStatus?: JSX.Element | null;
  messageText: JSX.Element | null;
  reactionsList?: JSX.Element | null;
}) => {
  const deleted = message.type === "deleted";

  const isOnlyEmojis = isOnlyEmojisFunction(message.text);

  const { recipientID, threadID, threadPane } = useThreadViewState(
    ({ recipientID, threadID, threadPane }) => ({
      recipientID,
      threadID,
      threadPane,
    })
  );

  const messageGlueAttachment: JSX.Element | null = !deleted ? (
    <MessageAttachments
      attachments={message.attachments || []}
      messageId={message.id}
    />
  ) : null;

  return (
    <div className="relative w-full min-w-0">
      <div className="pr-16">
        {firstGroupStyle !== "top" &&
          firstGroupStyle !== "single" &&
          messageActionMenu}

        <div
          className={"str-chat-message-content"}
          data-testid="message-glue-content"
        >
          {message.type !== "deleted" &&
            message.quoted_message &&
            message.quoted_message_id && (
              <div className="my-6">
                <Link
                  className="block"
                  to={() =>
                    routeToThread({
                      messageID: message.quoted_message_id,
                      recipientID,
                      threadID,
                      forceTo: threadPane,
                    })
                  }
                >
                  <QuotedMessage
                    message={streamMessageToGlueMessage(message.quoted_message)}
                  />
                </Link>
              </div>
            )}

          {message.text !== "" && (
            <span
              className={isOnlyEmojis ? "text-3xl" : ""}
              data-testid="message-glue-message"
            >
              {messageText}
            </span>
          )}
          {message.text === "" && messageGlueAttachment}
        </div>

        {message.text !== "" && messageGlueAttachment}

        {message.content_updated_at && message.user?.id !== env.glueAIBotID && (
          <div className={"text-text-subtle mt-2 mb-4 text-xs"}>
            (edited&nbsp;
            <Timestamp
              customClass="text-xs text-text-subtle"
              date={message.content_updated_at}
              formatDate={formatDateWithTime}
            />
            )
          </div>
        )}

        {message.text === "" && reactionsList}
      </div>

      {messageGlueStatus}
    </div>
  );
};
