import { ConnectionStatus } from "@capacitor/network";
import { devtools, subscribeWithSelector } from "zustand/middleware";

import { isNative } from "utils/platform";

import { Message, ThreadEdge } from "@utility-types";
import breakpoints from "utils/breakpoints";
import { glueCreateZustand } from "./helper/glueCreate";

const STORE_NAME = "AppStateStore";

type AppStatus = "unknown" | "active" | "inactive";

type AppNotification = {
  message: Message;
  threadID: string;
  threadEdge: ThreadEdge;
};

type AppStore = {
  activeThreadId?: string;
  activeNotification: AppNotification | undefined;
  appStatus: AppStatus;
  breakpointMD: boolean;
  handleAuthSignIn?: (errorHandler?: () => void) => Promise<void>;
  networkStatus: ConnectionStatus;
  setState: (values: Partial<AppStore>) => void;
};

const useAppStateStore = glueCreateZustand<AppStore>({
  name: STORE_NAME,
})(
  devtools(
    subscribeWithSelector(set => ({
      appStatus: isNative() ? "unknown" : "active",
      activeNotification: undefined,
      breakpointMD: breakpoints().md,
      networkStatus: {
        connected: true,
        connectionType: "unknown",
      },
      setState: values => set(state => ({ ...state, ...values })),
    })),
    { name: STORE_NAME }
  )
);

export default useAppStateStore;
