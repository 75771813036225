import { HTMLProps } from "react";

import { Button } from "components/design-system/Button";

import { CloseButton } from "components/Icons";

type Props = HTMLProps<HTMLDivElement> & {
  children: JSX.Element;
  onRemove: () => void;
};

const DisposableElement = ({
  children,
  onRemove,
  ...props
}: WithChildren<Props>): JSX.Element => (
  <div {...props}>
    <div className="relative z-0">
      <Button
        buttonStyle="none"
        buttonType="none"
        className="absolute -top-5 -right-5 z-1"
        data-testid="disposable-close-btn"
        onClick={() => onRemove()}
        role="button"
      >
        <CloseButton />
      </Button>
      {children}
    </div>
  </div>
);

export default DisposableElement;
