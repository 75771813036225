import { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Recipient } from "@utility-types";
import { Addable } from "generated/graphql";

import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { ToggleButton } from "components/design-system/Button";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";

import PaneHeader from "components/design-system/ui/PaneHeader";
import { useDebouncedCallback } from "use-debounce";
import { DraftForm, State } from "./DraftReducer";
import ThreadSubjectInput from "./ThreadSubjectInput";
import { useSuggestSubject } from "./hooks";
import { minSuggestLen } from "./hooks/useSuggestSubject";

type Props = {
  autoSuggestSubject: boolean;
  compose: State;
  isModal: boolean;
  onAddableChange: (addable: Addable) => void;
  onRecipientsChange: (recipients: Recipient[]) => void;
  onSubjectChange: (subject: string) => void;
  onClose: (() => void) | undefined;
  readOnly: boolean;
};

const ThreadComposeHeader = ({
  autoSuggestSubject,
  compose,
  isModal,
  onAddableChange,
  onRecipientsChange,
  onSubjectChange,
  onClose,
  readOnly,
}: Props) => {
  const { getFieldState, setValue, watch } = useFormContext<DraftForm>();

  // Suggest subject

  const [autoSuggest, setAutoSuggest] = useState(autoSuggestSubject);

  const { suggestSubject, loadingSubject } = useSuggestSubject(compose);

  const suggestAndSetSubject = useCallback(
    () =>
      suggestSubject?.().then(suggestion => {
        if (!suggestion) return;
        setAutoSuggest(true);
        setValue("subject", suggestion, {
          shouldDirty: true,
          shouldTouch: true,
        });
        onSubjectChange(suggestion ?? "");
      }),
    [onSubjectChange, setValue, suggestSubject]
  );

  const prevTextRef = useRef(compose.draftForm.message.text.length);

  const onTextChange = useDebouncedCallback((text: string) => {
    const lengthDiff = Math.abs(text.length - prevTextRef.current);
    if (autoSuggest && lengthDiff >= minSuggestLen) {
      prevTextRef.current = text.length;
      suggestAndSetSubject();
    }
  }, 350);

  // Auto-suggest subject when replying
  useEffect(() => {
    if (compose.draftForm.replyToMessage && !compose.draftID) {
      suggestAndSetSubject();
    }
  }, [compose.draftForm.replyToMessage, compose.draftID, suggestAndSetSubject]);

  // Watch form values and call callbacks
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type !== "change") return;
      switch (name) {
        case "recipients":
          onRecipientsChange(values.recipients as Recipient[]);
          break;
        case "subject":
          if (getFieldState(name).isDirty) {
            setAutoSuggest(false);
          }

          onSubjectChange(values.subject ?? "");
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [getFieldState, onRecipientsChange, onSubjectChange, watch]);

  const dirtyRef = useRef(compose.dirty);
  const onTextChangeRef = useRef(onTextChange);
  dirtyRef.current = compose.dirty;
  onTextChangeRef.current = onTextChange;

  useEffect(() => {
    if (!dirtyRef.current) return;
    onTextChangeRef.current(compose.draftForm.message.text);
  }, [compose.draftForm.message.text]);

  useEffect(() => {
    if (compose.draftID || compose.pending) {
      onTextChangeRef.current.cancel();
    }
  }, [compose.draftID, compose.pending]);

  // Render

  const addableNone = compose.draftForm?.recipientsAddable === Addable.None;
  const isGlueAI = isGlueAIRecipient(compose.draftForm.recipients);
  const suggestEnabled = !!suggestSubject;

  return (
    <PaneHeader
      onClose={onClose}
      padding={isModal ? "thread-compose-modal" : "thread-compose"}
    >
      <div className="flex flex-row items-start grow min-w-0">
        {!isModal && <BackStackButton />}
        <div className="flex grow min-w-0">
          <div className="flex flex-col grow min-w-0 md:gap-4">
            <ThreadSubjectInput
              wrapperClassName="mr-28"
              readOnly={readOnly}
              isGlueAI={isGlueAI}
              loadingSubject={loadingSubject}
              autoSuggest={autoSuggest}
              suggestEnabled={suggestEnabled}
              suggestAndSetSubject={suggestAndSetSubject}
            />
            <div className="flex items-end mb-8 md:mb-4">
              <RecipientsSelect<DraftForm>
                autoFocus={false}
                borderWidth={0}
                className="z-2 w-full border-none"
                disabled={readOnly}
                filterMe={true}
                maxLines={3}
                name="recipients"
                placeholder="To groups, users, or emails..."
                wrapperClassName="!my-0 grow min-w-0"
              />
              <ToggleButton
                buttonStyle="none"
                className="!p-8 ml-8 mb-2 mr-2"
                disabledState={{
                  icon: "Unlock",
                  iconClassName: "text-icon-subtle",
                  title: "Lock recipient list",
                }}
                enabledState={{
                  icon: "Lock",
                  iconClassName: "text-accent-badge",
                  title: "Unlock recipient list",
                }}
                iconSize={18}
                onClick={() =>
                  onAddableChange(addableNone ? Addable.Anyone : Addable.None)
                }
                toggled={addableNone}
              />
            </div>
          </div>
        </div>
      </div>
    </PaneHeader>
  );
};

export default ThreadComposeHeader;
