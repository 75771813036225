import { useHistory } from "react-router-dom";

import { ModalProps } from "components/ModalKit/Modal";
import {
  AuthConfigDocument,
  CreateWorkspaceInput,
  WorkspacesAndGroupsListDocument,
  useCreateWorkspaceMutation,
} from "generated/graphql";
import useModalStore from "store/useModalStore";

import { routeToGroup } from "components/routing/utils";

import WorkspaceModalForm from "./WorkspaceModalForm";

const WorkspaceModalCreate = (props: ModalProps): JSX.Element => {
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));
  const history = useHistory();

  const [createWorkspace] = useCreateWorkspaceMutation({
    refetchQueries: [AuthConfigDocument, WorkspacesAndGroupsListDocument],
  });

  const createNewWorkspace = (_: string, input: CreateWorkspaceInput) =>
    createWorkspace({ variables: { input: input } }).then(({ data }) => {
      if (data?.createWorkspace.id) {
        history.push(routeToGroup({ groupID: data.createWorkspace.id }));

        closeModal(`${props.modalId}`);
      }
    });

  return (
    <WorkspaceModalForm
      onSave={createNewWorkspace}
      title="Create a workspace"
      {...props}
    />
  );
};

export default WorkspaceModalCreate;
