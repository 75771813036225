import { useEffect, useRef } from "react";

import { useFormContext } from "react-hook-form";

import { GroupEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Form, RadioGroup } from "components/design-system/Forms";
import { InformationBubble } from "components/design-system/InformationBubble";
import { ThreadSubscription } from "generated/graphql";
import useModalStore from "store/useModalStore";

import { PreferencesModal } from "components/user";

import useGroupActions from "../GroupProfileBar/hooks/useGroupActions";

type FormData = {
  threadSubscription: ThreadSubscription;
};

type Props = {
  groupEdge: GroupEdge;
};

const GroupNotificationsFormFields = ({
  onSubmit,
}: {
  onSubmit: (data: FormData) => Promise<void | undefined>;
}) => {
  const onSubmitRef = useRef(onSubmit);
  onSubmitRef.current = onSubmit;

  const { watch } = useFormContext<FormData>();

  const threadSubscription = watch("threadSubscription");

  useEffect(() => {
    onSubmitRef.current({ threadSubscription });
  }, [threadSubscription]);

  return (
    <div className="flex flex-col mt-4">
      <div className="flex items-center">
        <span className="font-semibold text-base text-text-primary leading-5 select-none">
          Follow in Inbox
        </span>
      </div>
      <div>
        <RadioGroup<FormData>
          groupClassName="w-full !mb-0 !mt-8"
          name="threadSubscription"
          optionLabelClassName="items-center"
          options={[
            {
              label: (
                <div className="flex items-center justify-between">
                  <div>
                    <p className="m-0 font-semibold text-base text-text-primary leading-5">
                      Important threads
                    </p>
                    <p className="m-0 text-sm leading-[18px] text-text-subtle">
                      When you are @mentioned, reply to a thread or follow a
                      thread.
                    </p>
                  </div>
                  <div className="flex shrink-0 items-center text-xs font-semibold px-4 py-2 mx-8 text-text-inverse bg-background-action rounded-sm">
                    Recommended
                  </div>
                </div>
              ),
              value: ThreadSubscription.Activity,
            },
            {
              label: (
                <div>
                  <p className="m-0 font-semibold text-base text-text-primary leading-5">
                    Everything
                  </p>
                  <p className="m-0 text-sm leading-[18px] text-text-subtle">
                    See every thread in Inbox unless you unfollow.
                  </p>
                </div>
              ),
              value: ThreadSubscription.Inbox,
            },
          ]}
          wrapperClassName="py-8 pl-12 pr-16 border border-border-container rounded-lg hover:border-background-action-secondary cursor-pointer"
          wrapperSelectedClassname="!border-border-action"
        />
      </div>
    </div>
  );
};

const GroupNotificationsSettings = ({ groupEdge }: Props) => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { updateGroupEdge } = useGroupActions(groupEdge?.node);

  const handleSubmit = async (data: FormData) => updateGroupEdge(data);

  const handlePreferences = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    openModal(<PreferencesModal />);
  };

  // We're removing the "Activity" option, as it will be the default
  // but in the mean time we have to treat them equivalently
  let threadSubscription = groupEdge?.threadSubscription;
  if (threadSubscription === ThreadSubscription.Archive) {
    threadSubscription = ThreadSubscription.Activity;
  }

  return (
    <Form<FormData>
      onSubmit={handleSubmit}
      useFormProps={{ defaultValues: { threadSubscription } }}
    >
      <div className="pt-12 flex flex-col">
        <GroupNotificationsFormFields onSubmit={handleSubmit} />
        <div className="mt-32">
          <InformationBubble>
            Individual thread settings can override these group settings.
            <br />
            You can change your device notification settings in{" "}
            <Button
              buttonStyle="none"
              buttonType="none"
              className="text-text-link hover:text-text-link-hover text-sm leading-[18px] hover:underline !inline"
              onClick={handlePreferences}
            >
              Preferences
            </Button>
          </InformationBubble>
        </div>
      </div>
    </Form>
  );
};

export default GroupNotificationsSettings;
