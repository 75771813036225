import {
  ComponentProps,
  FunctionComponent,
  cloneElement,
  useState,
} from "react";

import { X } from "react-feather";

import BaseSnackbar from "./BaseSnackbar";
import NetworkErrorSnackbar from "./custom/NetworkErrorSnackbar";
import ServerErrorSnackbar from "./custom/ServerErrorSnackbar";

type Props = {
  content?: string | FunctionComponent | JSX.Element | null | undefined;
  onClose?: () => void;
  show?: boolean;
  type: ComponentProps<typeof BaseSnackbar>["type"];
};

const SnackBar = ({
  content,
  onClose,
  show,
  type,
}: Props): JSX.Element | null => {
  const [isShowing, setIsShowing] = useState(show);

  const renderContent = () => {
    if (content) {
      if (typeof content === "string") {
        return <p>{content}</p>;
      }
      if (typeof content === "function") {
        return content({});
      }
      return cloneElement(content, {});
    }
    switch (type) {
      case "connectivity":
        return <NetworkErrorSnackbar />;
      case "server":
        return <ServerErrorSnackbar />;
      default:
        return null;
    }
  };

  return show || isShowing ? (
    <BaseSnackbar
      containerClassName={`${show ? "fade-in" : "fade-out"}`}
      onAnimationEnd={() => setIsShowing(show)}
      type={type}
    >
      <div className="flex items-center w-full">{renderContent()}</div>
      <X
        className="close-icon"
        data-testid="snackbar-remove-button"
        onClick={onClose}
        size={25}
      />
    </BaseSnackbar>
  ) : null;
};

export default SnackBar;
