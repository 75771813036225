import { Icon } from "react-feather";

import { Recipient, RecipientType, Sizes } from "@utility-types";

import { parseItemData } from "./parseItemData";

import useRecipientDomains from "hooks/workspace/useRecipientDomains";
import ProfileItem from "./ProfileItem";

type Size = Exclude<Sizes, "tiny" | "small">;

type Props = {
  className?: string;
  isStarred?: boolean;
  onClick?: (type: RecipientType, id: string) => void;
  profileIcon?: Icon | null;
  recipient?: Recipient;
  role?: "admin" | "starter";
  size?: Size;
};

const RecipientProfileItem = ({
  className = "",
  isStarred,
  onClick,
  profileIcon,
  recipient,
  role,
  size = "medium",
}: Props): JSX.Element | null => {
  const { domains, workspace } = useRecipientDomains(recipient);

  return (
    <ProfileItem
      {...{
        ...parseItemData(recipient),
        className,
        description: recipient?.bio || workspace?.name,
        domains,
        isStarred,
        nameLabel: role,
        onClick,
        profileIcon,
        size,
      }}
    />
  );
};

export default RecipientProfileItem;
