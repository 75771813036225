import { useApolloClient } from "@apollo/client";

import { ModalProps } from "components/ModalKit/Modal";
import {
  CreateGroupInput,
  FetchWorkspaceOrPreviewEdgeDocument,
  PersistentChatsDocument,
  WorkspacesAndGroupsListDocument,
  useCreateGroupMutation,
} from "generated/graphql";
import useModalStore from "store/useModalStore";
import filterActiveQueries from "utils/filterActiveQueries";

import GroupModalForm from "./GroupModalForm";

type Props = {
  workspaceID?: string;
} & ModalProps;

const GroupModalCreate = ({ workspaceID, ...props }: Props): JSX.Element => {
  const apolloClient = useApolloClient();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const [createGroup] = useCreateGroupMutation({
    refetchQueries: [WorkspacesAndGroupsListDocument],
  });

  const createNewGroup = (_: string, input: CreateGroupInput) =>
    createGroup({
      refetchQueries: filterActiveQueries(apolloClient, [
        FetchWorkspaceOrPreviewEdgeDocument,
        PersistentChatsDocument,
        WorkspacesAndGroupsListDocument,
      ]),
      variables: { input: input },
    }).then(({ data }) => {
      if (data?.createGroup.id) {
        closeModal(`${props.modalId}`);
      }
    });

  return (
    <GroupModalForm
      groupWorkspaceID={workspaceID}
      onSave={createNewGroup}
      title="Create a group"
      isAdmin
      {...props}
    />
  );
};

export default GroupModalCreate;
