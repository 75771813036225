import { format } from "date-fns";
import {
  SubscriptionInterval,
  SubscriptionPlan,
  WorkspaceSubscriptionFieldsFragment,
} from "generated/graphql";

const numberFormatter = new Intl.NumberFormat("en-US");

export const formatAmount = (amount: number, showDecimals?: boolean) => {
  const formattedAmount = numberFormatter.format(amount / 100);
  return `$${formattedAmount}${showDecimals && !formattedAmount.includes(".") ? ".00" : ""}`;
};

export const subscriptionTotal = (
  subscription?: WorkspaceSubscriptionFieldsFragment,
  showDecimals?: boolean
) => {
  if (!subscription) return `$0${showDecimals ? ".00" : ""}`;
  return formatAmount(
    (subscription?.quantity ?? 0) * (subscription?.plan?.amount ?? 0),
    showDecimals
  );
};

export const planDate = (date?: string | null) =>
  date ? `${format(new Date(date), "MMMM d, yyyy")}` : undefined;

export const planDisplayName = (plan: SubscriptionPlan | null) => {
  if (!plan) return null;

  switch (plan?.interval) {
    case SubscriptionInterval.Month:
      return `${plan.name} ($${Math.ceil((plan.amount / 100) * 100) / 100}/user/month)`;

    case SubscriptionInterval.Year:
      return `${plan.name} ($${Math.ceil((plan.amount / 12 / 100) * 100) / 100}/user/month)`;

    default:
      return `${plan.name}`;
  }
};
