import { Dispatch, SetStateAction } from "react";

import { useRemirrorContext } from "@remirror/react";
import { XCircle } from "react-feather";

import { Button } from "components/design-system/Button";
import tw from "utils/tw";

import { TextFormat } from "components/Icons";

import buttonStyles from "../../buttonStyles";
type Props = {
  setToggleFormatBar: Dispatch<SetStateAction<boolean>>;
  show: boolean;
};

export default function FormattingBarToggleButton({
  setToggleFormatBar,
  show,
}: Props) {
  const { view } = useRemirrorContext();

  return (
    <div className="flex relative z-1 pl-8 w-40 h-30 bg-background-body">
      <Button
        buttonStyle="subtle"
        className={tw(buttonStyles(), "!mr-0")}
        icon={show ? XCircle : TextFormat}
        iconSize={20}
        onClick={() => {
          setToggleFormatBar(!show);
          view.focus();
        }}
      />
    </div>
  );
}
