import { matchURL } from "./matchURL";
import env from "./processEnv";

const { origin: apiOrigin, pathname: apiPath } = new URL(env.glueApiUrl);

const localOriginSuffixes = [
  ":8080",
  ":8443",
  "ngrok.io",
  "tailf1b4c.ts.net",
] as const;

const isLocalOrigin = (origin: string) =>
  localOriginSuffixes.find(suffix => origin.endsWith(suffix));

const proxiedUrlRegex = new RegExp(
  `^(?:.+?(?:${localOriginSuffixes
    .map(s => s.replace(".", "\\."))
    .join("|")})/proxy/)(.+?)(?:/(image|video))?$`
);

// Allow changing between API origins without breaking assets
const fixDevApiOrigin = (url: string) => {
  try {
    const { origin: assetOrigin, pathname: assetPath } = new URL(url);
    if (!isLocalOrigin(assetOrigin)) return url;
    if (assetOrigin !== apiOrigin || !assetPath.startsWith(apiPath)) {
      url = url.replace(
        assetOrigin,
        apiOrigin + (apiPath !== "/" ? apiPath : "")
      );
    }
  } catch {
    return url;
  }

  return url;
};

export default function fixDevApiUrl(url: string): string {
  if (env.glueEnv !== "development" || !matchURL(url)) {
    return url;
  }

  if (url.startsWith("blob:")) return url;

  if (url.startsWith("//")) url = `https:${url}`;

  const [_, proxiedUrl, type = "image"] = url.match(proxiedUrlRegex) || [];

  if (proxiedUrl) {
    const fixedProxiedUrl = fixDevApiOrigin(decodeURIComponent(proxiedUrl));
    return fixedProxiedUrl.includes(env.glueApiUrl)
      ? fixedProxiedUrl
      : `${env.glueApiUrl}/proxy/${encodeURIComponent(
          fixedProxiedUrl
        )}/${type}`;
  }

  return fixDevApiOrigin(url);
}
