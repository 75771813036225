import { memo } from "react";
import { useHistory } from "react-router-dom";

import { Clipboard } from "@capacitor/clipboard";
import tw from "utils/tw";

import { Recipient, ThreadEdge, ThreadPreviewEdge } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";
import ThreadInfoModal from "components/thread/ThreadView/components/ThreadInfoModal";
import { isSubscribed } from "components/threads-list/ThreadItem/utils";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useMemberEdge from "hooks/useMemberEdge";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";
import threadChatType from "utils/thread/threadChatType";

import { routeToGroup, routeToUser, routeURL } from "components/routing/utils";
import useChatRecipient from "hooks/useChatRecipient";

type Props = {
  threadEdge: ThreadEdge | ThreadPreviewEdge | undefined;
  additionalActions?: ActionSheetItemGroups["items"];
  breakpointMD?: boolean;
  loading?: boolean;
};

type PropsMemo = Props & {
  threadActions: ReturnType<typeof useInboxThreadActions>;
  starter: Recipient | undefined;
};

const ThreadActionMenu = ({
  threadEdge,
  ...rest
}: Props): JSX.Element | null => {
  const threadActions = useInboxThreadActions();
  const starter = threadEdge?.node.recipients.edges[0]?.node;

  return (
    <MemoizedThreadActionMenu
      {...{
        threadActions,
        starter,
        threadEdge,
        ...rest,
      }}
    />
  );
};

const MemoizedThreadActionMenu = memo(
  ({
    additionalActions,
    threadActions,
    starter,
    threadEdge,
    breakpointMD,
    loading,
  }: PropsMemo): JSX.Element => {
    const history = useHistory();
    const { openModal } = useModalStore(({ openModal }) => ({
      openModal,
    }));
    const { openSnackbar } = useSnackbar();

    const { memberEdge } = useMemberEdge(threadEdge);

    const chatType = threadChatType(memberEdge?.node);
    const recipient = useChatRecipient()(memberEdge?.node);

    const dropdownActions = ((): ActionSheetItemGroups[] => {
      if (!threadEdge) return [];

      const actions: ActionSheetItemGroups[] = [];

      const isPersistentChat =
        "isPersistentChat" in threadEdge.node &&
        threadEdge.node.isPersistentChat;

      const noun = isPersistentChat ? "chat" : "thread";

      const isMember = !!memberEdge;

      if (!isPersistentChat) {
        actions.push({
          items: [
            {
              className: "",
              icon: isMember ? "Settings" : "Info",
              iconClassName: "",
              onClick: () =>
                openModal(<ThreadInfoModal threadID={threadEdge?.node.id} />),
              text: isMember ? "Thread settings" : "Thread info",
            },
          ],
          name: "threadInfo",
        });
      }

      if (threadEdge.__typename === "ThreadEdge") {
        if (isPersistentChat) {
          actions.unshift({
            items: [
              {
                className: "",
                icon: chatType === "user" ? "User" : "ArrowRightCircle",
                iconClassName: "",
                onClick: () => {
                  if (!starter) return;

                  switch (starter?.__typename) {
                    case "WorkspacePreview":
                    case "GroupPreview":
                      history.push(
                        routeToGroup({ groupID: starter.id, to: "canonical" })
                      );
                      break;
                    case "User":
                      if (!recipient?.id) return;
                      history.push(
                        routeToUser({ to: "secondary", userID: recipient.id })
                      );
                      break;
                  }
                },
                text: chatType === "user" ? "View profile" : "Go to group",
              },
            ],
            name: "goTo",
          });
        }

        const memberActions: ActionSheetItemGroups["items"] = [
          ...(additionalActions ?? []),
          ...(!breakpointMD && !chatType
            ? [
                {
                  className: !isSubscribed(threadEdge) ? "unmute" : "",
                  icon: !isSubscribed(threadEdge)
                    ? ("Bell" as const)
                    : ("BellSmallFilled" as const),
                  iconClassName: "",
                  iconSize: !isSubscribed(threadEdge) ? 20 : 22,
                  onClick: () =>
                    threadActions.toggleThreadSubscribed(threadEdge),
                  text: !isSubscribed(threadEdge)
                    ? `Follow ${noun}`
                    : `Unfollow ${noun}`,
                },
              ]
            : []),
          ...(!chatType
            ? [
                {
                  className: threadEdge?.isArchived ? "is-archived" : "",
                  icon: threadEdge?.isArchived
                    ? ("Inbox" as const)
                    : ("Check" as const),
                  onClick: () => {
                    threadActions.toggleThreadArchived(threadEdge);
                  },
                  text: threadEdge?.isArchived
                    ? "Unarchive"
                    : `Archive ${noun}`,
                },
              ]
            : []),
          {
            className: threadEdge?.isStarred ? "unstar" : "",
            icon: "Star",
            onClick: () => threadActions.toggleThreadStarred(threadEdge),
            text: `${threadEdge?.isStarred ? "Unstar" : "Star"} ${noun}`,
          },
          {
            icon: threadEdge?.isRead ? "Unread" : "Mail",
            onClick: () => threadActions.toggleThreadRead(threadEdge),
            text: threadEdge?.isRead ? "Mark unread" : "Mark read",
          },
          ...(!threadEdge?.node.isPersistentChat
            ? [
                {
                  icon: "ExternalLink" as const,
                  onClick: () => {
                    Clipboard.write({
                      url: routeURL({ recipientID: threadEdge.node.id }),
                    }).then(() =>
                      openSnackbar(
                        "info",
                        "Share link copied to clipboard.",
                        5000
                      )
                    );
                  },
                  text: "Copy share link",
                },
              ]
            : []),
        ];

        actions.unshift({
          items: memberActions,
          name: "members",
        });
      }

      return actions;
    })();

    const disabled = !threadEdge;

    return (
      <Dropdown
        content={<DropdownActions actions={dropdownActions} />}
        disableFlip
      >
        <Button
          buttonStyle="subtle"
          disabled={disabled}
          className={tw(
            "size-28 justify-center rounded-md !border-border-container",
            { "hover:bg-background-secondary": !disabled }
          )}
          icon={loading ? "LoaderCircle" : "MenuVertical"}
          iconClassName={tw({ "animate-spin": loading })}
          iconSize={20}
          type="button"
        />
      </Dropdown>
    );
  },
  (prev, next) => {
    const memoThreadEdge = () => {
      if (
        prev.threadEdge?.__typename === "ThreadEdge" &&
        next.threadEdge?.__typename === "ThreadEdge"
      ) {
        return (
          isSubscribed(prev.threadEdge) === isSubscribed(next.threadEdge) &&
          prev.threadEdge?.id === next.threadEdge?.id &&
          prev.threadEdge?.isArchived === next.threadEdge?.isArchived &&
          prev.threadEdge?.isRead === next.threadEdge?.isRead &&
          prev.threadEdge?.isSeen === next.threadEdge?.isSeen &&
          prev.threadEdge?.isStarred === next.threadEdge?.isStarred &&
          prev.breakpointMD === next.breakpointMD &&
          prev.loading === next.loading
        );
      }
      return true;
    };

    return prev.threadEdge?.id === next.threadEdge?.id && memoThreadEdge();
  }
);

export default ThreadActionMenu;
