import { AttachmentType, getAttachmentIcon } from "./utils";

type Props = {
  attachmentTypes: AttachmentType[];
};

const ThreadItemContentAttachment = ({
  attachmentTypes: types,
}: Props): JSX.Element => {
  const Icon = getAttachmentIcon(types);
  return <Icon className="text-interactive-subtle" size="15" />;
};

export default ThreadItemContentAttachment;
