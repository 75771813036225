import Icon from "components/design-system/icons/Icon";
import { ComponentProps } from "react";
import tw from "utils/tw";

const InformationBubble = ({
  children,
  className,
  iconProps: iconPropsProp,
}: WithChildren & {
  className?: string;
  iconProps?: Partial<ComponentProps<typeof Icon>>;
}) => {
  const iconProps = {
    className: "text-icon-action",
    icon: "Info" as const,
    size: 20,
    ...iconPropsProp,
  };
  return (
    <div
      className={tw(
        "flex items-center bg-background-secondary px-16 py-12 rounded-md select-none",
        className
      )}
    >
      <Icon {...iconProps} />
      <div className="ml-8 text-footnote">{children}</div>
    </div>
  );
};

export default InformationBubble;
