import { Link } from "react-feather";

import { ExternalObject } from "@utility-types";
import getRandomInt from "utils/getRandomInt";
import { matchURL } from "utils/matchURL";
import { trimUrl } from "utils/trimUrl";

import { Image as ImageElement } from "components/Media";
import { Hyperlink } from "components/MessageElements";
import { Skeleton } from "components/Skeleton";

type Props = {
  item: ExternalObject & { state: "loading" | "failed" | "finished" };
};

const LinkPreview = ({ item }: Props): JSX.Element => {
  const styles =
    item.state === "failed"
      ? { border: "border-accent-error", text: "text-accent-error" }
      : { border: "border-background-subtle", text: "text-text-strong" };

  const image = item.image?.[0];

  const imageUrl = matchURL(image?.url || "");

  return (
    <div
      className={`relative mt-5 border select-none rounded-md h-80 ${styles.border}`}
    >
      <div className="flex flex-row items-center h-full">
        {(item.state === "loading" || item.state === "failed" || imageUrl) && (
          <ImageElement
            alt={""}
            /* istanbul ignore next */
            blurHash={image?.blurHash}
            className="object-cover overflow-hidden w-full h-full rounded-l-md"
            figureClassName="h-full w-80 flex-shrink-0"
            resize={{ fit: "max", h: 80 }}
            src={imageUrl}
          />
        )}
        <div className="flex flex-col py-4 px-15 w-full min-w-0">
          <Hyperlink
            className="group/link-preview !text-text-subtle hover:no-underline"
            url={item.url}
          >
            <div
              className={`overflow-hidden text-base font-semibold line-clamp-2 group-hover/link-preview:underline ${styles.text}`}
            >
              {item.state === "loading" ? (
                <Skeleton height="18px" width={`${getRandomInt(50, 90)}%`} />
              ) : item.state === "failed" ? (
                "Unable to load preview"
              ) : (
                item.title
              )}
            </div>
            <div className="flex items-center mt-2 text-interactive-subtle">
              <Link className="shrink-0 mt-1" width="12" />
              <span className="ml-6 min-w-0 text-sm leading-4 truncate">
                {trimUrl(item.url)}
              </span>
            </div>
          </Hyperlink>
        </div>
      </div>
    </div>
  );
};

export default LinkPreview;
