import formatRecipientNames, {
  RecipientEnvelope,
} from "utils/thread/formatRecipientNames";

const RecipientsLineSimple = (recipientEnvelope: RecipientEnvelope) => {
  const recipientsNames = formatRecipientNames(recipientEnvelope)?.map(r => {
    const name = r.name.replace(/ /g, "\u00a0");
    if (r.isGroup) {
      return r.emoji ? `${r.emoji}\u00a0${name}` : name;
    }
    return name;
  });

  return (
    <>
      <span className="truncate">{recipientsNames[0]}</span>
      {recipientsNames.length > 1 && (
        <span className="shrink-0">{`, +${recipientsNames.length - 1}`}</span>
      )}
    </>
  );
};

export default RecipientsLineSimple;
