import { StreamImageAttachment } from "@utility-types";
import glueImageURL from "utils/glueImageURL";

import { Image as ImageElement } from "components/Media";
import { PhotoSwipeItem } from "components/PhotoSwipe";

const PhotoSwipeImageWrapper = ({
  alt,
  blurHash,
  className,
  figureClassName,
  height,
  image,
  resize,
  src,
  width,
}: {
  alt: string;
  blurHash: string | undefined;
  className: string;
  figureClassName: string;
  height: number;
  image: StreamImageAttachment;
  resize: Parameters<typeof glueImageURL>[1];
  src: string;
  width: number;
}): JSX.Element => {
  const {
    fallback,
    file_id: fileId,
    image_height: imageHeight = 768,
    image_url: imageURL,
    image_width: imageWidth = 768,
  } = image;

  const maxWidth = Math.min(imageWidth, window.innerWidth);
  const maxHeight = Math.min(imageHeight, window.innerHeight);

  const ratio = imageWidth / imageHeight;
  const isLandScape = ratio >= 1;

  const fullSizeResize = {
    fit: "max" as const,
    h: isLandScape ? Math.round(maxWidth / ratio) : maxHeight,
  };

  return (
    <PhotoSwipeItem
      key={fileId}
      fullSizeURL={glueImageURL(imageURL, fullSizeResize, 2)}
      height={imageHeight}
      id={fileId}
      originalURL={imageURL}
      thumbnail={glueImageURL(imageURL, resize, 2)}
      title={fallback}
      width={imageWidth}
    >
      {({ bindLongPress, open, ref }) => (
        <ImageElement
          ref={ref}
          alt={alt}
          blurHash={blurHash}
          className={className}
          height={height}
          {...bindLongPress}
          figureClassName={figureClassName}
          onClick={open}
          resize={resize}
          src={src}
          width={width}
        />
      )}
    </PhotoSwipeItem>
  );
};

export default PhotoSwipeImageWrapper;
