import {
  VariantPropsOf,
  variantProps,
} from "components/helper/classNameVariants";
import React, { forwardRef } from "react";

import { Button } from "components/design-system/Button";
import tw from "utils/tw";

const className = {
  base: "border-b border-background-subtle flex flex-col items-start shrink-0 w-full min-h-[73px] relative",
  defaultVariants: {
    padding: "default" as const,
  },
  variants: {
    padding: {
      default: "px-16 py-10",
      none: "",
      "thread-compose": "pt-10 pr-8 pl-16 md:pt-16 md:pb-8",
      "thread-compose-modal": "pt-16 pr-8 pl-12 md:pl-16",
    },
  },
};

const componentProps = variantProps(className);
type ComponentVariantProps = VariantPropsOf<typeof componentProps>;

type Props = WithChildren &
  ComponentVariantProps & {
    onClose?: () => void;
    trailingContent?: React.ReactNode;
  };

const PaneHeader = forwardRef<HTMLDivElement, Props>(
  ({ children, onClose, padding, trailingContent }, ref) => {
    return (
      <div
        ref={ref}
        {...componentProps({
          padding,
        })}
      >
        <div className="flex items-center justify-start w-full">
          {!!onClose && (
            <Button
              buttonStyle="subtle"
              buttonType="text"
              className="mr-12 p-8"
              icon="Close"
              iconSize={20}
              iconStroke={2}
              onClick={e => {
                e.stopPropagation();
                onClose?.();
              }}
            />
          )}

          <div className="relative flex items-center grow min-w-0 h-full">
            {children}
          </div>
        </div>

        {trailingContent && (
          <div className={tw("w-full", { "pl-48": !!onClose })}>
            {trailingContent}
          </div>
        )}
      </div>
    );
  }
);

export default PaneHeader;
