import { ObservableQuery } from "@apollo/client";
import { useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { nodeAs } from "@utility-types";
import { MembersList } from "components/MembersList";
import { MembersListHeader } from "components/MembersList/MembersListHeader";
import { MembersListItem } from "components/MembersList/MembersListItem";
import { PendingMembersListItem } from "components/MembersList/PendingMembersListItem";
import { ConfirmationModal } from "components/Modals";
import { Button } from "components/design-system/Button";
import ExternalBadge from "components/design-system/ui/ExternalBadge";
import AddMembersModal from "components/workspace-group/AddMembersModal";
import {
  FetchGroupOrPreviewEdgeQuery,
  GroupFieldsFragment,
  MemberRole,
  useAddGroupMembersMutation,
  useApproveJoinApprovalMutation,
  useCancelRequestJoinGroupMutation,
  useRemoveGroupMembersMutation,
} from "generated/graphql";
import useFetchMorePendingApprovals from "hooks/pendingApprovals/useFetchMorePendingApprovals";
import useAuthData from "hooks/useAuthData";
import useModalStore from "store/useModalStore";

type Props = {
  group: GroupFieldsFragment;
  fetchMore: ObservableQuery<FetchGroupOrPreviewEdgeQuery>["fetchMore"];
  hasError: boolean;
  isLoading: boolean;
  isAdmin: boolean;
};

const GroupMembers = ({
  group,
  isAdmin,
  hasError,
  fetchMore,
  isLoading,
}: Props) => {
  const { authData } = useAuthData();
  const [editingMemberId, setEditingMemberId] = useState<string | null>(null);
  const { members, pendingApprovals } = group;
  const [approveJoinApproval] = useApproveJoinApprovalMutation({
    refetchQueries: ["FetchGroupOrPreviewEdge"],
  });

  const [cancelRequestJoinGroup] = useCancelRequestJoinGroupMutation({
    refetchQueries: ["FetchGroupOrPreviewEdge"],
  });
  const [removeGroupMembers] = useRemoveGroupMembersMutation();
  const [updateGroupMembers] = useAddGroupMembersMutation();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const [loadMoreRef] = useInfiniteScroll({
    disabled: hasError,
    hasNextPage: members.pageInfo.hasNextPage,
    loading: isLoading,
    onLoadMore: () => {
      fetchMore({
        variables: { membersAfter: members.pageInfo.endCursor },
      }).catch(err => {
        console.warn("Error: [GroupMembers] - ", err.message);
      });
    },
    rootMargin: "0px 0px 400px 0px",
  });

  useFetchMorePendingApprovals({
    fetchMore,
    pendingApprovals,
  });

  const handleAddMember = () => {
    openModal(
      <AddMembersModal
        currentMembers={members.edges.map(m => m.node)}
        groupOrWorkspace={group}
      />
    );
  };

  const handleUpdateRole = ({ id, role }: { id: string; role: MemberRole }) =>
    updateGroupMembers({
      variables: {
        id: group.id,
        members: [{ member: id, role }],
      },
    });

  const handleDelete = (memberId: string) => {
    const member = members.edges.find(m => m.node.id === memberId);

    if (!member) {
      return;
    }

    openModal(
      <ConfirmationModal
        confirmLabel="Remove user"
        header={`Remove ${member.node.name} from the group?`}
        isDestructive
        onConfirm={() =>
          removeGroupMembers({
            variables: { id: group.id, memberIDs: [member.node.id] },
          })
        }
      />
    );
  };

  const handleApprovePendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await approveJoinApproval({ variables: { joinApprovalID: approvalId } });
  };

  const handleCancelPendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await cancelRequestJoinGroup({ variables: { id: approvalId } });
  };

  const sortedMembers = [...members.edges].sort((a, b) => {
    if (
      a.memberRole === MemberRole.Admin &&
      b.memberRole !== MemberRole.Admin
    ) {
      return -1;
    }

    if (
      a.memberRole !== MemberRole.Admin &&
      b.memberRole === MemberRole.Admin
    ) {
      return 1;
    }

    return a.node.name.localeCompare(b.node.name);
  });

  return (
    <div className="native:pb-safe-area py-16">
      <MembersListHeader
        className="mb-16"
        addNewMemberButton={
          isAdmin && (
            <Button
              icon="Plus"
              buttonStyle="simplePrimary"
              buttonType="text"
              onClick={handleAddMember}
            >
              Add new member
            </Button>
          )
        }
      >
        {members.totalCount} members
        {pendingApprovals.totalCount > 0 &&
          `, ${pendingApprovals.totalCount} pending`}
      </MembersListHeader>

      <MembersList>
        {pendingApprovals.edges.map(({ node: approval }) => {
          const joining = nodeAs(approval.joining, ["User"]);
          return (
            joining && (
              <PendingMembersListItem
                key={approval.id}
                approvalID={approval.id}
                isAdmin={isAdmin}
                isByMe={approval.requester.id === authData?.me.id}
                isForMe={approval.joining.id === authData?.me.id}
                isInvite={approval.requester.id === approval.admin?.id}
                joining={joining}
                requestedAt={new Date(approval.requestedAt)}
                onApprove={handleApprovePendingApproval}
                onCancel={handleCancelPendingApproval}
              />
            )
          );
        })}

        {sortedMembers.map(member => (
          <MembersListItem
            key={member.node.id}
            member={{
              ...member.node,
              role: member.memberRole,
              badge: <ExternalBadge recipients={[member.node]} />,
            }}
            onUpdateRole={handleUpdateRole}
            onDelete={handleDelete}
            isEditing={editingMemberId === member.node.id}
            setIsEditing={(state: boolean) =>
              setEditingMemberId(state ? member.node.id : null)
            }
            canMessage={authData?.me.id !== member.node.id}
            canEdit={authData?.me.id !== member.node.id && isAdmin}
          />
        ))}

        {members.pageInfo.hasNextPage && (
          <li ref={loadMoreRef} className="text-text-subtle">
            Loading…
          </li>
        )}
      </MembersList>
    </div>
  );
};

export default GroupMembers;
