import Avatar from "components/design-system/Avatar/Avatar";
import { Button } from "components/design-system/Button";
import { ComponentProps } from "react";
import { Check } from "react-feather";
import useAppStateStore from "store/useAppStateStore";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";

type ActionSheetItemProps = ComponentProps<typeof ActionSheetItem>;

export type ActionSheetItemGroups = {
  items: ActionSheetItemProps[];
  name: string;
};

const ActionSheetItem = ({
  avatarName,
  avatarURL,
  className = "",
  hidden,
  iconClassName = "",
  iconSize,
  selected,
  text,
  ...rest
}: ComponentProps<typeof Button> & {
  avatarName?: string | null;
  avatarURL?: string | null;
  hidden?: boolean;
  selected?: boolean;
  text: string;
}): JSX.Element | null => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const defaultIconSize = breakpointMD ? 20 : 24;

  if (hidden) return null;

  return (
    <Button
      {...rest}
      buttonFont="normal"
      buttonStyle="none"
      className={tw(
        "group/action-sheet-item action-sheet-item",
        "flex items-center justify-start",
        "!px-16 !py-6 relative rounded-none",
        "h-40 md:h-32",
        "text-text-primary",
        "text-subhead",
        "hover:bg-accent-highlight/25 active:bg-accent-highlight/25 mouse:active:bg-accent-highlight/40",
        className
      )}
      iconClassName={tw(
        "mr-12",
        {
          "text-accent-primary": selected,
          "text-icon-secondary group-hover/action-sheet-item:text-icon-secondary-hover":
            !selected,
        },
        iconClassName
      )}
      iconSize={iconSize ?? defaultIconSize}
    >
      {avatarURL !== undefined && (
        <Avatar
          avatarURL={avatarURL}
          className="mr-10 md:mr-8"
          name={avatarName ?? ""}
          rounded="rounded-sm"
          size={breakpointMD ? "x-small" : "small"}
        />
      )}

      <div className="text-left truncate">{text}</div>

      {selected ? (
        <Check
          className="justify-self-end ml-12 -mr-4 text-interactive-subtle"
          size={16}
          strokeWidth={3}
        />
      ) : null}
    </Button>
  );
};

type DropdownActionsProps =
  | {
      children?: never;
      actions: ActionSheetItemGroups[];
    }
  | {
      actions?: never;
      children: React.ReactNode;
    };

export const DropdownActions = ({
  actions,
  modalId,
  setOpen,
  children,
}: DropdownActionsProps & {
  modalId?: string;
  setOpen?: (open: boolean) => void;
}) => {
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    modalId ? closeModal(modalId) : setOpen?.(false);
  };

  return (
    <div
      className={modalId ? "pb-[env(safe-area-inset-bottom)]" : ""}
      onClick={handleClose}
    >
      {children}

      {actions
        ?.filter(group => group.items.filter(item => !item.hidden).length)
        .map(group => (
          <div
            key={group.name}
            className="border-b-thin border-background-subtle flex flex-col max-w-full min-w-0 py-8 last:border-none"
          >
            {group.items.map(item => (
              <ActionSheetItem
                key={item.text}
                {...item}
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  handleClose(e);
                  item.onClick?.(e);
                }}
              />
            ))}
          </div>
        ))}
    </div>
  );
};
