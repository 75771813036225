import {
  Sizes,
  User,
  WorkspacePreviewSimple,
  WorkspaceSimple,
} from "@utility-types";
import AvatarWithBadge from "components/ProfileItem/AvatarWithBadge";
import { parameters } from "components/ProfileItem/profileParameters";
import useValidateRecipientOrigin from "hooks/useValidateRecipientOrigin";
import getRandomInt from "utils/getRandomInt";
import glueImageURL from "utils/glueImageURL";

import { ExternalBadge, ProfileDescription } from "components/ProfileItem";
import { Skeleton } from "components/Skeleton";

type Props = {
  admin?: User;
  showAvatar?: boolean;
  size?: Exclude<Sizes, "tiny">;
  workspace: WorkspaceSimple | WorkspacePreviewSimple | undefined;
};

const WorkspaceProfileItem = ({
  admin,
  showAvatar = true,
  size = "medium",
  workspace,
}: Props): JSX.Element | null => {
  const { domains, name } = workspace || {};
  const domain = domains?.[0];
  const roundedClass = size === "small" ? "rounded-md" : "rounded-full";
  const avatarClass = `${roundedClass} object-cover object-left ${parameters[size].avatar.groupClassNames} ${parameters[size].avatarSpacing}`;
  const { isExternalRecipient } = useValidateRecipientOrigin();
  const isExternal = !!workspace && isExternalRecipient(workspace);
  return (
    <div
      className={`flex flex-row min-w-0 items-center justify-start ${parameters[size].classNames}`}
    >
      {showAvatar && (
        <AvatarWithBadge
          badgeVisible={isExternal && size === "small"}
          className={"flex shrink-0 items-center empty:hidden"}
          type="Workspace"
        >
          {workspace?.avatarURL ? (
            <img
              alt={domain}
              className={avatarClass}
              onError={e => e.currentTarget.remove()}
              src={glueImageURL(workspace.avatarURL, {
                crop: "left",
                fit: "min",
                h: size === "large" ? 80 : 64,
                w: size === "large" ? 80 : 64,
              })}
            />
          ) : !workspace ? (
            <Skeleton className={avatarClass} />
          ) : null}
        </AvatarWithBadge>
      )}
      <div className="flex flex-col min-w-0">
        <div className="flex flex-row items-center min-w-0">
          <div
            className={`flex items-center min-w-0 ${parameters[size].nameClassNames}`}
          >
            {name ? (
              <span className="truncate">{name}</span>
            ) : (
              <Skeleton
                height="16px"
                width={`${getRandomInt(50, 150)}px`}
                flexGrow
              />
            )}
          </div>
          {isExternal && size !== "small" && <ExternalBadge type="Workspace" />}
        </div>
        {size !== "small" ? (
          domains ? (
            <ProfileDescription
              admin={admin}
              domains={domains}
              size={size}
              type={"Workspace"}
            />
          ) : (
            <Skeleton
              height="12px"
              width={`${getRandomInt(50, 120)}px`}
              flexGrow
            />
          )
        ) : null}
      </div>
    </div>
  );
};

export default WorkspaceProfileItem;
