type Breakpoints = {
  md: boolean;
};

/**
 *  - breakpoints naming matches TailwindCSS
 */
const breakpoints: (windowWidth?: number) => Breakpoints = (
  windowWidth = window?.innerWidth || 0
) =>
  // we only use the `md` breakpoint; if ever we need the others, the values should be:
  // sm:640, lg:1024, xl:1280
  ({
    md: windowWidth >= 768,
  });
export default breakpoints;
