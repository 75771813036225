import { PlusCircle } from "react-feather";

import { InputButton } from "components/design-system/Forms";
import { useLambdasQuery } from "generated/graphql";
import useModalStore from "store/useModalStore";

import EditLambdaModal from "./EditLambdaModal";
import LambdaList from "./LambdaList";

type Props = {
  appId: string;
};

const LambdaManager = ({ appId }: Props): JSX.Element => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const handleOpenCreateModal = () => {
    openModal(<EditLambdaModal appId={appId} />);
  };

  const { data } = useLambdasQuery({
    variables: {
      appId,
    },
  });

  const lambdas = data?.lambdas.edges.map(edge => edge.node) || [];

  return (
    <div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2>Lambdas</h2>
        <InputButton
          buttonStyle="none"
          buttonType="text"
          icon={PlusCircle}
          onClick={() => {
            handleOpenCreateModal();
          }}
        >
          Create Lambda
        </InputButton>
      </div>
      <LambdaList lambdas={lambdas} />
    </div>
  );
};

export default LambdaManager;
