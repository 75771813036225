import {
  ForwardedRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { ReactExtensions, useRemirrorContext } from "@remirror/react";
import { CommandsFromExtensions, FocusType } from "remirror";

import {
  FileOrImageUpload,
  isExternalObject,
  isGlueFile,
} from "@utility-types";
import { ExternalObjectPreview } from "@utility-types/linkPreview";
import { useAttachments } from "components/MessageEditor/hooks";
import useQuotedMessage from "components/MessageEditor/hooks/useQuotedMessage";
import { useEditorContentState } from "components/MessageEditor/providers/EditorContentProvider";
import { useEditorState } from "components/MessageEditor/providers/EditorProvider";
import {
  externalObjectToLinkPreview,
  fileUploadToStreamAttachment,
  glueFileToFileUpload,
  isFileAttachment,
  linkPreviewToStreamAttachment,
  streamAttachmentToFileUpload,
  streamAttachmentToLinkPreviews,
} from "components/MessageEditor/stream-helpers";
import { hasEditorTextContent } from "components/MessageEditor/utils";
import { MessageFieldsFragment } from "generated/graphql";
import useMessageEditorStore from "store/useMessageEditorStore";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import { isNativeIOS } from "utils/platform";
import {
  glueMessageToStreamMessage,
  streamMessageToGlueMessage,
} from "utils/stream/message";

import { GlueWysiwygPreset } from "../../remirror-extensions/GlueWysiwyg";
import { MessageEditor, Mode } from "../../types";

type Props = Omit<ReturnType<typeof useAttachments>, "attachmentComponents"> &
  Omit<ReturnType<typeof useQuotedMessage>, "quotedMessageComponent"> & {
    forwardedRef: ForwardedRef<MessageEditor>;
    isMounted: MutableRefObject<boolean>;
    mode: Mode;
  };

export default function ImperativeHandle({
  attachmentState,
  forwardedRef,
  getFinishedAttachments,
  isMounted,
  mode,
  onAttachFiles,
  quotedMessage,
  setAttachmentsRef,
  setQuotedMessageRef,
}: Props): null {
  const context = useRemirrorContext<ReactExtensions<GlueWysiwygPreset>>();
  const { commands, getState, helpers, manager, uid, view } = context;
  const { editorId } = useEditorState(({ editorId }) => ({ editorId }));
  const {
    hasAttachments,
    readOnly,
    setState: editorContentUpdater,
  } = useEditorContentState(({ hasAttachments, readOnly }) => ({
    hasAttachments,
    readOnly,
  }));
  const { addEditor, editors, removeEditor } = useMessageEditorStore();

  const hasFocus = () => view.hasFocus();
  const focusEditor = (position?: FocusType) => {
    commands.focus(position);
    return true;
  };

  const hasAttachmentsRef = useRef(hasAttachments);
  const readOnlyRef = useRef(readOnly);

  const unsubscribeNativeKeyboardStoreRef = useRef<(() => void) | undefined>();

  const [editor] = useState<
    MessageEditor & {
      // we don't want to expose commands outside of tests
      commands: CommandsFromExtensions<ReactExtensions<GlueWysiwygPreset>>;
    }
  >(() => {
    const setProcessing = (newValue: boolean) =>
      editorContentUpdater({
        isProcessing: newValue,
      });

    const reset = () => {
      if (!isMounted.current) return;

      setAttachmentsRef.current([], true, false);

      setQuotedMessageRef.current({ message: undefined, skipOnChange: true });

      // Clear out old state when setting data from outside
      // This prevents e.g. the user from using CTRL-Z to go back to the old state
      view.updateState(manager.createState({ content: "" }));

      // Update editor content context - text content has been removed.
      editorContentUpdater({
        hasTextContent: false,
        isResetting: true,
      });

      setProcessing(false);

      editorContentUpdater({
        isResetting: false,
      });
    };

    const setMessage = (
      text = "",
      attachments: FileOrImageUpload[],
      linkPreviews: ExternalObjectPreview[],
      quoted_message: MessageFieldsFragment | undefined
    ) => {
      if (!isMounted.current) return;

      reset();

      commands.insertMarkdown(text, {
        selection: "start",
      });

      setAttachmentsRef.current([...attachments, ...linkPreviews], true, false);

      setQuotedMessageRef.current({
        message: quoted_message,
        skipOnChange: true,
      });

      return {
        focus: () => {
          if (
            isNativeIOS() &&
            useNativeKeyboardStore.getState().isAccessoryBarVisible
          ) {
            unsubscribeNativeKeyboardStoreRef.current =
              useNativeKeyboardStore.subscribe(
                ({ isAccessoryBarVisible }) =>
                  !isAccessoryBarVisible &&
                  focusEditor("end") &&
                  unsubscribeNativeKeyboardStoreRef.current?.()
              );
          } else {
            focus();
          }
        },
      };
    };

    return {
      commands,
      focusEditor,
      getMessage: () => ({
        attachments: [
          ...getFinishedAttachments(attachmentState.current.uploadsState),
          ...getFinishedAttachments(attachmentState.current.linkPreviewState),
        ],
        quoted_message: quotedMessage.current,
        text: helpers.getMarkdown() || "",
      }),
      getStreamMessage: () => ({
        attachments: [
          ...getFinishedAttachments(attachmentState.current.uploadsState).map(
            fileUploadToStreamAttachment
          ),
          ...getFinishedAttachments(
            attachmentState.current.linkPreviewState
          ).map(linkPreviewToStreamAttachment),
        ],
        quoted_message: quotedMessage.current
          ? glueMessageToStreamMessage(quotedMessage.current)
          : undefined,
        quoted_message_id: quotedMessage.current?.id,
        text: helpers.getMarkdown(),
      }),
      hasAttachments: () => hasAttachmentsRef.current,
      addAttachments: (files: File[]) => onAttachFiles(files),
      hasFocus,
      hasText: () => hasEditorTextContent(getState()),
      mode,
      readOnly: () => readOnlyRef.current,
      reset: reset,
      setIsProcessing: setProcessing,
      setMessage: ({ attachments, quoted_message, text }) =>
        setMessage(
          text,
          attachments.filter(isGlueFile).map(glueFileToFileUpload),
          attachments.filter(isExternalObject).map(externalObjectToLinkPreview),
          quoted_message
        ),
      setQuotedMessage: (
        message: Parameters<typeof setQuotedMessageRef.current>[0]["message"]
      ) => setQuotedMessageRef.current({ focus: focusEditor, message }),
      setReadOnly: (value: boolean) =>
        editorContentUpdater({ readOnly: value }),
      setStreamMessage: ({ attachments = [], quoted_message, text }) =>
        setMessage(
          text,
          attachments
            .filter(isFileAttachment)
            .map(streamAttachmentToFileUpload),
          attachments
            .filter(isExternalObject)
            .map(streamAttachmentToLinkPreviews),
          quoted_message && streamMessageToGlueMessage(quoted_message)
        ),
    };
  });

  useImperativeHandle(forwardedRef, () => editor, [editor]);

  useEffect(() => unsubscribeNativeKeyboardStoreRef.current, []);

  useEffect(() => {
    hasAttachmentsRef.current = hasAttachments;
    readOnlyRef.current = readOnly;
  }, [hasAttachments, readOnly]);

  useEffect(() => {
    const currentEditorId = editorId || uid;

    if (editors.get(currentEditorId)) return;

    addEditor({
      editor,
      uid: currentEditorId,
    });

    return () => {
      removeEditor(currentEditorId);
    };
  }, [addEditor, editor, editorId, editors, removeEditor, uid]);

  return null;
}
