import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { Modal, ModalProps } from "components/ModalKit/Modal";
import { useModalDragging } from "components/ModalKit/useModalDragging";
import useModalStore from "store/useModalStore";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import breakpoints from "utils/breakpoints";
import tw from "utils/tw";

type Props = WithChildren & {
  contentClassName?: string;
  contentHandlesSafeArea?: boolean;
} & ModalProps;

export const FullScreenOverlay = ({
  children,
  contentClassName,
  contentHandlesSafeArea = true,
  ...props
}: Props) => {
  const history = useHistory();
  const { closeModal } = useModalStore(({ closeModal }) => ({
    closeModal,
  }));

  // if location changes, close the modal.
  useEffect(
    () => history?.listen(() => closeModal(props.modalId)),
    [closeModal, history, props.modalId]
  );

  const { bind, dragStyles } = useModalDragging(props.modalId);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          const { current: element } = contentRef;
          if (!element) return;

          const keyboardOffset = `${Math.ceil(keyboardHeight)}px`;
          element.style.paddingBottom = contentHandlesSafeArea
            ? `calc(${keyboardOffset} - env(safe-area-inset-bottom))`
            : keyboardOffset;
        }
      ),
    [contentHandlesSafeArea]
  );

  return (
    <Modal
      alignment={
        breakpoints().md
          ? undefined
          : {
              x: "text-center",
              y: "align-bottom",
            }
      }
      containerScrolling={false}
      contentClassName={tw(
        "w-full overflow-hidden !bg-background-black/25 !dark:bg-background-black/50",
        contentClassName
      )}
      contentTransitions={
        breakpoints().md
          ? undefined
          : {
              enter: {
                y: "0%",
              },
              from: {
                y: "100%",
              },
              leave: {
                y: "100%",
              },
            }
      }
      disableOpacityTransition={!breakpoints().md}
      dragStyles={dragStyles}
      {...props}
    >
      <div
        ref={contentRef}
        onClick={() => closeModal(props.modalId)}
        className={tw("flex flex-col h-safe-viewport-[0px]", {
          "pb-[env(safe-area-inset-bottom)]": !contentHandlesSafeArea,
        })}
      >
        <div
          className="h-55 shrink-0 cursor-grab touch-none select-none"
          {...bind()}
        />
        <div className="item-center flex grow flex-col justify-center w-full">
          {children}
        </div>
        <div className="h-55 shrink-0" />
      </div>
    </Modal>
  );
};
