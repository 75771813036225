import { Button } from "components/design-system/Button";
import { ComponentPropsWithoutRef } from "react";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";
import { Icon } from "../icons";

type Props = ComponentPropsWithoutRef<typeof Button> & {
  selected?: boolean;
};

export const DropdownActionButton = ({
  children,
  className,
  selected,
  ...rest
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return (
    <Button
      buttonFont="normal"
      buttonStyle="none"
      className={tw(
        "group/action-sheet-item action-sheet-item",
        "flex items-center justify-start",
        "!px-16 !py-7 md:!py-5 rounded-none",
        "w-full",
        "gap-12",
        "text-text-primary",
        "text-subhead",
        "hover:bg-accent-highlight/25 active:bg-accent-highlight/25 mouse:active:bg-accent-highlight/40",
        className
      )}
      iconClassName={tw(
        "!mr-0",
        "text-icon-secondary",
        "group-hover/action-sheet-item:text-icon-secondary-hover"
      )}
      iconSize={breakpointMD ? 20 : 24}
      {...rest}
    >
      {children}
      {selected && (
        <Icon
          icon="Check"
          className="text-icon-primary-selected ml-auto"
          size={20}
        />
      )}
    </Button>
  );
};
