import { uuidv4 } from "@firebase/util";
import {
  StreamMessage,
  useChannelActionContext,
  useChatContext,
} from "stream-chat-react";
import { Message } from "stream-chat/dist/types/types";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import { EditorStreamMessage } from "components/MessageEditor/types";
import { removeLonelyLinks } from "utils/message/removeLonelyLinks";

import { useRequestPermission } from "components/Notification";

const useSendMessage = () => {
  const { channel, client } = useChatContext<GlueDefaultStreamChatGenerics>();
  const { updateMessage } =
    useChannelActionContext<GlueDefaultStreamChatGenerics>();
  const { requestPermission: requestNotificationsPermission } =
    useRequestPermission();

  // We need to implement our own sendMessage function because Stream's doesn't
  // account for having the quoted_message property immediately available while sending.
  // Refer to https://github.com/GetStream/stream-chat-react/blob/ff6513dddafce7b8c6967e8b4060a7715e870136/src/components/Channel/Channel.tsx#L621
  // for the original implementation of createMessagePreview and sendMessage.
  const createMessageDataAndPreview = (
    message: EditorStreamMessage
  ): [
    Message<GlueDefaultStreamChatGenerics>,
    StreamMessage<GlueDefaultStreamChatGenerics>,
  ] => {
    const messageText = removeLonelyLinks(
      message.text.trim(),
      message.attachments
    );

    const messageData = {
      attachments: message.attachments,
      id: `${client.userID}-${uuidv4()}`,
      mentioned_users: [],
      quoted_message_id: message.quoted_message_id,
      text: messageText,
    };

    // Stream types make it impossible to use the StreamGlueMessage
    // type here, so need to create a type compatible with StreamMessage<T>
    const { quoted_message } = message;
    const quotedMessagePreview = quoted_message
      ? {
          attachments: quoted_message.attachments,
          created_at: quoted_message.created_at?.toString(),
          id: quoted_message.id,
          text: quoted_message.text,
          type: "regular" as const,
          user: quoted_message.user,
        }
      : undefined;

    return [
      messageData,
      {
        ...messageData,
        created_at: new Date(),
        pinned_at: null,
        quoted_message: quotedMessagePreview,
        status: "sending",
        type: "regular",
        updated_at: new Date(),
        user: client.user,
      },
    ];
  };

  const sendMessage = (message: EditorStreamMessage) => {
    if (!channel) return;

    const [messageData, messagePreview] = createMessageDataAndPreview(message);

    updateMessage(messagePreview);

    channel
      .sendMessage(messageData)
      .then(({ message }) => {
        message && updateMessage({ ...message, status: "received" });
        requestNotificationsPermission();
      })
      .catch(error => {
        const statusCode =
          error && "status" in error ? error.status : undefined;
        updateMessage({
          ...messagePreview,
          errorStatusCode: statusCode,
          status: "failed",
        });
      });
  };
  return sendMessage;
};

export default useSendMessage;
