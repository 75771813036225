import { useFormContext } from "react-hook-form";

import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import Button from "components/design-system/Button/Button";
import { Form, SubmitButton, TextInput } from "components/design-system/Forms";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";
import { StandardModal } from "./StandardModal";

type Props = {
  headerTitle?: string;
  title: string;
  subtitle: string;
  confirmationWord?: string;
  confirmButtonText: string;
  footerAction?: JSX.Element;
  onConfirm: () => Promise<void>;
};

type FormValues = {
  confirmation: string;
};

const FormContent = ({
  headerTitle = "Confirmation required",
  title,
  subtitle,
  children,
  confirmationWord = "DELETE",
  confirmButtonText,
  footerAction,
  modalId,
}: WithChildren<Omit<Props, "onConfirm">> & ModalProps) => {
  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext<FormValues>();
  const { confirmation } = watch();
  const submitDisabled = confirmation !== confirmationWord;

  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));
  return (
    <>
      <Header variant="bordered">{headerTitle}</Header>
      <Main className="flex flex-col gap-20 px-32 py-20">
        <div className="flex flex-col">
          <span className="text-body-bold">{title}</span>
          <span className="text-body text-text-secondary">{subtitle}</span>
        </div>
        {children}
        <div className="flex flex-col mb-72">
          <span className="text-subhead-bold">
            Type "{confirmationWord}" to confirm
          </span>
          <TextInput name="confirmation" wrapperClassName="!mt-4 !mb-0 " />
        </div>
      </Main>
      <Footer
        flexboxClassName={tw("justify-end", {
          "justify-between": !!footerAction,
        })}
      >
        {footerAction}
        <div className="flex">
          <Button
            buttonStyle="simpleSecondary"
            type="button"
            onClick={() => closeModal(modalId)}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <SubmitButton
            buttonStyle="primaryDestructive"
            disabled={submitDisabled || isSubmitting}
          >
            {confirmButtonText}
          </SubmitButton>
        </div>
      </Footer>
    </>
  );
};

const ConfirmationRequiredModal = ({
  headerTitle = "Confirmation required",
  title,
  subtitle,
  children,
  confirmationWord,
  confirmButtonText,
  footerAction,
  onConfirm,
  ...props
}: WithChildren<Props> & ModalProps) => {
  return (
    <StandardModal {...props}>
      <Form<FormValues> onSubmit={onConfirm}>
        <FormContent
          headerTitle={headerTitle}
          title={title}
          subtitle={subtitle}
          children={children}
          confirmationWord={confirmationWord}
          footerAction={footerAction}
          modalId={props.modalId}
          confirmButtonText={confirmButtonText}
        />
      </Form>
    </StandardModal>
  );
};

export default ConfirmationRequiredModal;
